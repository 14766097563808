import { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

export default function BettermapsColorPicker(props) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const styles = reactCSS({
    default: {
      color: {
        width: "24px",
        height: "24px",
        borderRadius: "2px",
        background:
          typeof props.color === "string"
            ? props.color
            : `rgba(${props.color.rgb.r}, ${props.color.rgb.g}, ${props.color.rgb.b}, ${props.color.rgb.a})`,
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div
        style={styles.color}
        onClick={() => setDisplayColorPicker(!displayColorPicker)}
      ></div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={() => setDisplayColorPicker(false)}
          />
          <SketchPicker
            color={props.color.rgb}
            onChange={props.setColor}
            disableAlpha={props.disableAlpha}
            onChangeComplete={props.setColor}
          />
        </div>
      ) : null}
    </div>
  );
}
