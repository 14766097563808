import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import BettermapsModal from "../../BettermapsModal";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

export default function HostedMapInfoModal(props) {
	const [isLoading, setIsLoading] = useState(true);
	const userData = useSelector((state) => state.user);

	const handleClose = () => {
		props.setHostedMapInfo({});
		props.setShow(false);
		props.setShowMainModal(false);
		analytics.identify("click on Paper Map - Hosted Map Info - close button ", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};
	const copyClipBoard = (value) => {
		navigator.clipboard.writeText(value);
	};

	useEffect(() => {
		if (props.hostedMapInfo.urlS3 && props.hostedMapInfo.htmlTag) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
		}
	}, [props.hostedMapInfo]);

	const handleHtmlCopy = () => {
		copyClipBoard(props.hostedMapInfo.htmlTag);

		analytics.identify(
			`click on Paper Map - Hosted Map Info - HTML TAG - ${props.hostedMapInfo.htmlTag}`,
			{
				id: userData.ownerId,
				subscription: userData.subscription,
			}
		);
	};

	const handleUrlS3 = () => {
		copyClipBoard(props.hostedMapInfo.urlS3),
			analytics.identify(
				`click on Paper Map - Hosted Map Info - URL S3 - ${props.hostedMapInfo.urlS3}`,
				{
					id: userData.ownerId,
					subscription: userData.subscription,
				}
			);
	};

	return (
		<BettermapsModal
			sx={{ width: "800px" }}
			title="Hosted Map Info"
			showModal={props.show}
			setShowModal={props.setShow}>
			{isLoading ? (
				<CircularProgress></CircularProgress>
			) : (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<TextField
							fullWidth
							label="URL S3"
							value={props.hostedMapInfo.urlS3}
							sx={{ mr: "2rem" }}></TextField>
						<Button variant="contained" size="large" onClick={handleUrlS3}>
							Copy
						</Button>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", mt: "10px" }}>
						<TextField
							fullWidth
							label="HTML TAG"
							value={props.hostedMapInfo.htmlTag}
							sx={{ mr: "2rem" }}></TextField>
						<Button variant="contained" size="large" onClick={handleHtmlCopy}>
							Copy
						</Button>
					</Box>
				</Box>
			)}
			<Box sx={{ mt: "10px", display: "flex", justifyContent: "end" }}>
				<Button variant="contained" color="error" onClick={handleClose}>
					CLOSE
				</Button>
			</Box>
		</BettermapsModal>
	);
}
