import * as React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  createUserLayer,
  getUserLayer,
  updateUserLayer,
} from "../../../services/DatasourcesService";
import { useMap } from "react-map-gl";
import { useSelector, useDispatch } from "react-redux";
import { saveLayer } from "../../../redux/slices/layers";

export default function LongMenu(props) {
  const dispatch = useDispatch();
  const { ownerId } = useSelector((state) => state.user);
  const { layers } = useSelector((state) => state.layers);
  props = props.props;
  const myMap = useMap()[props.map];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isSaved, setIsSaved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.layer.dbLayerId) {
      getUserLayer(props.layer.dbLayerId).then((l) => setIsSaved(l !== null));
    }
  });

  const saveSingleLayer = () => {
    const layer = props.layer;
    layer.dbLayerId
      ? updateUserLayer(layer, ownerId)
      : createUserLayer(layer, ownerId).then((id) => {
          if (id) {
            dispatch(saveLayer([layer.id, id]));
            setIsSaved(true);
            enqueueSnackbar("Layer saved", {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              "We had an error saving your layer, please try again later",
              {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "error",
              }
            );
          }
        });

    handleClose();
  };

  // createUserLayer(props.layer, ownerId).then((id) => {
  //   dispatchAction(id);
  // var newLayers = [...layers];
  // newLayers.find((l) => l.id == props.layer.id).dbLayerId = id;
  // var newLayer = newLayers.find((l) => l.id == props.layer.id);

  // dispatch(setLayers(newLayers));
  // setIsSaved(true);
  // handleClose();
  // });

  const zoomLayer = () => {
    if (props.layer.boundingbox) {
      myMap.getMap().fitBounds(props.layer.boundingbox);
      handleClose();
    }
  };

  const editLayer = () => {
    props.onEdit();
    handleClose();
    setIsSaved(false);
  };
  const deleteLayer = () => {
    handleClose();
    props.onDelete();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon
          sx={{
            color: props.canDelete ? "primary.main" : "secondary.main",
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          title="zoom to layer"
          sx={{ pr: "2px", pl: "2px", mr: "3px" }}
          onClick={zoomLayer}
        >
          <ZoomOutMapIcon
            sx={{
              color: props.canDelete ? "primary.main" : "secondary.main",
              mr: "3px",
              ml: "2px",
            }}
          />
          Zoom to Layer
        </MenuItem>
        <MenuItem
          title="Save Layer"
          disabled={isSaved}
          onClick={saveSingleLayer}
          sx={{ pr: "2px", pl: "2px" }}
        >
          <SaveIcon
            sx={{
              mr: "3px",
              ml: "2px",
              // height: "28px",
              // width: "28px",
              color: isSaved ? "secondary.main" : "primary.main",
            }}
          />
          Save Layer
        </MenuItem>
        <MenuItem
          title="Edit Layer"
          disabled={!props.canEdit}
          onClick={editLayer}
          sx={{ pr: "2px", pl: "2px" }}
        >
          <EditIcon
            sx={{
              color: props.canEdit ? "primary.main" : "secondary.main",
              mr: "3px",
              ml: "2px",
            }}
          />
          Edit Layer
        </MenuItem>
        <MenuItem
          disabled={!props.canDelete}
          title="Delete Layer"
          onClick={deleteLayer}
          sx={{ pr: "2px", pl: "2px" }}
        >
          <DeleteIcon
            sx={{
              color: props.canDelete ? "primary.main" : "secondary.main",
              mr: "3px",
              ml: "2px",
            }}
          />
          Delete Layer
        </MenuItem>
      </Menu>
    </div>
  );
}
