import { Box, Card, Divider, Chip, Collapse, CardContent } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import { CardActionArea } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { SourceProviderColor } from "./SourceProviderColor";
import { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import * as React from "react";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DataLayerCard(props) {
	const [expanded, setExpanded] = useState();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	// SourceProviderColor.map((s) => {
	//   if (s.provider == props.source.toLowerCase()) {
	//     chipColor = s.color;
	//   }
	// });

	// Refactored code
	const sourceProvider = SourceProviderColor.find(
		(s) => s.provider === props.source.toLowerCase()
	);
	const chipColor = sourceProvider ? sourceProvider.color : null;

	return (
		<>
			<Card
				index={props.index}
				elevation={6}
				sx={{ width: 200, borderRadius: "10px", m: "2px" }}>
				<CardActionArea onClick={props.onClick}>
					<CardMedia
						component="img"
						height="150"
						image={props.image}
						alt={props.alt}
					/>
					<Box
						sx={{
							display: "flex",
							//   // justifyContent: "end",
							position: "absolute",
							width: "100%",
							bgcolor: "rgba(255, 255, 255, 0.50)",
							color: "white",
							marginLeft: "0px",
							marginTop: "-40px",
						}}>
						<Typography
							variant="h6"
							color="primary"
							style={{
								// backgroundColor: chipColor,
								// // marginTop: "-40px",
								paddingLeft: "10px",
							}}>
							{props.text}
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "end",
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							color: "white",
							padding: "2px",
							marginTop: "2px",
						}}>
						<Chip
							label={props.source}
							size="small"
							style={{
								backgroundColor: chipColor,
								fontSize: "10px",
								height: "15px",
								paddingRight: "0.5px",
								paddingLeft: "0.5px",
							}}></Chip>
					</Box>
				</CardActionArea>
				{/* <Divider /> */}
				{/* <CardActions
          disableSpacing
          sx={{ display: "flex", justifyContent: "center" }}
        > */}
				{/* <IconButton aria-label="add to favourites">
            <FavoriteIcon />
          </IconButton> */}

				{/* <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon /> */}
				{/* </ExpandMore> */}
				{/* </CardActions> */}
				{/* <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Method:</Typography>
            <Typography paragraph>
              Heat 1/2 cup of the broth in a pot until simmering, add saffron
              and set aside for 10 minutes.
            </Typography>

            <Typography>
              Set aside off of the heat to let rest for 10 minutes, and then
              serve.
            </Typography>
          </CardContent>
        </Collapse> */}
			</Card>
		</>
	);
}
