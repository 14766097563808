import post from "../clients/HttpClient";

export async function analyseStyle(map) {
  if (map) {
    const response = await post(
      `${process.env.REACT_APP_STYLE_ANALYSIS_LAMBDA}/${process.env.REACT_APP_ENV}`,
      JSON.stringify(map.getStyle())
    );
    if (response.data.statusCode === 200) {
      return JSON.parse(response.data.body).alerts;
    } else {
      console.error(response.data.body);
      throw new Error("Something went wrong when analysing your style");
    }
  }
}
