import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import localData from "./slices/localStorage";
import maps from "./slices/map";
import layers from "./slices/layers";
import style from "./slices/style";
import boundingBox from "./slices/boundingBox";

export const Store = configureStore({
  reducer: {
    user,
    localData,
    maps,
    layers,
    style,
    boundingBox,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({ serializable: false }),
});
