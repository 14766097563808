import CustomOverlay from "./CustomOverlay";
import BettermapsWhite from "../../../images/BM_H_White.svg";
import { Box } from "@mui/material";

export default function BettermapsOverlay(props) {
  return (
    <CustomOverlay>
      <Box
        style={{
          position: "absolute",
          right: props.right,
          left: props.left,
          top: props.top,
          bottom: props.bottom,
        }}
      >
        <img
          src={BettermapsWhite}
          height={props.height || 32}
          width={props.width || 160}
        />
      </Box>
    </CustomOverlay>
  );
}
