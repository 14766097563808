import { Grid, Container, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import * as React from "react";
import Box from "@mui/material/Box";
import { mapTemplates } from "../../lib/MapTemplates";
import DataLayerCard from "./DataLayerCard";
import { setMapStyle } from "../../redux/slices/style";
import { useSelector, useDispatch } from "react-redux";

export const mapSources = [
  { value: "all", name: "All" },
  // { value: "maputnik", name: "Maputnik" },
  { value: "maptiler", name: "MapTiler" },
  { value: "mapbox", name: "MapBox" },
  // { value: "thunderforest", name: "ThunderForest" },
];

export const Templates = (props) => {
	const dispatch = useDispatch();
	const [templates, setTemplates] = useState(mapTemplates);
	const [templatesFilter, setTemplatesFilter] = useState("all");
	const [sources, setSources] = useState(mapSources);
	const [noTemplates, setNoTemplates] = useState(false);

	const userData = useSelector((state) => state.user);
	// useEffect(() => {
	// 	if (templatesFilter && templatesFilter != "all") {
	// 		mapTemplates.map((s) => {
	// 			if (s.source.toLowerCase() == templatesFilter) {
	// 				filteredArray.push(s);
	// 			}
	// 		});
	// 	} else {
	// 		filteredArray = mapTemplates;
	// 	}

	// 	if (filteredArray.length > 0) {
	// 		setNoTemplates(false);
	// 		setTemplates(filteredArray);
	// 	} else {
	// 		setNoTemplates(true);
	// 	}
	// }, [templatesFilter]);

	// Refactored code
	useEffect(() => {
		let filteredArray = [];

		if (templatesFilter && templatesFilter !== "all") {
			filteredArray = mapTemplates.filter(
				(s) => s.source.toLowerCase() === templatesFilter
			);
		} else {
			filteredArray = mapTemplates;
		}

		setNoTemplates(filteredArray.length === 0);
		setTemplates(filteredArray);
	}, [templatesFilter, mapTemplates]);

	function changeStyle(template) {
		props.closeFunction(false);
		dispatch(
			setMapStyle(process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + template)
		);
	}

	const env_templates = templates.filter((t) => t.env != "dev");

	return (
		<main>
			<Container>
				<Box
					sx={{
						display: "flex",
						flexdirection: "row",
						justifyContent: "center",
						mb: "10px",
					}}>
					{sources.map((s, index) => (
						<Button
							key={index}
							variant={s.value == templatesFilter ? "contained" : "outlined"}
							value={s.value}
							onClick={(e) => {
								setTemplatesFilter(e.target.value),
									analytics.identify(
										`click create map modal: ${e.target.value}`,
										{
											id: userData.ownerId,
											subscription: userData.subscription,
										}
									);
							}}>
							{s.name}
						</Button>
					))}
				</Box>
				<Box
					sx={{
						display: "flex",
						flexdirection: "row",
						flexWrap: "wrap",
						justifyContent: "center",
						maxHeight: "600px",
						overflow: "scroll",
						overflowX: "hidden",
					}}>
					{noTemplates ? (
						<Typography>No Templates Found</Typography>
					) : (
						env_templates.map((t, index) => (
							<DataLayerCard
								index={index}
								key={index}
								source={t.source}
								alt={t.text}
								delete={(e) => alert("deleting")}
								image={
									process.env.REACT_APP_S3_PUBLIC_MINIATURES_PREFIX_URL +
									t.imageName
								}
								text={t.text}
								onClick={(e) => {
									changeStyle(t.styleName),
										analytics.identify(`template selected: ${t.styleName}`, {
											id: userData.ownerId,
											subscription: userData.subscription,
										});
								}}
							/>
						))
					)}
				</Box>
			</Container>
		</main>
	);
};
