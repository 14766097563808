import { Box, Grid, Typography } from "@mui/material";

export default function ErrorNotFoundPage() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <Box sx={{ width: "100%", alignItems: "center" }}>
          <Typography variant="h1">Error 404</Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
