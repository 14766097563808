import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import Map, { Layer, Source } from "react-map-gl";
import toLayer, { toSource } from "../../lib/StyleBuilder";
import { useSelector, useDispatch } from "react-redux";
import { getMapData } from "../../redux/slices/map";
import { useHistory } from "react-router-dom";

export default function MapboxPaperMap(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.maps);
  const { layers } = useSelector((state) => state.layers);
  const [viewport, setViewport] = useState({
    longitude: parseFloat(state.mapCenter.long) || -3,
    latitude: parseFloat(state.mapCenter.lat) || 40,
    zoom: parseFloat(state.mapZoom) || 2,
    bearing: parseFloat(state.mapBearing) || 0,
    pitch: parseFloat(state.mapPitch) || 0,
  });
  var displayLayers = [...layers];
  const [hoverInfo, setHoverInfo] = useState(null);
  const [isHovering, setIsHovering] = useState(false);

  const moveEnd = () => {
    dispatch(
      getMapData(
        props.myMap.getZoom(),
        props.myMap.getCenter(),
        props.myMap.getPitch(),
        props.myMap.getBearing()
      )
    );
  };
  const handleOnHover = (event, layers) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];
    const layer = layers.find((l) => l.id == hoveredFeature.layer.id);
    const showOnHover = layer.showOnHover;

    if (showOnHover) {
      const properties = {};
      layer.hoverProperties.forEach(
        (p) => (properties[p] = hoveredFeature.properties[p])
      );

      setHoverInfo(properties && { properties, x, y });
      setIsHovering(true);
    }
  };

  return (
    <Map
      preserveDrawingBuffer
      id={props.id}
      initialViewState={{ viewport }}
      {...viewport}
      boxZoom={!props.static}
      doubleClickZoom={!props.static}
      dragRotate={!props.static}
      dragPan={!props.static}
      keyboard={!props.static}
      scrollZoom={!props.static}
      touchPitch={!props.static}
      touchZoomRotate={!props.static}
      style={{
        height: props.id.includes("zoom-view") ? props.maxHeight : props.height,
        width: props.width,
        minWidth: props.width,
        minHeight: props.height,
        // maxWidth: props.maxWidth,
        // maxHeight: props.maxHeight,
      }}
      mapStyle={
        props.mapStyle ||
        process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
      }
      onMove={(evt) => setViewport(evt.viewState)}
      onMouseMove={(event) => handleOnHover(event, props.layers)}
      onMouseLeave={() => setIsHovering(false)}
      onMoveEnd={moveEnd}
      attributionControl={false}
      interactiveLayerIds={props.layers.map((l) => l.id)}
    >
      {displayLayers &&
        displayLayers
          // .sort((a, b) => b.position < a.position)
          .map((l) => (
            <Source key={l.source.id} {...toSource(l.source)}>
              <Layer key={l.id} {...toLayer(l, displayLayers)}></Layer>
            </Source>
          ))}

      {props.content}
      {isHovering && hoverInfo && (
        <Box
          style={{
            position: "absolute",
            left: hoverInfo.x,
            top: hoverInfo.y,
            backgroundColor: "white",
            opacity: "0.7",
            padding: "10px",
            color: "black",
            borderRadius: "5px",
          }}
        >
          {Object.keys(hoverInfo.properties).map((p, index) => (
            <p key={index}>
              {p}: {hoverInfo.properties[p]}
            </p>
          ))}
        </Box>
      )}
    </Map>
  );
}
