import { createSlice } from "@reduxjs/toolkit";

export const styleSlice = createSlice({
  name: "style",
  initialState: {
    styleId: "",
    styleName: "This is the Title of the Map",
    mapStyle: process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + `basic.json`,
  },
  reducers: {
    setStyleId: (state, action) => {
      state.styleId = action.payload;
    },
    setStyleName: (state, action) => {
      state.styleName = action.payload;
    },
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
    resetStyle: (state, initialState) => {
      state = initialState;
    },
  },
});

export const { setStyleId, setStyleName, setMapStyle, resetStyle } =
  styleSlice.actions;

export default styleSlice.reducer;

export const getStyleId = (styleId) => (dispatch) => {
  dispatch(setStyleId(styleId));
};

export const cleanStyle = () => (dispatch) => {
  dispatch(setStyleId(""));
  dispatch(setStyleName("This is the Title of the Map"));
  dispatch(
    setMapStyle(
      process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
    )
  );
};

// export const cleanStyle = () => (dispatch) => {
//   dispatch(setStyleId(""));
//   dispatch(setStyleName(""));
//   dispatch(setMapStyle(""));
// };
