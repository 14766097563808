import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ConfirmMapIntentModal(props) {
  const createSentence = () => {
    const location = props.mapIntent.CREATE;
    // when added font and background erase this >>>>
    const style = "";
    // <<<<

    // and uncommend this>>>>
    // const style = props.mapIntent.FONT
    //   ? ` with ${props.mapIntent.FONT.toLowerCase()} style`
    //   : "";
    // <<<<<<

    const mapBaseStyle = props.mapIntent.BACKGROUND
      ? " " + props.mapIntent.BACKGROUND.toLowerCase()
      : "";

    return `So, you want me to create a${mapBaseStyle} map of ${location}${style}, right?`;
  };

  return (
    <Dialog
      open={props.show}
      onClose={() => props.setShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Cass, our AI assistant says:"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {createSentence()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            props.createLogForAi("false");
            props.setShow(false);
          }}
          autoFocus
        >
          No
        </Button>
        <Button
          onClick={() => {
            props.createLogForAi("true");
            props.createMap();
            props.setShow(false);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
