import { createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    ownerId: "",
    email: "",
    subscription: "",
    intentsAi: "",
    intentsPrints: "",
    intentsHtml: "",
  },
  reducers: {
    setOwnerId: (state, action) => {
      state.ownerId = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPlanInfo: (state, action) => {
      if (action.payload.plan) {
        state.subscription = action.payload.plan;
      } else {
        state.subscription = "";
      }
      state.intentsAi = action.payload.usageAI;
      state.intentsPrints = action.payload.usagePrint;
      state.intentsHtml = action.payload.usageHtml;
    },
  },
});

export const { setOwnerId, setSubscription, setEmail, setPlanInfo } =
  userSlice.actions;

export default userSlice.reducer;

export const getUser = () => (dispatch) => {
  Auth.currentAuthenticatedUser().then((user) => {
    dispatch(setOwnerId(user.username));
    dispatch(setEmail(user.attributes.email));
    // dispatch(setSubscription(user.attributes["custom:usagePlan"]));
    // identify(user.id, {
    //   name: user.username,
    //   email: user.attributes.email,
    // });
  });



};

export const getSubscription = () => (dispatch) => {
  Auth.currentUserInfo().then((user) => {
    // dispatch(setSubscription(user.attributes["custom:usagePlan"]));
  });
};
