import { IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ActionButton(props) {
  const theme = useTheme();
  const getBackgroundColor = () =>
    props.variant == "filled"
      ? theme.palette.primary.main
      : props.variant == "contained"
      ? "white"
      : "inherit";
  return (
    <Tooltip title={props.title} enterDelay={1000}>
      <span>
        <IconButton
          disabled={props.disabled}
          component={props.component}
          sx={{
            bgcolor: getBackgroundColor(),
            borderRadius: 1,
            maxWidth: 36,
            maxHeight: 36,
            ...props.sx,
          }}
          style={{
            pointerEvents: "fill",
            backgroundColor: getBackgroundColor(),
          }}
          variant="contained"
          onClick={props.onClick}
        >
          {props.icon}
        </IconButton>
      </span>
    </Tooltip>
  );
}
