import { createSlice } from "@reduxjs/toolkit";
import { useMap } from "react-map-gl";

export const mapSlice = createSlice({
  name: "map",
  initialState: {
    mapId: "map-preview",
    mapZoom: "5",
    mapCenter: {
      long: "-3",
      lat: "40.0",
    },
    mapPitch: "0",
    mapBearing: "0",
  },
  reducers: {
    setMapId: (state, action) => {
      state.mapId = action.payload;
    },
    setMapZoom: (state, action) => {
      state.mapZoom = action.payload;
    },
    setMapCenter: (state, action) => {
      state.mapCenter.long = action.payload[0];
      state.mapCenter.lat = action.payload[1];
    },
    setMapPitch: (state, action) => {
      state.mapPitch = action.payload;
    },
    setMapBearing: (state, action) => {
      state.mapBearing = action.payload;
    },
    resetNewMap: (state) => {
      state.mapZoom = "5";
      state.mapCenter.long = "-3";
      state.mapCenter.lat = "40";
      state.mapPitch = "0";
      state.mapBearing = "0";
    },
  },
});

export const {
  setMapId,
  setMapBearing,
  setMapPitch,
  setMapCenter,
  setMapZoom,
  resetNewMap,
} = mapSlice.actions;

export default mapSlice.reducer;

export const getMapId = (mapId) => (dispatch) => {
  dispatch(setMapId(mapId));
};

export const getMapData = (zoom, center, pitch, bearing) => (dispatch) => {
  dispatch(setMapZoom(zoom));
  dispatch(setMapCenter([center.lng, center.lat]));
  dispatch(setMapPitch(pitch));
  dispatch(setMapBearing(bearing));
};
