import { Grid } from "@mui/material";
import { useState } from "react";
import { decimalAlphaToHex } from "../../../../../lib/color";
import { useColor } from "../../../../../lib/hooks";
import { LayerType } from "../../../../../lib/LayerType";
import BettermapsModal from "../../../BettermapsModal";
import LayerDetails from "../../LayerDetails";

export default function EditKMLModal(props) {
  const getFillColor = () =>
    props.layer.type.value !== LayerType.Line.value
      ? `${props.layer.fillColor}${decimalAlphaToHex(props.layer.opacity)}`
      : props.layer.fillColor;

  const getLineColor = () =>
    props.layer.type.value === LayerType.Line.value
      ? `${props.layer.lineColor}${decimalAlphaToHex(props.layer.opacity)}`
      : props.layer.lineColor;

  const [lineColor, setLineColor] = useColor(getLineColor());
  const [fillColor, setFillColor] = useColor(getFillColor());
  const [layerName, setLayerName] = useState(props.layer.name);

  function editKMLLayer() {
    props.setShow(false);
    props.setLayers((layers) => {
      return [
        ...layers.filter((l) => l.id !== props.layer.id),
        {
          ...props.layer,
          fillColor: fillColor.hex,
          lineColor: lineColor.hex,
          name: layerName,
          opacity: fillColor.rgb.a,
        },
      ];
    });
  }

  return (
    <BettermapsModal
      sx={{ width: "450px" }}
      title="Edit KML Layer"
      showModal={props.show}
      setShowModal={props.setShow}
      onDone={editKMLLayer}
      doneText="Save"
    >
      <Grid container direction="row">
        <LayerDetails
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          fillColor={fillColor}
          setFillColor={setFillColor}
          lineColor={lineColor}
          setLineColor={setLineColor}
          layerName={layerName}
          setLayerName={setLayerName}
          layerType={props.layer.type}
        ></LayerDetails>
      </Grid>
    </BettermapsModal>
  );
}
