import { useSelector } from "react-redux";

import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from "@mui/material";
import BettermapsModal from "../../BettermapsModal";

export default function ControlModal(props) {
	const userData = useSelector((state) => state.user);

		const handleRadioSettings = (e) => {
			props.setUnit(e.target.value);
			analytics.identify(`Scale Settings: ${e.target.value}`, {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
		};
	return (
		<BettermapsModal
			sx={{ width: "30%", minWidth: "288px" }}
			title={props.title}
			showModal={props.show}
			setShowModal={props.setShow}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					mt: 2,
				}}>
				{/* <FormControl disabled>
          <FormLabel id="radio-buttons-group-position">Position</FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-position"
            name="controlled-radio-buttons-group"
            value={props.position}
            onChange={(e) => props.setPosition(e.target.value)}
          >
            <FormControlLabel
              value="top-left"
              control={<Radio />}
              label="Top left"
            />
            <FormControlLabel
              value="top-right"
              control={<Radio />}
              label="Top right"
            />
            <FormControlLabel
              value="bottom-left"
              control={<Radio />}
              label="Bottom left"
            />
            <FormControlLabel
              value="bottom-right"
              control={<Radio />}
              label="Bottom right"
            />
          </RadioGroup>
        </FormControl> */}
				{props.unit && (
					<FormControl>
						<FormLabel id="radio-buttons-group-unit">Unit</FormLabel>
						<RadioGroup
							aria-labelledby="radio-buttons-group-unit"
							name="controlled-radio-buttons-group"
							value={props.unit}
							onChange={handleRadioSettings}>
							<FormControlLabel
								value="imperial"
								control={<Radio />}
								label="Imperial"
							/>
							<FormControlLabel
								value="metric"
								control={<Radio />}
								label="Metric"
							/>
							<FormControlLabel
								value="nautical"
								control={<Radio />}
								label="Nautical"
							/>
						</RadioGroup>
					</FormControl>
				)}
			</Box>
		</BettermapsModal>
	);
}
