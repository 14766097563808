import InsightsIcon from "@mui/icons-material/Insights";
import { Button } from "@mui/material";
import { PureComponent } from "react";
// import { HTMLOverlay } from "react-map-gl";

export default class ButtonMapControl extends PureComponent {
  redraw = () => {
    return (
      <Button
        style={this.props.style}
        variant="contained"
        onClick={this.props.onClick}
      >
        <InsightsIcon />
        Example text
      </Button>
    );
  };

  render = () => (
    // <HTMLOverlay captureClick={true} captureDrag={true} redraw={this.redraw} />
    <div></div>
  );
}
