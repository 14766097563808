import { TextField, InputAdornment } from "@mui/material";
import { bgcolor, styled } from "@mui/system";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
  },
  "& .MuiOutlinedInput-root": {
    height: "40px",
    borderRadius: "4px",
  },

  "&.extra-rounded .MuiOutlinedInput-root": {
    height: "37px",
    borderRadius: "100px",
  },
}));

export default function BettermapsTextField(props) {
	return (
		<StyledTextField
			id={`${props.label}-textfield`}
			style={{
				width: "100%",
				pointerEvents: "fill",
				borderRadius: "4px",
				...props.style,
			}}
			sx={props.sx}
			className={props.className}
			size={props.size || "small"}
			disabled={props.disabled}
			label={props.label}
			value={props.value}
			placeholder={props.placeholder}
			InputProps={{
				endAdornment: props.endAdornment && (
					<InputAdornment position="end">{props.endAdornment}</InputAdornment>
				),

				startAdornment: props.startAdornment && (
					<InputAdornment position="start">
						{props.startAdornment}
					</InputAdornment>
				),
				readOnly: props.readOnly || false,
			}}
			onChange={props.onChange}
			error={props.error}
			helperText={props.helperText}
			type={props.type}
		/>
	);
}
