import { useState } from "react";
import { MenuItem, MenuList } from "@mui/material";
import { propReduce } from "@turf/turf";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 10,
		position: "relative",
		backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
		border: "0px solid black",
		fontSize: 16,
		width: "20vw",
		textAlign: "right",
		padding: "5px 10px",
		transition: theme.transitions.create([
			"border-color",
			"background-color",
			"box-shadow",
		]),

		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
}));

export default function Geocoder(props) {
	const [value, setValue] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const userData = useSelector((state) => state.user);

	const handleChange = async (event) => {
		setValue(event);

		try {
			const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${event}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&autocomplete=true`;
			const response = await fetch(endpoint);
			const results = await response.json();

			setSuggestions(results?.features);
		} catch (error) {
			console.log("Error:", error);
		}
	};

	const handleClick = (s) => {
		props.flyTo(s);
	};
	return (
		<>
			<BootstrapInput
				placeholder="Search for address"
				value={value}
				onChange={(e) => {
					handleChange(e.target.value),
						analytics.identify(`Search for address value: ${e.target.value}`, {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
				}}
				sx={{
					position: "absolute",
					top: "-42px",
					right: props.onEditMode == true ? "180px" : "230px",
					zIndex: 9999,
					// textAlign: "right",
				}}></BootstrapInput>
			{suggestions?.length > 0 && (
				<MenuList
					sx={{
						position: "absolute",
						right: "180px",
						zIndex: 9999,
						top: "-10px",
						bgcolor: "white",
						border: "0px solid black",
						borderRadius: 2,
						overflow: "hidden",
					}}>
					{suggestions.map((suggestion, index) => {
						return (
							<MenuItem
								sx={{ display: "flex", justifyContent: "end" }}
								key={index}
								onClick={() => {
									setValue("");
									setSuggestions([]);
									handleClick(suggestion);
								}}>
								{suggestion.place_name}
							</MenuItem>
						);
					})}
				</MenuList>
			)}
		</>
	);
}
