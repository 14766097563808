import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import { setPlanInfo } from "../redux/slices/user";
import { countInfo } from "../services/StripeService";
import { useDispatch } from "react-redux";
export default function EditStylePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { subscription } = useSelector((state) => state.user);
  useEffect(() => {
    if (subscription) {
      if (
        subscription != "standard" &&
        subscription != "explorer" &&
        subscription != "pro"
      ) {
        enqueueSnackbar(
          "To use Bettermaps you need to choose a subscription plan",
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          }
        );
        history.push("/subscriptions");
      }
    } else {
      Auth.currentAuthenticatedUser().then((user) => {
        countInfo(user.attributes.email).then((c) => {
          if (c.plan == "standard" || c.plan == "explorer" || c.plan == "pro") {
            dispatch(setPlanInfo(c));
            // history.push("/dashboard");
          } else {
            history.push("/subscriptions");
          }
        });
      });
    }
  });
  return (
    <iframe
      src="https://maputnik.github.io/editor"
      width="100%"
      style={{ height: "calc(100vh - 50px)" }}
    />
  );
}
