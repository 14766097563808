export default function formateDate(dateISO) {
	const date = new Date(dateISO);
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const formatedDate = `${day.toString().padStart(2, "0")}-${month
		.toString()
		.padStart(2, "0")}-${year.toString().slice(2)}`;
	return formatedDate;
}
