import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetLayers } from "../../../../redux/slices/layers";
import { cleanStyle } from "../../../../redux/slices/style";
import { setMapStyle, setStyleName } from "../../../../redux/slices/style";
import { resetNewMap } from "../../../../redux/slices/map";
import { resetBoundingBox } from "../../../../redux/slices/boundingBox";

export default function NewMapModal(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector((state) => state.user);
	const resetMap = () => {
		dispatch(resetLayers());
		dispatch(cleanStyle());
		dispatch(resetNewMap());
		dispatch(resetBoundingBox());

		props.setShowLayerTiles(true);

		history.push(history.location.pathname);

		props.setShowTemplatesModal(true);
	};

	return (
		<Dialog
			open={props.show}
			onClose={() => props.setShow(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{"Are you sure you want to create a new map?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Upon confirming, the style will be reset. This action cannot be undo.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.setShow(false)} autoFocus>
					Cancel
				</Button>
				<Button
					color="error"
					onClick={() => {
						resetMap();
						props.setShow(false);
						analytics.identify("click on Create map confirm", {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
					}}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
