import { Box, Typography } from "@mui/material";
import { useMap } from "react-map-gl";
import { useState, useEffect } from "react";
import CustomOverlay from "./CustomOverlay";

export default function CurrentZoomLevel(props) {
  const myMap = useMap()[props.mapId];
  const [currentZoomLevel, setCurrentZoomLevel] = useState();

  useEffect(() => {
    if (!myMap) {
      return undefined;
    }

    const onMove = () => {
      setCurrentZoomLevel(myMap.getZoom());
    };

    myMap.on("move", onMove);

    onMove();

    return () => {
      myMap.off("move", onMove);
    };
  }, [myMap]);

  return (
    <CustomOverlay>
      <Box
        style={{
          position: "absolute",
          right: props.right,
          left: props.left,
          top: props.top,
          bottom: props.bottom,
          backgroundColor: "white",
          padding: "6px 15px",
          borderRadius: "3px",
        }}
      >
        <Typography>
          {Number(currentZoomLevel).toFixed(props.decimals || 2)}
        </Typography>
      </Box>
    </CustomOverlay>
  );
}
