import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  borderStyle: process.env.REACT_APP_ENV == "dev" ? "dashed" : "",
  borderWidth: "0.5px",
  alignItems: "center",
  justifyContent: "center",
}));

export default function MainContainer(props) {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={2} lg={1}></Grid>
          <Grid item xs={8} lg={10}>
            <Item>{props.content}</Item>
          </Grid>
          <Grid item xs={2} lg={1}></Grid>
        </Grid>
      </Box>
    </div>
  );
}
