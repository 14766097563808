import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function CheckAPIKeysModal(props) {
	const userData = useSelector((state) => state.user);
	const handleClick = () => {
		props.onConfirm();
		props.setShow(false);
		analytics.identify("click on Paper Map - Standalone Website - confirm", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	return (
		<Dialog
			open={props.show}
			onClose={() => props.setShow(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Please, check that your API keys are correctly stored on your Account
					settings. Otherwise, the exported map may not work...
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					onClick={() => {
						props.setShow(false),
							analytics.identify(
								"click on Paper Map - Standalone Website - cancel",
								{
									id: userData.ownerId,
									subscription: userData.subscription,
								}
							);
					}}
					autoFocus>
					Cancel
				</Button>
				<Button color="success" onClick={handleClick}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
