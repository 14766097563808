import { PureComponent } from "react";
// import { HTMLOverlay } from "react-map-gl";
import BettermapsWhite from "../../images/BM_H_White.svg";

export default class BettermapsOverlay extends PureComponent {
  redraw = () => {
    const ICON_HEIGHT = 32;
    const ICON_WIDTH = 160;
    const position = [5, 5];
    return (
      <div
        key={"ABC"}
        style={{ position: "absolute", left: position[0], bottom: position[1] }}
      >
        <img src={BettermapsWhite} height={ICON_HEIGHT} width={ICON_WIDTH} />
      </div>
    );
  };

  // render = () => <HTMLOverlay redraw={this.redraw} />;
  render = () => <div></div>;
}
