import { useSelector } from "react-redux";

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import BettermapsModal from "../../BettermapsModal";
import BettermapsTextField from "../../BettermapsTextField";

export default function MapExtendModal(props) {
	const [zoom, setZoom] = useState(Number(5).toFixed(2));
	const [scale, setScale] = useState(18489297);
	const [latitude, setLatitude] = useState(Number(40).toFixed(4));
	const [longitude, setLongitude] = useState(Number(-3).toFixed(4));
	const [changingField, setChangingField] = useState("ZOOM");
	const userData = useSelector((state) => state.user);

	const navigate = () => {
		props.setShow(false);
		if (props.myMap) {
			props.myMap.flyTo({
				center: [longitude, latitude],
				zoom: zoom,
			});
		}
		analytics.identify("Map Extent - save myMap", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const getUpdatedZoom = () => {
		return Number(23 - Math.log(scale / 70.53) / Math.log(2)).toFixed(2);
	};

	const getUpdatedScale = () => {
		return Number(70.53 * Math.pow(2, 23 - zoom)).toFixed(0);
	};

	useEffect(() => {
		if (changingField === "SCALE") {
			setZoom(getUpdatedZoom());
		}
	}, [scale]);

	useEffect(() => {
		if (changingField === "ZOOM") {
			setScale(getUpdatedScale());
		}
	}, [zoom]);

	useEffect(() => {
		if (props.myMap) {
			const updateState = () => {
				setZoom(Number(props.myMap.getZoom()).toFixed(2));
				setLatitude(Number(props.myMap.getCenter().lat).toFixed(4));
				setLongitude(Number(props.myMap.getCenter().lng).toFixed(4));
				setScale(Number(getUpdatedScale()).toFixed(0));
			};

			props.myMap.on("styledata", () => updateState());
			props.myMap.on("move", () => updateState());
			props.myMap.off("move", () => updateState());
		}
	}, [props.myMap]);

	const isCorrectLatitude = () => latitude >= -90 && latitude <= 90;
	const isCorrectLongitude = () => longitude >= -180 && longitude <= 180;
	const isCorrectZoom = () => zoom >= 0 && zoom <= 23;

	const handleChangeLatitude = (e) => {
		setLatitude(e.target.value),
			analytics.identify(`Map Extent - Latitude: ${e.target.value}`, {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
	};

	const handleChangeLongitude = (e) => {
		setLongitude(e.target.value);
		analytics.identify(`Map Extent - Longitude: ${e.target.value}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleChangeZoom = (e) => {
		setChangingField("ZOOM");
		setZoom(e.target.value);
		analytics.identify(`Map Extent - Zoom: ${e.target.value}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleChangeScale = (e) => {
		setChangingField("SCALE");
		setScale(e.target.value);
		analytics.identify(`Map Extent - Scale: ${e.target.value}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	return (
		<BettermapsModal
			sx={{ width: "30%", minWidth: "370px" }}
			title="Map Extent"
			showModal={props.show}
			setShowModal={props.setShow}
			onDone={navigate}
			doneText="Save"
			doneDisabled={
				!(isCorrectLatitude() && isCorrectLongitude() && isCorrectZoom())
			}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					mt: 2,
				}}>
				<BettermapsTextField
					sx={{ mr: 2 }}
					style={{ maxWidth: "227px" }}
					label="Latitude"
					value={latitude}
					onChange={(e) => handleChangeLatitude(e)}
					error={!isCorrectLatitude()}
					helperText={
						isCorrectLatitude() ? null : "Latitude should be in range [-90, 90]"
					}></BettermapsTextField>
				<BettermapsTextField
					style={{ maxWidth: "227px" }}
					label="Longitude"
					value={longitude}
					onChange={(e) => {
						handleChangeLongitude(e);
					}}
					error={!isCorrectLongitude()}
					helperText={
						isCorrectLongitude()
							? null
							: "Longitude should be in range [-180, 180]"
					}></BettermapsTextField>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					mt: 5,
				}}>
				<BettermapsTextField
					sx={{ mr: 2 }}
					style={{ maxWidth: "227px" }}
					label="Zoom"
					value={zoom}
					onChange={(e) => {
						handleChangeZoom(e);
					}}
					error={!isCorrectZoom()}
					helperText={
						isCorrectZoom() ? null : "Zoom should be in range [0, 23]"
					}></BettermapsTextField>
				<BettermapsTextField
					style={{ maxWidth: "227px" }}
					label="Scale"
					value={scale}
					onChange={(e) => {
						handleChangeScale(e);
					}}
					startAdornment="1:"
					endAdornment={
						props.scaleUnit == "imperial"
							? "mi"
							: props.scaleUnit == "metric"
							? "m"
							: "nm"
					}
					error={!isCorrectZoom()}
					helperText={
						isCorrectZoom() ? null : "Zoom should be in range [0, 23]"
					}></BettermapsTextField>
			</Box>
		</BettermapsModal>
	);
}
