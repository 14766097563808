import { createSlice } from "@reduxjs/toolkit";
import { LocalVariables } from "../../lib/LocalStorageVariables";
import { getlayers } from "./layers";
import { getBoundingBox } from "./boundingBox";
import { getStyleId, setMapStyle, setStyleName } from "./style";

export const localDataSlice = createSlice({
  name: "localData",
  initialState: {
    data: {
      BettermapsVersion: "bettermapsVersion",
      Style: "style",
      ShowLayerTiles: "showLayerTiles",
      StyleName: "styleName",
      StyleId: "styleId",
      Scale: "scale",
      Navigation: "navigation",
      Legend: "legend",
      Attribution: "attribution",
      PaperSettings: "paperSettings",
      ExportSettings: "exportSettings",
    },
  },
  reducers: {
    setDataBettermapsVersion: (state, action) => {
      state.data.BettermapsVersion = action.payload;
    },
    setDataStyle: (state, action) => {
      state.data.Style = action.payload;
    },
    setDataShowLayerTiles: (state, action) => {
      state.data.ShowLayerTiles = action.payload;
    },
    setDataStyleName: (state, action) => {
      state.data.StyleName = action.payload;
    },
    setDataStyleId: (state, action) => {
      state.data.StyleId = action.payload;
    },
    setDataScale: (state, action) => {
      state.data.Scale = action.payload;
    },
    setDataNavigation: (state, action) => {
      state.data.Navigation = action.payload;
    },
    setDataLegend: (state, action) => {
      state.data.Legend = action.payload;
    },
    setDataAttribution: (state, action) => {
      state.data.Attribution = action.payload;
    },
    setDataPaperSettings: (state, action) => {
      state.data.PaperSettings = action.payload;
    },
    setDataExportSettings: (state, action) => {
      state.data.ExportSettings = action.payload;
    },
  },
});

export const { setDataBettermapsVersion } = localDataSlice.actions;
export const { setDataStyle } = localDataSlice.actions;
export const { setDataShowLayerTiles } = localDataSlice.actions;
export const { setDataStyleName } = localDataSlice.actions;
export const { setDataScale } = localDataSlice.actions;
export const { setDataNavigation } = localDataSlice.actions;
export const { setDataLegend } = localDataSlice.actions;
export const { setDataAttribution } = localDataSlice.actions;
export const { setDataPaperSettings } = localDataSlice.actions;
export const { setDataExportSettings } = localDataSlice.actions;

export default localDataSlice.reducer;

export const getLocalStorageData = () => (dispatch) => {
  if (localStorage.getItem(LocalVariables.BettermapsVersion)) {
    dispatch(
      setDataBettermapsVersion(
        localStorage.getItem(LocalVariables.BettermapsVersion)
      )
    );
  }

  if (window.localStorage.getItem(LocalVariables.Layers)) {
    dispatch(
      getlayers(
        JSON.parse(window.localStorage.getItem(LocalVariables.Layers)).value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.Style)) {
    dispatch(
      setMapStyle(
        JSON.parse(window.localStorage.getItem(LocalVariables.Style)).value
      )
    );
  } else {
    dispatch(
      setMapStyle(
        process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.ShowLayerTiles)) {
    dispatch(
      setDataShowLayerTiles(
        JSON.parse(window.localStorage.getItem(LocalVariables.ShowLayerTiles))
          .value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.StyleName)) {
    dispatch(
      setStyleName(
        JSON.parse(window.localStorage.getItem(LocalVariables.StyleName)).value
      )
    );
  } else {
    dispatch(setStyleName("This is the Title of the Map"));
  }
  if (window.localStorage.getItem(LocalVariables.StyleId)) {
    dispatch(
      getStyleId(
        JSON.parse(window.localStorage.getItem(LocalVariables.StyleId)).value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.Scale)) {
    dispatch(
      setDataScale(
        JSON.parse(window.localStorage.getItem(LocalVariables.Scale)).value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.Navigation)) {
    dispatch(
      setDataNavigation(
        JSON.parse(window.localStorage.getItem(LocalVariables.Navigation)).value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.Legend)) {
    dispatch(
      setDataLegend(
        JSON.parse(window.localStorage.getItem(LocalVariables.Legend)).value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.Attribution)) {
    dispatch(
      setDataAttribution(
        JSON.parse(window.localStorage.getItem(LocalVariables.Attribution))
          .value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.PaperSettings)) {
    dispatch(
      setDataPaperSettings(
        JSON.parse(window.localStorage.getItem(LocalVariables.PaperSettings))
          .value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.ExportSettings)) {
    dispatch(
      setDataExportSettings(
        JSON.parse(window.localStorage.getItem(LocalVariables.ExportSettings))
          .value
      )
    );
  }
  if (window.localStorage.getItem(LocalVariables.MapBoundingBox)) {
    dispatch(
      getBoundingBox(
        JSON.parse(window.localStorage.getItem(LocalVariables.MapBoundingBox))
          .value
      )
    );
  }
};
