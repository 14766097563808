import { LayerType } from "./LayerType";

export default function toLayer(mapboxLayer) {
  if (mapboxLayer.type.value == LayerType.Fill.value) {
    return toFillLayer(mapboxLayer);
  } else if (mapboxLayer.type.value == LayerType.Line.value) {
    return toLineLayer(mapboxLayer);
  } else if (mapboxLayer.type.value == LayerType.Circle.value) {
    return toCircleLayer(mapboxLayer);
  } else if (mapboxLayer.type.value == LayerType.Raster.value) {
    return toRasterLayer(mapboxLayer);
  } else if (mapboxLayer.type.value == LayerType.Invert.value) {
    return toFillReverseLayer(mapboxLayer);
  }
}

function toFillLayer(mapboxLayer) {
  return {
    id: mapboxLayer.id,
    source: mapboxLayer.source.id,
    type: LayerType.Fill.value,
    before: mapboxLayer.beforeId,
    layout: {
      visibility: mapboxLayer.show ? "visible" : "none",
    },
    paint: {
      "fill-outline-color": `rgba(${mapboxLayer.lineColor.r},${mapboxLayer.lineColor.g},${mapboxLayer.lineColor.b},${mapboxLayer.lineColor.a})`,
      "fill-color": ["get", "color"],
      "fill-opacity": 1,
    },
  };
}

function toLineLayer(mapboxLayer) {
  return {
    id: mapboxLayer.id,
    source: mapboxLayer.source.id,
    type: LayerType.Line.value,
    before: mapboxLayer.beforeId,
    layout: {
      visibility: mapboxLayer.show ? "visible" : "none",
    },
    paint: {
      "line-width": 2,
      "line-color": ["get", "color"],
      "line-opacity": mapboxLayer.opacity,
    },
  };
}

function toCircleLayer(mapboxLayer) {
  return {
    id: mapboxLayer.id,
    source: mapboxLayer.source.id,
    type: LayerType.Circle.value,
    before: mapboxLayer.beforeId,
    layout: {
      visibility: mapboxLayer.show ? "visible" : "none",
    },
    paint: {
      "circle-color": ["get", "color"],
      "circle-stroke-color": `rgba(${mapboxLayer.lineColor.r},${mapboxLayer.lineColor.g},${mapboxLayer.lineColor.b},${mapboxLayer.lineColor.a})`,
      "circle-stroke-width": 2,
      "circle-opacity": mapboxLayer.opacity,
    },
  };
}

function toRasterLayer(mapboxLayer) {
  return {
    id: mapboxLayer.id,
    type: LayerType.Raster.value,
    beforeId: mapboxLayer.beforeId,
    layout: {
      visibility: mapboxLayer.show ? "visible" : "none",
    },
    source: mapboxLayer.source.id,
    paint: {
      "raster-opacity": mapboxLayer.opacity,
    },
  };
}

export function toSource(source) {
  if (source.type == "geojson") {
    return {
      id: source.id,
      type: source.type,
      data: source.data,
    };
  } else if (source.type == "raster") {
    return {
      id: source.id,
      type: source.type,
      tiles: source.tiles,
      tileSize: source.tileSize,
    };
  }
}

function toFillReverseLayer(layer) {
  return {
    id: layer.id,
    source: layer.source.id,
    type: "fill",
    before: layer.beforeId,
    layout: {
      visibility: layer.show ? "visible" : "none",
    },
    paint: {
      // "line-color": layer.lineColor,
      "fill-outline-color": layer.lineColor,
      "fill-color": layer.fillColor,
      "fill-opacity": layer.opacity,
    },
  };
}
export function toReverseSource(source) {
  return {
    id: source.id,
    type: source.type,
    data: source.data,
  };
}
