import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import BettermapsTextField from "../BettermapsTextField";
import BettermapsSelect from "../BettermapsSelect";
import { useSelector } from "react-redux";

export default function LayersFilter(props) {
	const [filterType, setFilterType] = useState("name");
	const [nameFilter, setNameFilter] = useState("");
	const [locationFilter, setLocationFilter] = useState("");
	const [geometryFilter, setGeometryFilter] = useState("");
	const [filtersObject, setFiltersObject] = useState({});
	const [nameLabel, setNameLabel] = useState("");
	const [locationLabel, setLocationLabel] = useState("");
	const [geometryLabel, setGeometryLabel] = useState("");
	const theme = useTheme();
	const userData = useSelector((state) => state.user);

	useEffect(() => {
		if (props.filters) {
			setFiltersObject(props.filters);
		}
	}, []);

	const setObject = () => {
		setFiltersObject({
			name: nameFilter,
			location: locationFilter,
			geometry: geometryFilter,
		});
	};

	useEffect(() => {
		if (!nameFilter) {
			setObject();
		}
	}, [nameFilter]);
	useEffect(() => {
		if (!locationFilter) {
			setObject();
		}
	}, [locationFilter]);
	useEffect(() => {
		if (!geometryFilter) {
			setObject();
		}
	}, [geometryFilter]);

	useEffect(() => {
		props.setFilters(filtersObject);
	}, [filtersObject]);

	const handleAddFilter = () => {
		if (nameFilter) {
			setNameLabel(`Name: ${nameFilter}`);
		} else {
			setNameLabel("");
		}
		if (locationFilter) {
			setLocationLabel(`Location: ${locationFilter}`);
		} else {
			setLocationLabel("");
		}
		if (geometryFilter) {
			setGeometryLabel(`Geometry: ${geometryFilter}`);
		} else {
			setGeometryLabel("");
		}
		setObject();
		analytics.identify("click on ADD GeoJSON", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleDeleteFilterLocation = () => {
		setLocationFilter("");
		setLocationLabel("");
	};

	const handleDeleteFilterName = () => {
		setNameFilter("");
		setNameLabel("");
	};

	const handleDeleteFilterGeometry = () => {
		setGeometryFilter("");
		setGeometryLabel("");
	};

	return (
		<Grid
			item
			xl={7}
			lg={7}
			md={7}
			sm={7}
			xs={7}
			style={{ padding: "7px 0px 7px 7px" }}>
			<Stack>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}>
					<Box sx={{ display: "flex", width: "22rem" }}>
						<BettermapsSelect
							label="Select filter"
							value={filterType}
							onChange={(e) => {
								setFilterType(e.target.value),
									analytics.identify(`Select Filter: ${e.target.value}`, {
										id: userData.ownerId,
										subscription: userData.subscription,
									});
							}}
							sx={{ marginRight: "15px" }}>
							<MenuItem value="name">Name</MenuItem>
							<MenuItem value="location">Location</MenuItem>
							<MenuItem value="geometry">Geometry</MenuItem>
						</BettermapsSelect>
						{filterType == "name" && (
							<BettermapsTextField
								sx={{ height: "10px" }}
								label="Name Filter"
								onChange={(e) =>
									setNameFilter(e.target.value)
								}></BettermapsTextField>
						)}
					</Box>
					{filterType == "location" && (
						<BettermapsTextField
							label="Location filter"
							onChange={(e) => {
								setLocationFilter(e.target.value),
									analytics.identify(`Location Filter: ${e.target.value}`, {
										id: userData.ownerId,
										subscription: userData.subscription,
									});
							}}></BettermapsTextField>
					)}
					{filterType == "geometry" && (
						<BettermapsSelect
							label="Geometry filter"
							onChange={(e) => setGeometryFilter(e.target.value)}>
							<MenuItem value="Point">Point</MenuItem>
							<MenuItem value="Line">Line</MenuItem>
							<MenuItem value="Polygon">Polygon</MenuItem>
						</BettermapsSelect>
					)}

					<Button
						variant="contained"
						onClick={handleAddFilter}
						sx={{
							borderRadius: "4px",
							height: "40px",
							width: "auto",
							marginLeft: "10px",
						}}>
						ADD
					</Button>
				</Box>
				<Box sx={{ marginTop: "5px" }}>
					{nameLabel && (
						<Chip
							size="small"
							label={nameLabel}
							sx={{
								mb: "4px",
								mr: "6px",
								borderRadius: "8px",
							}}
							onDelete={handleDeleteFilterName}
						/>
					)}
					{locationLabel && (
						<Chip
							size="small"
							label={locationLabel}
							sx={{
								mb: "4px",
								mr: "6px",
								borderRadius: "8px",
							}}
							onDelete={handleDeleteFilterLocation}
						/>
					)}
					{geometryLabel && (
						<Chip
							size="small"
							label={geometryLabel}
							sx={{
								mb: "4px",
								mr: "6px",
								borderRadius: "8px",
							}}
							onDelete={handleDeleteFilterGeometry}
						/>
					)}
				</Box>
				<Box>
					<FormControlLabel
						style={{ marginLeft: "-10px" }}
						control={
							<Checkbox
								checked={props.showHistorical}
								onChange={() => {
									props.setShowHistorical(!props.showHistorical),
										analytics.identify(
											"click on Add GeoJSON Layer - Show historical layers",
											{
												id: userData.ownerId,
												subscription: userData.subscription,
											}
										);
								}}
							/>
						}
						label="Show historical layers"
					/>
				</Box>
				<Box>
					{Object.keys(props.tags).map((tag, i) =>
						tag == "null" ? null : (
							<Chip
								key={i}
								size="small"
								label={tag}
								variant={props.tags[tag] ? "filled" : "outlined"}
								sx={{
									mb: "4px",
									mr: "6px",
									borderRadius: "8px",
								}}
								style={{
									backgroundColor: props.tags[tag]
										? theme.palette.primary.main
										: theme.palette.secondary.main,
									color: props.tags[tag] ? "white" : "grey",
									borderColor: props.tags[tag]
										? theme.palette.primary.main
										: "grey",
								}}
								onClick={() => {
									props.setTags((tags) => {
										return { ...tags, [tag]: !props.tags[tag] };
									});
									analytics.identify(`click on Add GeoJSON Layer: ${tag}`, {
										id: userData.ownerId,
										subscription: userData.subscription,
									});
								}}
							/>
						)
					)}
				</Box>
			</Stack>
		</Grid>
	);
}
