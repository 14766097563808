import { Container, Grid, CircularProgress, Box } from "@mui/material/";
import { Layer, Source } from "react-map-gl";
import MapboxMap from "../common/MapboxMap";

const layerStyle = {
  id: "point",
  type: "circle",
  paint: {
    "circle-radius": 10,
    "circle-color": "#007cbf",
  },
};

export default function MapImageAnalysisPreview(props) {
  return (
    <Container
      sx={{
        py: 2,
        backgroundColor: "var(--secondary-bg-color)",
        borderRadius: "16px",
        marginTop: "32px",
      }}
      maxWidth="sm"
    >
      <Grid container direction="column" display="flex" justifyItems="center">
        <Grid item sx={{ width: "100%" }}></Grid>
        <Grid item sx={{ width: "100%" }} style={{ position: "relative" }}>
          <Box
            style={{
              position: "absolute",
              display: props.loading ? "block" : "none",
              width: "100%",
              height: "100%",
              zIndex: 1,
              backgroundColor: "rgba(200, 200, 200, 0.5)",
            }}
          >
            <CircularProgress
              color="primary"
              style={{ position: "absolute", top: "45%" }}
            />
          </Box>
          <MapboxMap
            id="image-analysis-preview-map"
            height="70vh"
            mapStyle={
              process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
            }
            content={
              <Source
                id="Geojson Layer Source"
                type="geojson"
                key={"Geojson Layer"}
                data={props.geoJson}
              >
                <Layer {...layerStyle} />
              </Source>
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
}
