import { Box, Container, Grid } from "@mui/material/";
import { getGeoJSONFromLocationsOnImage } from "../../services/ImageAnalysisService";
import BetterMapsDropzone from "../common/BetterMapsDropzone";
import React, { useState } from "react";
import { useSnackbar } from "notistack";

export default function MapImageAnalyser(props) {
  const [url, setUrl] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onDropHandler = (acceptedFiles) => {
    props.setLoading(true);
    if (acceptedFiles[0]) {
      setUrl(URL.createObjectURL(acceptedFiles[0]));
      getGeoJSONFromLocationsOnImage(acceptedFiles[0])
        .then((geojson) => {
          props.setGeoJson(geojson);
          enqueueSnackbar("The image has been correctly analyzed!", {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          });
        })
        .catch((e) => {
          enqueueSnackbar(`Error: ${e.message ? e.message : e}`, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          });
          setUrl(null);
        });
      props.setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Container
        sx={{
          py: 2,
          backgroundColor: "var(--secondary-bg-color)",
          borderRadius: "16px",
          marginTop: "32px",
        }}
        maxWidth="sm"
      >
        <Grid container display="flex" justifyItems="center">
          <Grid
            item
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <BetterMapsDropzone
              onDropHandler={onDropHandler}
              ctaText={["DROP YOUR", <br />, "IMAGE FILE HERE"]}
              maxFiles={1}
              accept={"image/jpeg, image/png"}
              key="This Dropzone"
            />
            <Box>
              {url && (
                <img alt="target" width="100%" margin="10px" src={url}></img>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
