import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import WebMapStyleAnalyser from "../components/AnalyseWebMapStylePage/WebMapStyleAnalyser";
import WebMapStyleAnalysisPreview from "../components/AnalyseWebMapStylePage/WebMapStyleAnalysisPreview";
import ZoomViews from "../components/common/ZoomViews";

export default function AnalyseWebMapStylePage() {
  const [styleAnalysis, setStyleAnalysis] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  return (
		<React.Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Analyse Map Style</title>
			</Helmet>
			<Box
				disableGutters
				style={{
					maxWidth: "100%",
					height: "calc(100vh - 50px)",
					paddingTop: "15px",
					paddingLeft: "15px",
					paddingRight: "15px",
					paddingBottom: "15px",
					backgroundColor: theme.palette.secondary.main,
					overflow: "auto",
					position: "relative",
				}}>
				<WebMapStyleAnalyser
					setStyleAnalysis={setStyleAnalysis}
					setLoading={setLoading}
				/>
				<WebMapStyleAnalysisPreview
					styleAnalysis={styleAnalysis}
					loading={loading}
				/>
				{/* <ZoomViews layers={[]} /> */}
			</Box>
		</React.Fragment>
	);
}
