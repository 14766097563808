import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { SnackbarProvider } from "notistack";
import React from "react";

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

export default function BettermapsSnackbarProvider(props) {
  const StyledSnackbarProvider = styled(SnackbarProvider)({
    "&.SnackbarContent-root": {
      opacity: 0.8,
    },
  });

  return (
    <StyledSnackbarProvider
      ref={notistackRef}
      {...props}
      children={props.children}
      action={(key) => (
        <Button style={{ color: "white" }} onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    />
  );
}
