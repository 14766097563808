import { Box, Button, TextField } from "@mui/material";
import BettermapsModal from "../../BettermapsModal";

export default function DrawLayerDetailsModal(props) {
  const handleClose = () => {
    props.setShow(false);
    props.setIsLoading(false);
    props.setFeatures([]);
    props.setShowModal(false);
  };

  const handleSave = () => {
    props.setShow(false);
    props.done();
  };

  return (
    <BettermapsModal
      sx={{ width: "500px" }}
      title="Layer Name"
      showModal={props.show}
      setShowModal={handleClose}
    >
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          value={props.layerName}
          size="small"
          onChange={(e) => props.setLayerName(e.target.value)}
        ></TextField>
        <Button variant="contained" sx={{ mt: "10px" }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </BettermapsModal>
  );
}
