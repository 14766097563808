import { createSlice } from "@reduxjs/toolkit";

export const boundingBoxSlice = createSlice({
  name: "boundingBox",
  initialState: {
    boundingBox: [],
  },
  reducers: {
    setBoundingBox: (state, action) => {
      state.boundingBox = action.payload;
    },
    resetBoundingBox: (state) => {
      state.boundingBox = [];
    },
  },
});

export const { setBoundingBox, resetBoundingBox } = boundingBoxSlice.actions;

export default boundingBoxSlice.reducer;

export const getBoundingBox = (boundingBox) => (dispatch) => {
  dispatch(setBoundingBox(boundingBox));
};
