import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
} from "@mui/material";
import * as turf from "@turf/turf";
import { useEffect, useState } from "react";
import { v4 as randomID } from "uuid";
import { useColor } from "../../../../lib/hooks";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { LayerType } from "../../../../lib/LayerType";
import {
  getAllTags,
  getGeoJSONs,
} from "../../../../services/DatasourcesService";
import BettermapsModal from "../../BettermapsModal";
import BettermapsSelect from "../../BettermapsSelect";
import LayerDetails from "../LayerDetails";
import LayersFilter from "../LayersFilter";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import MapBoxSource from "../../MapBox/MapBoxSource";
import { getLayerFeatures } from "../../../../services/DatasourcesService";
import UploadJSONModal from "../modals/UploadJSONModal";
import { useDispatch, useSelector } from "react-redux";
import { addLayer } from "../../../../redux/slices/layers";

export default function GeoJSONModal(props) {
	const dispatch = useDispatch();
	const { layers } = useSelector((state) => state.layers);
	const [geojsonList, setGeojsonList] = useState([]);
	const [geojsonFile, setGeojsonfile] = useState("");
	const [tags, setTags] = useState("");
	const [showHistorical, setShowHistorical] = useState(false);
	const [lineColor, setLineColor] = useColor("#02c100");
	const [fillColor, setFillColor] = useColor("#cd0000");
	const [layerName, setLayerName] = useState("");
	const [layerType, setLayerType] = useState(LayerType.Fill);
	const [selectedAccessUrl, setSelectedAccessUrl] = useState();
	const [zoomOnLoad, setZoomOnLoad] = useState(true);
	const [filters, setFilters] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [geojsonError, setGeojsonError] = useState(false);
	const [collection, setCollection] = useState([]);
	const [boundingBox, setBoundingBox] = useState([]);
	const [gotTags, setGotTags] = useState(false);
	const [selectColor, setSelectColor] = useState("random");
	const userData = useSelector((state) => state.user);

	useEffect(() => {
		getAllTags().then((d) => {
			setTags(d);
		});
	}, []);

	useEffect(() => {
		if (tags.length == 0) {
		} else {
			setIsLoading(true);
			getGeoJSONs(tags, showHistorical, filters).then((data) => {
				data.map((g) => {
					if (g.type_of_geometry) {
						g.type_of_geometry = g.type_of_geometry.trim();
					}
				});

				setGeojsonList(data);
			});
		}
	}, [tags]);

	useEffect(() => {
		if (geojsonList.message) {
			setGeojsonError(true);
		} else {
			setGeojsonError(false);
		}
	}, [geojsonList]);
	useEffect(() => {
		if (gotTags == true) {
			setIsLoading(true);

			getGeoJSONs(tags, showHistorical, filters).then((d) => {
				d.map((g) => {
					if (g.type_of_geometry) {
						g.type_of_geometry = g.type_of_geometry.trim();
					}
				});

				setGeojsonList(d);
			});
		}
	}, [tags, showHistorical, filters]);

	useEffect(() => {
		if (geojsonList.length == 0) {
			setGeojsonError(true);
		} else {
			setGeojsonError(false);
			setGotTags(true);
		}

		setIsLoading(false);
	}, [geojsonList]);

	const getLayerType = (access_url) => {
		const geometry = geojsonList.find(
			(g) => g.access_url === access_url
		).type_of_geometry;

		if (geometry === "Point") return LayerType.Circle;
		if (geometry === "Line") return LayerType.Line;
		else return LayerType.Fill;
	};

	const getLayerName = (access_url) => {
		return geojsonList.find((g) => g.access_url === access_url).name_;
	};

	const getChipColor = (geometry) => {
		if (geometry === "Line") return LayerType.Line.color;
		if (geometry === "Point") return LayerType.Circle.color;
		else return LayerType.Fill.color;
	};

	// useEffect(() => {
	// 	if (selectedAccessUrl != undefined && selectedAccessUrl !== "") {
	// 		const polygon = turf.polygon(
	// 			JSON.parse(
	// 				geojsonList.find((g) => g.access_url === selectedAccessUrl).geometry
	// 			).coordinates
	// 		);
	// 		setBoundingBox(turf.bbox(polygon));
	// 	}
	// }, selectedAccessUrl);

	useEffect(() => {
		if (selectedAccessUrl != undefined && selectedAccessUrl !== "") {
			const polygon = turf.polygon(
				JSON.parse(
					geojsonList.find((g) => g.access_url === selectedAccessUrl).geometry
				).coordinates
			);
			setBoundingBox(turf.bbox(polygon));
		}
	}, [selectedAccessUrl]);

	const zoomToBoundingBox = () => {
		props.map.getMap().fitBounds(boundingBox);
	};

	function addFeatures() {
		var typeColor = `rgba(${fillColor.rgb.r},${fillColor.rgb.g},${fillColor.rgb.b},${fillColor.rgb.a})`;
		if (layerType.value == LayerType.Line.value) {
			typeColor = `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`;
		}
		getLayerFeatures(geojsonFile).then((d) => {
			MapBoxSource(d, selectColor, typeColor).then((s) => {
				addGeoJSONLayer(s);
			});
		});
	}
	function addGeoJSONLayer(d) {
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();

		const newLayer = {
			id: layerID,
			name: layerName,
			fileType: LayerFileType.GeoJSON,
			type: layerType,
			show: true,
			fillColor: fillColor.rgb,
			lineColor: lineColor.rgb,
			selectColor: selectColor,
			featureColors: d.featureColors,
			boundingbox: boundingBox,
			urlSource: geojsonFile,
			opacity: layerType == LayerType.Line ? lineColor.rgb.a : fillColor.rgb.a,
			source: {
				id: sourceID,
				type: "geojson",
				data: d.data,
			},
			showOnHover: false,
			hoverProperties: [],
		};
		dispatch(addLayer(newLayer));
		closeGeoJSONModal();
		if (zoomOnLoad) zoomToBoundingBox();
	}

	const closeGeoJSONModal = () => props.setShowGeoJSONModal(false);

	return (
		<BettermapsModal
			sx={{ width: "70%", maxWidth: "800px" }}
			title="Add GeoJSON Layer"
			showModal={props.showGeoJSONModal}
			setShowModal={props.setShowGeoJSONModal}
			onDone={addFeatures}
			doneText="Add"
			doneDisabled={geojsonFile === ""}>
			<Grid container direction="row">
				<LayerDetails
					label="GeoJSON"
					fillColor={fillColor}
					setFillColor={setFillColor}
					lineColor={lineColor}
					setLineColor={setLineColor}
					layerName={layerName}
					setLayerName={setLayerName}
					layerType={layerType}
					selectColor={selectColor}
					setSelectColor={setSelectColor}></LayerDetails>
				<LayersFilter
					tags={tags}
					setTags={setTags}
					showHistorical={showHistorical}
					setShowHistorical={setShowHistorical}
					filters={filters}
					setFilters={setFilters}></LayersFilter>
			</Grid>
			<Box
				sx={{
					height: "80px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				{isLoading ? (
					<CircularProgress></CircularProgress>
				) : geojsonError ? (
					<Typography>NO GEOJSONS FOUND</Typography>
				) : (
					<BettermapsSelect
						fullWidth
						value={geojsonFile}
						onChange={(e) => {
							setGeojsonfile(e.target.value);
							setLayerType(getLayerType(e.target.value));
							setLayerName(getLayerName(e.target.value));
							setSelectedAccessUrl(e.target.value);
						}}
						label="Layers">
						{geojsonList.map((geojson, index) => (
							<MenuItem key={index} value={geojson.access_url}>
								<Box
									style={{
										display: "flex",
										width: "100%",
										justifyContent: "space-between",
									}}
									onClick={() =>
										analytics.identify(
											`click on GeoJSON Layers - Layers: ${geojson.name_}, ${geojson.location_}`,
											{
												id: userData.ownerId,
												subscription: userData.subscription,
											}
										)
									}>
									{geojson.location_ && geojson.location_ !== ""
										? `${geojson.name_} | ${geojson.location_}`
										: geojson.name_}
									<Chip
										size="small"
										label={geojson.type_of_geometry}
										variant="outlined"
										sx={{
											mb: "4px",
											mr: "6px",
											borderColor: getChipColor(geojson.type_of_geometry),
											bgcolor: getChipColor(geojson.type_of_geometry),
											color: "dark-grey",
										}}
									/>
								</Box>
							</MenuItem>
						))}
					</BettermapsSelect>
				)}
			</Box>
			<FormControlLabel
				style={{
					position: "absolute",
					left: "32px",
					bottom: "32px",
				}}
				control={
					<Checkbox
						checked={zoomOnLoad}
						onChange={() => setZoomOnLoad(!zoomOnLoad)}
					/>
				}
				label="Zoom on load"
			/>
			<UploadJSONModal props={props}></UploadJSONModal>
		</BettermapsModal>
	);
}
