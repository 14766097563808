export default function buildStandAloneWebsite(styleAsString, zoom, center) {
  return `
  <!DOCTYPE html>
  <html>
    <head>
        <meta charset="utf-8">
        <title>Display a map on a webpage</title>
        <meta name="viewport" content="initial-scale=1,maximum-scale=1,user-scalable=no">
        <script src='https://unpkg.com/maplibre-gl@2.1.9/dist/maplibre-gl.js'></script>
        <link href='https://unpkg.com/maplibre-gl@2.1.9/dist/maplibre-gl.css' rel='stylesheet' />
        <style>
            body { margin: 0; padding: 0; }
            #map { position: absolute; top: 0; bottom: 0; width: 100%; }
        </style>
    </head>
    <body>
        <div id="map"></div>
        <script>
        const map = new maplibregl.Map({
            container: 'map',
            style: ${styleAsString}, 
            center: [${center.lng}, ${center.lat}], // starting position [lng, lat]
            zoom: ${zoom}, // starting zoom
            attributionControl: false
        });
        const navigationControl = new maplibregl.NavigationControl();
        const attributionControl = new maplibregl.AttributionControl();
        const scaleControl = new maplibregl.ScaleControl();
        map.addControl(navigationControl, 'top-right');
        map.addControl(attributionControl, 'bottom-right');
        map.addControl(scaleControl, 'bottom-left');
    </script>
    </body>
  </html>
    `;
}
