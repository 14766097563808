import { Typography } from "@mui/material";
import { useState } from "react";
import { useColor } from "../../../../../lib/hooks";
import BettermapsModal from "../../../BettermapsModal";
import BettermapsTextField from "../../../BettermapsTextField";
import { AlphaPicker } from "react-color";
import { useTheme } from "@mui/material/styles";
import { decimalAlphaToHex } from "../../../../../lib/color";
import { modifyWmsLayer } from "../../../../../redux/slices/layers";
import { useDispatch, useSelector } from "react-redux";

export default function EditWMSModal(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);
  var temporalLayers = layers;
  const [color, setColor] = useColor(
    `${theme.palette.primary.main}${decimalAlphaToHex(props.layer.opacity)}`
  );
  const [layerName, setLayerName] = useState(props.layer.name);

  function editWMSLayer() {
    const objIndex = temporalLayers.findIndex(
      (obj) => obj.id == props.layer.id
    );

    dispatch(modifyWmsLayer([objIndex, layerName, color.rgb.a]));
    props.setShow(false);
  }

  return (
    <BettermapsModal
      sx={{ width: "450px" }}
      title="Edit WMS Layer"
      showModal={props.show}
      setShowModal={props.setShow}
      onDone={editWMSLayer}
      doneText="Save"
    >
      <BettermapsTextField
        label="Layer name"
        value={layerName}
        onChange={(e) => setLayerName(e.target.value)}
      ></BettermapsTextField>
      <Typography variant="body1" sx={{ mt: "20px", mb: "5px" }}>
        Transparency
      </Typography>
      <AlphaPicker color={color.rgb} onChange={setColor} width={150} />
    </BettermapsModal>
  );
}
