import Dropzone from "react-dropzone";

function randomId(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function BetterMapsDropzone(props) {
  return (
    <Dropzone
      noClick={true}
      key="Dropzone"
      maxFiles={props.maxFiles}
      accept={props.accept}
      onDrop={props.onDropHandler}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input key={randomId(8)} {...getInputProps()} />
          <p key={props.maxFiles}>{props.ctaText}</p>
        </div>
      )}
    </Dropzone>
  );
}

export default BetterMapsDropzone;
