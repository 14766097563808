import { useSelector } from 'react-redux';

import Dashboard from '@mui/icons-material/Dashboard';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LayersIcon from '@mui/icons-material/Layers';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import BuildIcon from '@mui/icons-material/Build';
import MapIcon from '@mui/icons-material/Map';
import { BurstMode, GetApp } from '@mui/icons-material';
import { ImageSearch } from '@mui/icons-material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const iconstyle = { color: 'white', width: '100px', height: '100px' };

export const pages = [
	['Dashboard', '/dashboard', Dashboard, 'prod'],
	['Divider'],
	[
		'Create map',
		'/create-map',
		EditLocationIcon,
		'prod',
		<EditLocationIcon sx={iconstyle}></EditLocationIcon>,
	],
	[
		'Edit Style',
		'/edit-style',
		LineStyleIcon,
		'prod',
		<LineStyleIcon sx={iconstyle}></LineStyleIcon>,
	],
	[
		'Analyse Style',
		'/analyse-web-map-style',
		FindInPageIcon,
		'dev',
		<FindInPageIcon sx={iconstyle}></FindInPageIcon>,
	],
	// ['Analyse image', '/analyse-paper-map-image', ImageSearch, 'dev',<ImageSearch sx={iconstyle}></ImageSearch>],
	['Divider'],
	['Sources', '/sources', GetApp, 'dev', <GetApp sx={iconstyle}></GetApp>],
	[
		'User Styles',
		'/styles',
		BurstMode,
		'prod',
		<BurstMode sx={iconstyle}></BurstMode>,
	],
	[
		'User Layers',
		'/layers',
		LayersIcon,
		'prod',
		<LayersIcon sx={iconstyle}></LayersIcon>,
	],
	['Divider'],
	[
		'Toolbox',
		'/toolset',
		BuildIcon,
		'dev',
		<BuildIcon sx={iconstyle}></BuildIcon>,
	],
	['Divider'],
	[
		'Subscriptions',
		'/subscriptions',
		MonetizationOnIcon,
		'prod',
		<MonetizationOnIcon sx={iconstyle}></MonetizationOnIcon>,
	],
	[
		'Documentation',
		'https://docs.bettermaps.ai/',
		MenuBookIcon,
		'prod',
		<MenuBookIcon sx={iconstyle}></MenuBookIcon>,
	],
];
