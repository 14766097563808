/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLayer = /* GraphQL */ `
  query GetLayer($id: ID!) {
    getLayer(id: $id) {
      id
      ownerId
      properties
      createdAt
      updatedAt
    }
  }
`;
export const listLayers = /* GraphQL */ `
  query ListLayers(
    $filter: ModelLayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        properties
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStyle = /* GraphQL */ `
  query GetStyle($id: ID!) {
    getStyle(id: $id) {
      baseStyleURL
      description
      id
      layers
      name
      ownerId
      s3URL
      styleId
      createdAt
      updatedAt
    }
  }
`;
export const listStyles = /* GraphQL */ `
  query ListStyles(
    $filter: ModelStyleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStyles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        baseStyleURL
        description
        id
        layers
        name
        ownerId
        s3URL
        styleId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
