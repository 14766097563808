import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Amplify } from "aws-amplify";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import AppWrapper from "./AppWrapper";
import awsExports from "./aws-exports";
import { LocalVariables } from "./lib/LocalStorageVariables";
import logOut from "./lib/logout";
import { createTheme } from "@mui/material";

const latestVersion = `${process.env.REACT_APP_AWS_BRANCH}@${process.env.REACT_APP_AWS_COMMIT_ID}`;
const localVersion = window.localStorage.getItem(
  LocalVariables.BettermapsVersion
);

awsExports.oauth.redirectSignIn = `${window.location.origin}/`;
awsExports.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure({
  ...awsExports,
});

if (localVersion && localVersion !== latestVersion) {
  logOut();
} else {
  window.localStorage.setItem(
    LocalVariables.BettermapsVersion,
    `${process.env.REACT_APP_AWS_BRANCH}@${process.env.REACT_APP_AWS_COMMIT_ID}`
  );
}


if (process.env.REACT_APP_ENV !== "local") {
  Sentry.init({
    dsn: "https://ffa396cc38094b01b5d3898c72fd3d1d@o1150268.ingest.sentry.io/6222945",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `bettermaps-app@${process.env.REACT_APP_AWS_BRANCH}+${process.env.REACT_APP_AWS_COMMIT_ID}`,
  });
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#006837",
    },
    secondary: {
      main: "#d3d3d3",
    },
    text: {
      primary: "#006837",
      secondary: "#707070",
    },
  },
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Authenticator.Provider>
      <AppWrapper theme={theme} />
    </Authenticator.Provider>
  </StrictMode>,
  rootElement
);
