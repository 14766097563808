import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import * as turf from "@turf/turf";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import DeleteLayerModal from "../components/common/CreateMap/modals/DeleteLayerModal";
import TablePaginationActions from "../components/DataLayerPage/TablePaginationActions";
import {
  listUserLayers,
  getGeoJSONs,
  getLayerFeatures,
} from "../services/DatasourcesService";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Auth } from "aws-amplify";
import { v4 as randomID } from "uuid";
import MapBoxSource from "../components/common/MapBox/MapBoxSource";
import { addLayer } from "../redux/slices/layers";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { setPlanInfo } from "../redux/slices/user";
import { countInfo } from "../services/StripeService";
import formateDate from "../helpers/formatDate";

export default function DataLayersPage() {
	const dispatch = useDispatch();
	const { subscription } = useSelector((state) => state.user);
	const { ownerId } = useSelector((state) => state.user);
	const { layers: inMapLayers } = useSelector((state) => state.layers);
	const [layers, setLayers] = useState([]);
	const history = useHistory();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [nextToken, setNextToken] = useState();
	const [showDeleteLayerModal, setShowDeleteLayerModal] = useState(false);
	const [layerToDeleteId, setLayerToDeleteId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const userData = useSelector((state) => state.user);

	const columns = [
		{ id: "name", value: "Name" },
		{ id: "fileType", value: "Type" },
		{ id: "updatedAt", value: "LastUpdate" },
		{ id: "actions", value: "Actions" },
	];

	useEffect(() => {
		if (subscription) {
			if (
				subscription != "standard" &&
				subscription != "explorer" &&
				subscription != "pro"
			) {
				enqueueSnackbar(
					"To use Bettermaps you need to choose a subscription plan",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				history.push("/subscriptions");
			}
		} else {
			Auth.currentAuthenticatedUser().then((user) => {
				countInfo(user.attributes.email).then((c) => {
					if (c.plan == "standard" || c.plan == "explorer" || c.plan == "pro") {
						dispatch(setPlanInfo(c));
						// history.push("/dashboard");
					} else {
						history.push("/subscriptions");
					}
				});
			});
		}
	});
	useEffect(() => {
		if (!ownerId) {
			Auth.currentAuthenticatedUser()
				.then((u) => u.username)
				.then((ownerId) => {
					listUserLayers(ownerId).then((d) => {
						d.layers.forEach((element) => {
							if (element.updatedAt) {
								element.updatedAt = formateDate(element.updatedAt);
							}
						});
						var sortedLayers = d.layers.sort(compare);
						setLayers(sortedLayers);
						// setNextToken(d.nextToken);
						setIsLoading(false);
					});
				});
		} else {
			listUserLayers(ownerId).then((d) => {
				d.layers.forEach((element) => {
					if (element.updatedAt) {
						element.updatedAt = formateDate(element.updatedAt);
					}
				});
				var sortedLayers = d.layers.sort(compare);
				setLayers(sortedLayers);
				// setNextToken(d.nextToken);
				setIsLoading(false);
			});
		}
	}, []);
	function compare(a, b) {
		if (a.updatedAt > b.updatedAt) {
			return -1;
		}
		if (a.updatedAt < b.updatedAt) {
			return 1;
		}
		return 0;
	}
	function hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
	// const handleChangePage = (event, newPage) => {
	//   setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	//   setRowsPerPage(+event.target.value);
	//   listUserLayers(event.target.value).then((d) => {
	//     setLayers(d.layers);
	//     setNextToken(d.nextToken);
	//   });
	//   setPage(0);
	// };

	const handleDeleteLayer = (layerId) => {
		setLayerToDeleteId(layerId);
		setShowDeleteLayerModal(true);
		analytics.identify(`Your Layers - delete layer: ${layerId}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const removeLayerFromTable = () => {
		setIsLoading2(true);
		listUserLayers(ownerId).then((d) => {
			var sortedLayers = d.layers.sort(compare);

			setLayers(sortedLayers);

			setNextToken(d.nextToken);
			setIsLoading2(false);
		});
	};
	const layerNotComplete = () => {
		setIsLoading2(false);
		enqueueSnackbar(
			"This layer is broken, Bettermaps team has been notified to solve this issue",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	};
	const handleAddLayer = (id) => {
		setIsLoading2(true);
		const objIndex = layers.findIndex((obj) => obj.id == id);

		const copyLayers = [...layers];

		// copyLayers[objIndex]
		var nameAlreadyExists = false;

		const chosenLayer = JSON.parse(copyLayers[objIndex].properties);
		inMapLayers.map((i) => {
			if (i.id == chosenLayer.id) {
				nameAlreadyExists = true;
			}
		});
		// if (!chosenLayer.urlSource) {
		if (chosenLayer.type.value == "raster") {
			var newLayer = {};
			newLayer.id = `bettermaps-layer-${randomID()}`;
			if (nameAlreadyExists) {
				newLayer.name = chosenLayer.name + " (copy)";
			} else {
				newLayer.name = chosenLayer.name;
			}
			newLayer.fileType = chosenLayer.fileType;
			newLayer.type = chosenLayer.type;
			newLayer.show = chosenLayer.show;
			newLayer.opacity = chosenLayer.opacity;
			newLayer.source = {};
			newLayer.source.id = randomID();
			newLayer.boundingbox = chosenLayer.boundingbox;
			newLayer.source.type = chosenLayer.source.type;
			newLayer.source.tiles = chosenLayer.source.tiles;
			newLayer.source.tileSize = chosenLayer.source.tileSize;
			if (
				newLayer.id &&
				newLayer.name &&
				newLayer.fileType &&
				newLayer.type &&
				newLayer.show &&
				newLayer.opacity &&
				newLayer.boundingbox &&
				newLayer.source.id &&
				newLayer.source.type &&
				newLayer.source.tiles &&
				newLayer.source.tileSize
			) {
				dispatch(addLayer(newLayer));
				changePage();
			} else {
				layerNotComplete();
			}
		} else if (chosenLayer.type.value == "invert") {
			var newLayer = {};
			newLayer.id = `bettermaps-layer-${randomID()}`;
			newLayer.name = chosenLayer.name;
			newLayer.fileType = chosenLayer.fileType;
			newLayer.type = chosenLayer.type;
			newLayer.show = chosenLayer.show;
			if (chosenLayer.fillColor.r) {
				newLayer.fillColor = chosenLayer.fillColor;
			} else {
				newLayer.fillColor = hexToRgb(chosenLayer.fillColor);
			}
			if (chosenLayer.fillColor.r) {
				newLayer.lineColor = chosenLayer.lineColor;
			} else {
				newLayer.line = hexToRgb(chosenLayer.lineColor);
			}
			newLayer.boundingbox = chosenLayer.boundingbox;
			newLayer.urlSource = chosenLayer.urlSource;
			// newLayer.opacity = chosenLayer.opacity;
			newLayer.source = {};
			newLayer.source.id = randomID();
			newLayer.source.type = chosenLayer.source.type;
			newLayer.source.data = chosenLayer.urlSource;

			dispatch(addLayer(newLayer));
			changePage();
		} else {
			const filters = { name: chosenLayer.name, location: "", geometry: "" };
			getGeoJSONs([], false, filters).then((data) => {
				if (data.length == 0) {
					layerNotComplete();
				} else {
					const polygon = turf.polygon(
						JSON.parse(data[0].geometry).coordinates
					);
					const bb = turf.bbox(polygon);
					getLayerFeatures(data[0].access_url).then((f) => {
						if (chosenLayer.selectColor) {
							var selectColor = chosenLayer.selectColor;
						} else {
							selectColor = "random";
						}
						if (chosenLayer.fillColor) {
							if (chosenLayer.fillColor.r) {
								var fillColor = chosenLayer.fillColor;
							} else {
								fillColor = hexToRgb(chosenLayer.fillColor);
							}
						} else {
							var fillColor = "rgba(205,0,0,1)";
						}
						var lineColor;

						var color;
						if (chosenLayer.type.value == "line") {
							color = lineColor;
							if (chosenLayer.lineColor) {
								if (chosenLayer.lineColor.r) {
									lineColor = chosenLayer.lineColor;
								} else {
									lineColor = hexToRgb(chosenLayer.lineColor);
								}
							} else {
								lineColor = "rgba(205,0,0,1)";
							}
						} else {
							color = fillColor;
							if (chosenLayer.lineColor) {
								if (chosenLayer.lineColor.r) {
									lineColor = chosenLayer.lineColor;
								} else {
									lineColor = hexToRgb(chosenLayer.lineColor);
								}
							} else {
								lineColor = { r: 255, g: 255, b: 255, a: 1 };
							}
						}

						MapBoxSource(f, selectColor, color).then((s) => {
							var newLayer = {};
							newLayer.id = `bettermaps-layer-${randomID()}`;
							if (nameAlreadyExists) {
								newLayer.name = chosenLayer.name + " (copy)";
							} else {
								newLayer.name = chosenLayer.name;
							}
							newLayer.fileType = chosenLayer.fileType;
							newLayer.type = chosenLayer.type;
							newLayer.show = chosenLayer.show;
							newLayer.fillColor = fillColor;
							newLayer.lineColor = lineColor;
							if (chosenLayer.selectColor) {
								newLayer.selectColor = chosenLayer.selectColor;
							} else {
								newLayer.selectColor = "random";
							}
							newLayer.featureColors = s.featureColors;
							if (chosenLayer.boundingbox) {
								newLayer.boundingbox = chosenLayer.boundingbox;
							} else {
								newLayer.boundingbox = bb;
							}
							newLayer.urlSource = data[0].access_url;
							newLayer.opacity = chosenLayer.opacity;
							newLayer.source = {};
							newLayer.source.id = randomID();
							newLayer.source.type = chosenLayer.source.type;
							newLayer.source.data = s.data;
							newLayer.showOnHover = false;
							newLayer.hoverProperties = [];

							dispatch(addLayer(newLayer));
							changePage();
						});
					});
				}
			});
		}
		analytics.identify(`Your Layers - Add Layer: ${id} `, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	// const getFollowingEntries = () => {
	//   listUserLayers(rowsPerPage, nextToken).then((d) => {
	//     setLayers([...layers, ...d.layers]);
	//     setNextToken(d.nextToken);
	//   });
	// };
	const changePage = () => {
		history.push("/create-map");
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.secondary.main,
		},
	}));

	return (
		<Container sx={{ paddingTop: "32px", width: "90%" }}>
			<Typography variant="h4" sx={{ mt: 1, mb: 5 }}>
				Your layers
			</Typography>

			<TableContainer
				sx={{
					minHeight: 120,
					maxHeight: "75vh",
					borderStyle: "solid",
					borderCollapse: "primary.main",
					borderRadius: "6px",
				}}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										paddingLeft: column.id === "name" ? "50px" : "",
										paddingLeft: column.id === "fileType" ? "20px" : "",

										bgcolor: "primary.main",
										color: "white",
										pt: "8px",
										pb: "8px",
									}}
									key={column.id}
									align="left"
									style={{
										width: column.id == "actions" ? "140px" : "500px",
									}}>
									{column.value}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{layers.map((layer) => {
							return (
								<StyledTableRow role="checkbox" tabIndex={-1} key={layer.id}>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align="left"
											style={{
												width: column.id == "actions" ? "140px" : "500px",
												paddingLeft: column.id === "updatedAt" ? "26px" : "",
											}}>
											{column.id == "actions" ? (
												<Box sx={{ display: "flex" }}>
													<Tooltip title="Add to Map">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0 }}
															onClick={() => handleAddLayer(layer.id)}>
															<AddIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title="Delete layer">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0 }}
															onClick={() => handleDeleteLayer(layer.id)}>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</Box>
											) : (
												<Box>
													{column.id == "updatedAt" ? (
														<Box>{layer[column.id]}</Box>
													) : (
														<Box>{JSON.parse(layer.properties)[column.id]}</Box>
													)}
												</Box>
											)}
										</TableCell>
									))}
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>

			{isLoading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						pl: "20px",
						mt: "5rem",
						// mr: 10,
					}}>
					<CircularProgress></CircularProgress>
				</Box>
			)}
			<Box>
				{!isLoading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							width: "100%",
							pr: "20px",
							mt: 1,
							// mr: 10,
						}}>
						<Typography>Number of Layers: {layers.length}</Typography>
					</Box>
				)}
				{isLoading2 && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							pl: "20px",
							mt: "5rem",
							// mr: 10,
						}}>
						<CircularProgress></CircularProgress>
					</Box>
				)}
			</Box>
			{/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => (
          <TablePaginationActions
            entries={layers}
            nextToken={nextToken}
            getFollowingEntries={getFollowingEntries}
            {...props}
          />
        )}
        showFirstButton={false}
        showLastButton={false}
      /> */}
			<DeleteLayerModal
				show={showDeleteLayerModal}
				setShow={setShowDeleteLayerModal}
				layerToDeleteId={layerToDeleteId}
				removeLayerFromTable={removeLayerFromTable}></DeleteLayerModal>
		</Container>
	);
}
