import { API, Auth, graphqlOperation } from "aws-amplify";
import S3 from "aws-sdk/clients/s3";
import post from "../clients/HttpClient";
import {
  createStyle,
  deleteStyle,
  updateStyle,
  createLayer,
  updateLayer,
  deleteLayer,
} from "../graphql/mutations";
import { getLayer, getStyle, listLayers, listStyles } from "../graphql/queries";
import { useSelector } from "react-redux";
import { fileToBase64, htmlToBase64 } from "../lib/fileConversions";

let s3 = new S3({
  apiVersion: "2006-03-01",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  params: { Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME },
});

export async function getGeoJSONs(groups, showHistorical, filters) {
	var activegroups;
	if (groups) {
		activegroups = Object.keys(groups).filter((group) => groups[group]);
		activegroups.map((active, key) => {
			activegroups[key] = activegroups[key].replace(/\s+/g, "");
		});
	}
	var showHistoryLayer;
	if (showHistorical == true) {
		showHistoryLayer = "true";
	} else {
		showHistoryLayer = "false";
	}

	var data = {};
	if (activegroups) {
		data = {
			filteredSearch: {
				name: filters.name,
				location: filters.location,
				geometry: filters.geometry,
			},
			tags: activegroups,
			show: showHistoryLayer,
		};
	} else {
		data = {
			filteredSearch: {
				name: filters.name,
				location: filters.location,
				geometry: filters.geometry,
			},
			tags: [],
			show: showHistoryLayer,
		};
	}

	// Authentification
	const idToken = (await Auth.currentAuthenticatedUser())["signInUserSession"][
		"idToken"
	]["jwtToken"];
	//('idToken', idToken)
	let headers = {
		Authorization: idToken,
		"Content-Type": "application/json;",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Headers": "*",
	};

	//('headers',headers)
	// let response = await fetch(
	//   `${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}/search`,
	//   {
	//     method: "POST",
	//     headers: headers,
	//     body: JSON.stringify(data),
	//   }
	// );
	// if (response.status == 200) {
	//   response = await response.json();
	// } else {
	//   ("error");
	//   response = [];
	// }
	// Refactored Code

	try {
		const response = await fetch(
			`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}/search`,
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify(data),
			}
		);

		if (response.status === 200) {
			const data = await response.json();
			return data;
		} else {
			throw new Error("Failed to search data sources");
		}
	} catch (error) {
		console.error("Error:", error);
	}

	return response;
}

export async function getWMSs(showHistorical) {
	let query = `SELECT name_english_ as name_, access_url, location_, ST_AsGeoJSON(geom) as geometry
  FROM datasources
  WHERE datasources.access_type_of_file = 'WMS'
  AND datasources.temporality_ = '${showHistorical}'`;

	const response = await post(
		`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}`,
		JSON.stringify({
			sql: query,
		})
	);

	if (response.data.statusCode === 200) {
		return JSON.parse(response.data.body);
	} else {
		console.error(response.data.body);
		return [{ name_: "No WMSs Found", access_url: "" }];
	}
}

export async function getAllTags() {
	const response = await post(
		`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}`,
		JSON.stringify({
			sql: `SELECT DISTINCT group_
          FROM datasources
          WHERE datasources.access_type_of_file = 'GeoJSON'`,
		})
	);

	if (response.data.statusCode === 200) {
		const tags = {};
		JSON.parse(response.data.body).forEach((t) => (tags[t.group_] = false));
		return tags;
	} else {
		console.error(response.data.body);
		return {};
	}
}

export async function createUserStyle(
	style,
	styleName,
	layers,
	baseStyleURL,
	ownerId,
	camera
) {
	return API.graphql(
		graphqlOperation(createStyle, {
			input: {
				name: styleName,
			},
		})
	)
		.then((d) =>
			s3
				.upload({
					Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME,
					Key: ownerId + "/styles/" + d.data.createStyle.id + ".json",
					Body: JSON.stringify(style),
				})
				.promise()
		)
		.then((d) => {
			const styleId = d.key.split("/")[2].split(".")[0];
			return API.graphql({
				query: updateStyle,
				variables: {
					input: {
						id: styleId,
						name: styleName,
						ownerId: ownerId,
						s3URL: d.Location,
						layers: JSON.stringify({
							layers: layers,
							camera: camera,
						}),
						baseStyleURL: baseStyleURL,
					},
				},
			});
		})
		.then((d) => d.data.updateStyle.id);
}

export async function updateUserStyle(
	styleId,
	style,
	styleName,
	layers,
	baseStyleURL,
	ownerId,
	camera,
	modifyWMSLayers
) {
	return s3
		.upload({
			Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME,
			Key: ownerId + "/styles/" + styleId + ".json",
			Body: JSON.stringify(style),
		})
		.promise()
		.then((d) =>
			API.graphql({
				query: updateStyle,
				variables: {
					input: {
						id: styleId,
						name: styleName,
						layers: JSON.stringify({
							layers: layers,
							camera: camera,
						}),
						baseStyleURL: baseStyleURL,
					},
				},
			})
		)
		.then((d) => d.data.updateStyle.id);
	// return API.graphql({
	//   query: updateStyle,
	//   variables: {
	//     input: {
	//       id: styleId,
	//       name: styleName,
	//       layers: JSON.stringify({ layers: layers }),
	//       baseStyleURL: baseStyleURL,
	//     },
	//   },
	// }).then((d) => {
	//   if (d.data.updateStyle) {
	//     return d.data.updateStyle.id;
	//   } else {
	//     return "error";
	//   }
	// });
}

export async function getUserStyle(styleId) {
	return API.graphql({ query: getStyle, variables: { id: styleId } }).then(
		(d) => {
			if (d.data.getStyle.id) {
				return {
					name: d.data.getStyle.name,
					baseStyleURL: d.data.getStyle.baseStyleURL,
					layers: JSON.parse(d.data.getStyle.layers).layers,
					id: d.data.getStyle.id,
				};
			} else {
				return "error";
			}
		}
	);
}

export async function listUserStyles(ownerId) {
	const newStyles = [];
	const limit = 200;
	const getStyles = (limit, nextToken) =>
		API.graphql({
			query: listStyles,
			variables: {
				filter: { ownerId: { eq: ownerId } },
				limit: limit,
				nextToken: nextToken,
			},
		});

	const response = await getStyles();
	newStyles.push.apply(newStyles, response.data.listStyles.items);
	var newNextToken = response.data.listStyles.nextToken;

	while (newStyles.length < limit && newNextToken) {
		const response = await getStyles(limit, newNextToken);
		newStyles.push.apply(newStyles, response.data.listStyles.items);
		newNextToken = response.data.listStyles.nextToken;
	}
	return {
		nextToken: newNextToken,
		styles: newStyles,
	};
}

export async function deleteUserStyle(styleId) {
	return API.graphql({
		query: deleteStyle,
		variables: {
			input: { id: styleId },
		},
	});
}

export async function getUserLayer(layerId) {
	return API.graphql({ query: getLayer, variables: { id: layerId } }).then(
		(d) => d.data.getLayer
	);
}

export async function createUserLayer(layer, ownerId) {
	var modifyLayer = {};
	if (layer.type.value == "raster") {
		modifyLayer.id = layer.id;
		modifyLayer.name = layer.name;
		modifyLayer.fileType = layer.fileType;
		modifyLayer.type = layer.type;
		modifyLayer.show = layer.show;
		modifyLayer.opacity = layer.opacity;
		modifyLayer.boundingbox = layer.boundingbox;
		modifyLayer.source = {};
		modifyLayer.source.id = layer.source.id;
		modifyLayer.source.type = layer.source.type;
		modifyLayer.source.tiles = layer.source.tiles;
		modifyLayer.source.tileSize = layer.source.tileSize;
	} else {
		modifyLayer.id = layer.id;
		modifyLayer.name = layer.name;
		modifyLayer.fileType = layer.fileType;
		modifyLayer.type = layer.type;
		modifyLayer.show = layer.show;
		if (layer.type.value != "line") {
			modifyLayer.fillColor = layer.fillColor;
		}
		modifyLayer.lineColor = layer.lineColor;
		if (layer.type.value != "invert") {
			modifyLayer.selectColor = layer.selectColor;
			modifyLayer.featureColors = layer.featureColors;
		}
		modifyLayer.boundingbox = layer.boundingbox;
		modifyLayer.urlSource = layer.urlSource;
		modifyLayer.opacity = layer.opacity;
		modifyLayer.source = {};
		modifyLayer.source.id = layer.source.id;
		modifyLayer.source.type = layer.source.type;
		modifyLayer.source.data = [];
	}
	return API.graphql({
		query: createLayer,
		variables: {
			input: {
				ownerId: ownerId,
				properties: JSON.stringify(modifyLayer),
			},
		},
	})
		.then((d) => {
			const dbLayerId = d.data.createLayer.id;
			return API.graphql({
				query: updateLayer,
				variables: {
					input: {
						id: dbLayerId,
						ownerId: ownerId,
						properties: JSON.stringify({
							...modifyLayer,
							dbLayerId: dbLayerId,
						}),
					},
				},
			});
		})
		.then((d) => d.data.updateLayer.id);
}

export async function updateUserLayer(layer, ownerId) {
	var modifyLayer = {};
	if (layer.type.value == "raster") {
		modifyLayer.id = layer.id;
		modifyLayer.name = layer.name;
		modifyLayer.fileType = layer.fileType;
		modifyLayer.type = layer.type;
		modifyLayer.show = layer.show;
		modifyLayer.opacity = layer.opacity;
		modifyLayer.boundingbox = layer.boundingbox;
		modifyLayer.source = {};
		modifyLayer.source.id = layer.source.id;
		modifyLayer.source.type = layer.source.type;
		modifyLayer.source.tiles = layer.source.tiles;
		modifyLayer.source.tileSize = layer.source.tileSize;
		modifyLayer.dbLayerId = layer.dbLayerId;
	} else {
		modifyLayer.id = layer.id;
		modifyLayer.name = layer.name;
		modifyLayer.fileType = layer.fileType;
		modifyLayer.type = layer.type;
		modifyLayer.show = layer.show;
		if (layer.type.value != "line") {
			modifyLayer.fillColor = layer.fillColor;
		}
		modifyLayer.lineColor = layer.lineColor;
		if (layer.type.value != "invert") {
			modifyLayer.selectColor = layer.selectColor;
			modifyLayer.featureColors = layer.featureColors;
		}
		modifyLayer.boundingbox = layer.boundingbox;
		modifyLayer.urlSource = layer.urlSource;
		modifyLayer.opacity = layer.opacity;
		modifyLayer.source = {};
		modifyLayer.source.id = layer.source.id;
		modifyLayer.source.type = layer.source.type;
		modifyLayer.source.data = [];
		modifyLayer.dbLayerId = layer.dbLayerId;
		// modifyLayer.hoverProperties = layer.hoverProperties;
	}

	return API.graphql({
		query: updateLayer,
		variables: {
			input: {
				id: modifyLayer.dbLayerId,
				ownerId: ownerId,
				properties: JSON.stringify(modifyLayer),
			},
		},
	}).then((d) => d.data.updateLayer.id);
}

export async function listUserLayers(ownerId, nextToken) {
	const newLayers = [];
	// let newNextToken = nextToken;
	var limit = 200;

	const getLayers = (limit, nextToken) =>
		API.graphql({
			query: listLayers,
			variables: {
				filter: { ownerId: { eq: ownerId } },
				limit: limit,
				nextToken: nextToken,
			},
		});

	const response = await getLayers();
	newLayers.push.apply(newLayers, response.data.listLayers.items);
	var newNextToken = response.data.listLayers.nextToken;

	while (newLayers.length < limit && newNextToken) {
		const response = await getLayers(limit, newNextToken);
		newLayers.push.apply(newLayers, response.data.listLayers.items);
		newNextToken = response.data.listLayers.nextToken;
	}
	return {
		nextToken: newNextToken,
		layers: newLayers,
	};
}

export async function deleteUserLayer(layerId) {
	return API.graphql({
		query: deleteLayer,
		variables: {
			input: { id: layerId },
		},
	});
}

// export async function getLayerFeatures(url) {
// 	let response = await fetch(`${url}`);

// 	if (response.status == 200) {
// 		response = await response.json();
// 	}
// 	return response;
// }

// Refactored Code

export async function getLayerFeatures(url) {
	try {
		const response = await fetch(url);
		if (response.status === 200) {
			const data = await response.json();
			return data;
		} else {
			throw new Error(`Response status ${response.status}`);
		}
	} catch (error) {
		console.error(error);
		throw new Error("Failed to fetch layer features");
	}
}

export async function getHostedMap(html, ownerId) {
	const base64 = btoa(html);
	let body = { file: base64 };
	// let response = await fetch(
	//   `${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/host_static_webmap`,
	//   {
	//     method: "POST",
	//     body: JSON.stringify(body),
	//   }
	// );
	// Refactored Code

	// Refactored Code

	try {
		const response = await fetch(
			`${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/host_static_webmap`,
			{
				method: "POST",
				body: JSON.stringify(body),
			}
		);
		// handle response as needed
	} catch (error) {
		console.error("Error:", error);
	}

	let data = await response.json();
	return data;
}
