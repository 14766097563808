import { FormControl, InputLabel, Select } from "@mui/material";
import { styled } from "@mui/system";

const defaultMenuProps = {
  PaperProps: {
    style: {
      maxHeight: 210,
      width: 250,
    },
  },
  sx: {
    "&& .Mui-selected": {
      backgroundColor: "primary.main",
      color: "white",
    },
  },
};

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
  },
}));

export default function BettermapsSelect(props) {
  const toIDFormat = (label) =>
    String(label).toLowerCase().replaceAll(" ", "-");

  return (
    <FormControl fullWidth size="small" sx={props.sx}>
      <InputLabel id={`${toIDFormat(props.label)}-select-label`}>
        {props.label}
      </InputLabel>
      <StyledSelect
        displayEmpty
        labelId={`${toIDFormat(props.label)}-select-label`}
        id={`${toIDFormat(props.label)}-select`}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        MenuProps={props.menuProps || defaultMenuProps}
        renderValue={props.renderValue}
        disabled={props.disabled}
      >
        {props.children}
      </StyledSelect>
    </FormControl>
  );
}
