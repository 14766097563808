import { Box, Stack } from "@mui/material";
import React from "react";
import {
  AttributionControl,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import CurrentZoomLevel from "../common/MapBox/CurrentZoomLevel";
import MapboxPaperMap from "../common/MapboxPaperMap";
import { PaperOrientation, PaperSize } from "../../lib/PaperSettings";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import BettermapsOverlay from "../common/MapBox/BettermapsOverlay";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMap } from "react-map-gl";

export default function PaperMapPreview(props) {
  const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [isMoving, setIsMoving] = useState(true);

  const myPaperMap = useMap()[props.mapId];
  useEffect(() => {
    const xheight = 532;
    const xwidth =
      props.paperSettings.paperOrientation === PaperOrientation.Landscape
        ? xheight * 1.41
        : xheight / 1.41;

    setHeight(xheight);
    setWidth(xwidth);
  }, [props.paperSettings.paperOrientation]);

  const getPaperSizeDimensions = () => {
    const width = PaperSize[props.paperSettings.paperSize].dimensions[0] / 10;
    const height = PaperSize[props.paperSettings.paperSize].dimensions[1] / 10;
    return `(${width} x ${height} cm)`;
  };

  useEffect(() => {
    if (myPaperMap) {
      if (myPaperMap.isSourceLoaded) {
        setIsMoving(false);
      }
    }
  }, [myPaperMap]);

  useEffect(() => {
    if (!isMoving) {
      if (myPaperMap) {
        if (!myPaperMap.isMoving()) {
          setTimeout(() => {
            myPaperMap.resize();
          }, 1000);
        }
      }
    }
  }, [props.paperSettings]);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          borderStyle: "solid",
          borderWidth: "2px",
          borderRadius: "2px",
          height: height,
          width: width,
        }}
      >
        <MapboxPaperMap
          id={props.mapId}
          myMap={myPaperMap}
          layers={props.layers}
          height={height}
          width={width}
          mapStyle={props.mapStyle}
          paperSettings={props.paperSettings}
          content={
            <React.Fragment>
              <CurrentZoomLevel
                mapId={props.mapId}
                top={10}
                right={50}
                decimals={2}
              ></CurrentZoomLevel>
              <BettermapsOverlay
                top={`calc(100vh - calc(100vh - ${height}px) - 35px)`}
                right={`calc(100vw - calc(100vw - ${width}px) - 165px)`}
              ></BettermapsOverlay>
              {props.scale && props.scale.show && (
                <ScaleControl
                  key={props.scaleKey}
                  unit={props.scale.unit}
                  position={props.scale.position}
                />
              )}
              {props.navigation && props.navigation.show && (
                <NavigationControl position={props.navigation.position} />
              )}
              {props.attribution && props.attribution.show && (
                <AttributionControl
                  customAttribution={props.attribution.customAttribution}
                  compact={true}
                />
              )}
            </React.Fragment>
          }
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          height: "40px",
          minWidth: "380px",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
      >
        <Box>
          {props.paperSettings.paperSize + " " + getPaperSizeDimensions()}
        </Box>
        <Box>{props.paperSettings.paperOrientation}</Box>
        <Box>DPI: {props.exportSettings.dpi}</Box>
      </Stack>
    </Box>
  );
}
