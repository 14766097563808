import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import Map, { Layer, Source, useControl } from "react-map-gl";
import toLayer, { toSource } from "../../lib/StyleBuilder";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { getMapData } from "../../redux/slices/map";
import { useHistory } from "react-router-dom";

export default function MapboxMap(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const myMap = useMap()[props.id];
  const state = useSelector((state) => state.maps);

  if (props.id.includes("zoom-view")) {
    var lat = props.latitude;
    var long = props.longitude;
    var zoom = props.zoom;
    var bearing = 0;
    var pitch = 0;
  } else {
    var lat = parseFloat(state.mapCenter.lat) || 40;
    var long = parseFloat(state.mapCenter.long) || -3;
    var zoom = parseFloat(state.mapZoom) || 2;
    var bearing = parseFloat(state.mapBearing) || 0;
    var pitch = parseFloat(state.mapPitch) || 0;
  }
  const { layers } = useSelector((state) => state.layers);

  const [viewport, setViewport] = useState({
    longitude: long,
    latitude: lat,
    zoom: zoom,
    bearing: bearing,
    pitch: pitch,
  });
  var displayLayers = [...layers];
  const [hoverInfo, setHoverInfo] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [beforeId, setBeforeId] = useState("");

  const centerViews = () => {
    if (myMap) {
      myMap.resize();
    }
  };
  useEffect(() => {
    if (props.id.includes("zoom-view")) {
      if (myMap) {
        setTimeout(() => {
          centerViews();
        }, 4000);
      }
    }
  }, [myMap]);
  useEffect(() => {
    if (props.id.includes("zoom-view")) {
      if (myMap) {
        if (myMap) {
          centerViews();
        }
      }
    }
  });

  const moveEnd = () => {
    if (!props.id.includes("zoom-view")) {
      dispatch(
        getMapData(
          myMap.getZoom(),
          myMap.getCenter(),
          myMap.getPitch(),
          myMap.getBearing()
        )
      );
    }
  };
  const handleOnHover = (event, layers) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];
    const layer = layers.find((l) => l.id == hoveredFeature.layer.id);
    const showOnHover = layer.showOnHover;

    if (showOnHover) {
      const properties = {};
      layer.hoverProperties.forEach(
        (p) => (properties[p] = hoveredFeature.properties[p])
      );

      setHoverInfo(properties && { properties, x, y });
      setIsHovering(true);
    }
  };

  const handleOnClick = (event, layers) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];
    const layer = layers.find((l) => l.id == hoveredFeature.layer.id);
    if (layer.type.value != "invert") {
      props.openEditFeature(layer, hoveredFeature);
    }
  };

  return (
    <Map
      preserveDrawingBuffer
      id={props.id}
      initialViewState={{ viewport }}
      {...viewport}
      boxZoom={!props.static}
      doubleClickZoom={!props.static}
      dragRotate={!props.static}
      dragPan={!props.static}
      keyboard={!props.static}
      scrollZoom={!props.static}
      touchPitch={!props.static}
      touchZoomRotate={!props.static}
      style={{
        height: props.id.includes("zoom-view") ? props.maxHeight : props.height,
        width: props.width,
        minWidth: props.width,
        minHeight: props.height,
        // maxWidth: props.maxWidth,
        // maxHeight: props.maxHeight,
      }}
      mapStyle={
        props.mapStyle ||
        process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
      }
      onMove={(evt) => setViewport(evt.viewState)}
      onMouseMove={(event) => handleOnHover(event, props.layers)}
      onMouseLeave={() => setIsHovering(false)}
      onMoveEnd={moveEnd}
      onClick={(event) => handleOnClick(event, props.layers)}
      attributionControl={false}
      interactiveLayerIds={props.layers.map((l) => l.id)}
    >
      {displayLayers &&
        displayLayers
          // .sort((a, b) => b.position < a.position)
          .map((l) => (
            <Source key={l.source.id} {...toSource(l.source)}>
              <Layer key={l.id} {...toLayer(l, displayLayers)}></Layer>
            </Source>
          ))}

      {props.content}
      {isHovering && hoverInfo && (
        <Box
          style={{
            position: "absolute",
            left: hoverInfo.x,
            top: hoverInfo.y,
            backgroundColor: "white",
            opacity: "0.7",
            padding: "10px",
            color: "black",
            borderRadius: "5px",
          }}
        >
          {Object.keys(hoverInfo.properties).map((p, index) => (
            <p key={index}>
              {p}: {hoverInfo.properties[p]}
            </p>
          ))}
        </Box>
      )}

      {/* <DrawControl onDrawCreate={onDrawCreate} onDrawUpdate={onDrawUpdate} /> */}
      {/* <DrawControl
        position="top-right"
        displayControlsDefault={true}
        controls={{
          polygon: true,
          lineString: false,
          trash: true,
        }}
      /> */}
    </Map>
  );
}
