import {
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
  Slider,
} from "@mui/material";
import { useState, useEffect } from "react";
import { decimalAlphaToHex } from "../../../../../lib/color";
import { useColor } from "../../../../../lib/hooks";
import { LayerType } from "../../../../../lib/LayerType";
import BettermapsModal from "../../../BettermapsModal";
import LayerDetails from "../../LayerDetails";
import BettermapsSelect from "../../../BettermapsSelect";
import { useDispatch, useSelector } from "react-redux";
import { modifyOneLayer } from "../../../../../redux/slices/layers";
import { editDataLayer } from "../../../MapBox/MapBoxSource";
import { RandomColorPalette } from "../../../ColorPalette";
import { getThemeProps } from "@mui/system";
import BettermapsTextField from "../../../BettermapsTextField";
import BettermapsColorPicker from "../../../BettermapsColorPicker";
import { AlphaPicker } from "react-color";
import { editReverseLayer } from "../../../../../redux/slices/layers";

export default function EditReverseLayerModal(props) {
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);
  var temporalLayers = layers;
  const [lineColor, setLineColor] = useColor(props.layer.lineColor);
  const [fillColor, setFillColor] = useColor(props.layer.fillColor);
  const [layerName, setLayerName] = useState(props.layer.name);
  const [color, setColor] = useState(props.layer.opacity * 100);

  function modifyReverseLayer() {
    const objIndex = temporalLayers.findIndex(
      (obj) => obj.id == props.layer.id
    );
    var opacity = 1;
    var fill = `rgba(${fillColor.rgb.r},${fillColor.rgb.g},${fillColor.rgb.b},${fillColor.rgb.a})`;
    var outline = `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`;
    dispatch(editReverseLayer([objIndex, layerName, fill, outline, opacity]));
    props.setShow(false);
  }
  const handleChange = (event, newValue) => {
    setColor(newValue);
  };

  return (
    <BettermapsModal
      name="aqui"
      sx={{ width: "600px" }}
      title="Edit Invert Layer"
      showModal={props.show}
      setShowModal={props.setShow}
      onDone={modifyReverseLayer}
      doneText="Save"
    >
      <BettermapsTextField
        label="Layer name"
        value={layerName}
        onChange={(e) => setLayerName(e.target.value)}
      ></BettermapsTextField>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            wrap: "noWrap",
            // marginRight: "40px",
            // marginTop: "10px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <Typography>Outline color:</Typography>
            <Box style={{ marginLeft: "10px" }}>
              <BettermapsColorPicker
                color={lineColor}
                setColor={setLineColor}
              />
            </Box>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <Typography>Fill color:</Typography>

            <Box style={{ marginLeft: "10px" }}>
              <BettermapsColorPicker
                color={fillColor}
                setColor={setFillColor}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </BettermapsModal>
  );
}
