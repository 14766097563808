function StripePricePage() {
  // pk_test_51LY4hbJc2jwMiH4cDJxdPsd0FLz7BEcAHWvw3KqpeoIm6tTmPSvC7QsQMAgDVV7B3RhWh8eeEnrfBKKFRttXOlp300UA1vrahV
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
      publishable-key={process.env.REACT_APP_STRIPE_TABLE}
    ></stripe-pricing-table>
  );
}

export default StripePricePage;
