export const Format = {
  JPEG: "jpg",
  PNG: "png",
  PDF: "pdf",
  SVG: "svg",
};
export const DPI = {
  72: 72,
  96: 96,
  200: 200,
  300: 300,
  400: 400,
};
