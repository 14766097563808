import { fabric } from "fabric";
import { jsPDF } from "jspdf";
import { Format } from "./ExportSettings";
import { PaperOrientation, PaperSize } from "./PaperSettings";
import buildStandAloneWebsite from "./templatesBuilder/buildStandAloneWebsite";
import BettermapsWhite from "../images/BM_H_White.svg";
import { getHostedMap } from "../services/DatasourcesService";

function toPNG(canvas, fileName) {
  const uri = canvas.toDataURL("image/png");
  if (canvas.msToBlob) {
    // for IE11
    const blob = toBlob(uri);
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    // for other browsers except IE11
    const a = document.createElement("a");
    a.href = uri;
    a.download = fileName;
    a.click();
    a.remove();
  }
}

function toJPEG(canvas, fileName) {
  const uri = canvas.toDataURL("image/jpeg", 1);
  if (canvas.msToBlob) {
    // for IE11
    const blob = toBlob(uri);
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    // for other browsers except IE11
    const a = document.createElement("a");
    a.href = uri;
    a.download = fileName;
    a.click();
    a.remove();
  }
}

function toPDF(map, canvas, fileName, paperSettings) {
  const width = getWidth(paperSettings);
  const height = getHeight(paperSettings);
  const pdf = new jsPDF({
    orientation: paperSettings.paperOrientation,
    unit: "mm",
    compress: true,
    format: [width, height],
  });

  pdf.addImage(
    canvas.toDataURL("image/png", 1),
    "png",
    0,
    0,
    width,
    height,
    undefined,
    "FAST"
  );

  const { lng, lat } = map.getCenter();
  pdf.setProperties({
    title: map.getStyle().name,
    subject: `center: [${lng}, ${lat}], zoom: ${map.getZoom()}`,
    creator: "Mapbox GL Export Plugin",
    author: "(c)Bettermaps (c)MapLibre, (c)OpenStreetMap",
  });

  pdf.save(fileName);
}

function toSVG(canvas, fileName, paperSettings, exportSettings) {
  const uri = canvas.toDataURL("image/png", 1);

  fabric.Image.fromURL(uri, (image) => {
    const tmpCanvas = new fabric.Canvas("canvas");
    const pxWidth = toPixels(getWidth(paperSettings), exportSettings.dpi);
    const pxHeight = toPixels(getHeight(paperSettings), exportSettings.dpi);
    image.scaleToWidth(pxWidth);
    image.scaleToHeight(pxHeight);

    tmpCanvas.add(image);
    const svg = tmpCanvas.toSVG({
      x: 0,
      y: 0,
      width: pxWidth,
      height: pxHeight,
      viewBox: {
        x: 0,
        y: 0,
        width: pxWidth,
        height: pxHeight,
      },
    });
    const a = document.createElement("a");
    a.href = `data:application/xml,${encodeURIComponent(svg)}`;
    a.download = fileName;
    a.click();
    a.remove();
  });
}

function toPixels(length, dpi = 96) {
  return (dpi * length) / 25.4;
}

function toBlob(base64) {
  const bin = Buffer.from(base64.replace(/^.*,/, ""), "base64");
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], { type: "image/png" });
  return blob;
}

function getWidth(paperSettings) {
  return paperSettings.paperOrientation === PaperOrientation.Landscape
    ? PaperSize[paperSettings.paperSize].dimensions[0]
    : PaperSize[paperSettings.paperSize].dimensions[1] * 2;
}

function getHeight(paperSettings) {
  return paperSettings.paperOrientation === PaperOrientation.Landscape
    ? PaperSize[paperSettings.paperSize].dimensions[1]
    : PaperSize[paperSettings.paperSize].dimensions[0] * 2;
}

async function cloneCanvas(oldCanvas, width, height, position, dpi) {
  var positionWidth = 0;
  var positionHeight = 0;
  var logoWidth = 500;
  var logoHeight = 100;

  if (dpi == "72") {
    logoWidth = 150;
    logoHeight = logoWidth / 5;
  }
  if (dpi == "96") {
    logoWidth = 200;
    logoHeight = logoWidth / 5;
  }
  if (dpi == "200") {
    logoWidth = 400;
    logoHeight = logoWidth / 5;
  }
  if (dpi == "300") {
    logoWidth = 500;
    logoHeight = 100;
  }
  if (dpi == "400") {
    logoWidth = 700;
    logoHeight = logoWidth / 5;
  }

  if (position == "bottomleft") {
    positionWidth = 0;
    positionHeight = height - logoHeight;
  }
  if (position == "bottomright") {
    positionWidth = width - logoWidth;
    positionHeight = height - logoHeight;
  }
  if (position == "topleft") {
    positionWidth = 0;
    positionHeight = 0;
  }
  if (position == "topright") {
    positionWidth = width - logoWidth;
    positionHeight = 0;
  }
  var clonedCanvas = document.createElement("canvas");
  var context = clonedCanvas.getContext("2d");

  clonedCanvas.width = width;
  clonedCanvas.height = height;

  context.drawImage(oldCanvas, 0, 0, width, height);
  const result = await fetch(
    "https://imgjas.s3.eu-central-1.amazonaws.com/bettermapGreen%403x.png"
  );
  const blob = await result.blob();
  const image = await createImageBitmap(blob);
  context.drawImage(
    image,
    positionWidth,
    positionHeight,
    logoWidth,
    logoHeight
  );

  return clonedCanvas;
}
export async function exportMap(
  fileFormat,
  paperSettings,
  exportSettings,
  map,
  position
) {
  cloneCanvas(
    map.getCanvas(),
    toPixels(getWidth(paperSettings), exportSettings.dpi),
    toPixels(getHeight(paperSettings), exportSettings.dpi),
    position,
    exportSettings.dpi
  ).then((canvas) => {
    switch (fileFormat) {
      case Format.PNG:
        toPNG(canvas, `map.${fileFormat}`);
        break;
      case Format.JPEG:
        toJPEG(canvas, `map.${fileFormat}`);
        break;
      case Format.PDF:
        toPDF(map, canvas, `map.${fileFormat}`, paperSettings);
        break;
      case Format.SVG:
        toSVG(canvas, `map.${fileFormat}`, paperSettings, exportSettings);
        break;
      default:
        console.error(`Invalid file format: ${fileFormat}`);
    }
  });
}

export async function exportStyle(style, styleName) {
  const uri =
    "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(style));
  const a = document.createElement("a");
  a.href = uri;
  a.download = `${styleName}.json`;
  a.click();
  a.remove();
}

export async function exportStandAloneWebsite(style, zoom, center, APIKeys) {
  const updatedStyle = replaceTokens(style, APIKeys);
  const html = buildStandAloneWebsite(updatedStyle, zoom, center);
  const uri = "data:text/json;charset=utf-8," + encodeURIComponent(html);
  const a = document.createElement("a");
  a.href = uri;
  a.download = `map.html`;
  a.click();
  a.remove();
}

export async function exportHostedWebsite(
  style,
  zoom,
  center,
  APIKeys,
  ownerId
) {
  const updatedStyle = replaceTokens(style, APIKeys);
  const html = buildStandAloneWebsite(updatedStyle, zoom, center);
  return getHostedMap(html, ownerId);

  // const uri = "data:text/json;charset=utf-8," + encodeURIComponent(html);
  // const a = document.createElement("a");
  // a.href = uri;
  // a.download = `map.html`;
  // a.click();
  // a.remove();
}

function replaceTokens(style, APIKeys) {
  return JSON.stringify(style).replaceAll(
    /key=\w*/g,
    `key=${APIKeys.maptilerAPIKey}`
  );
}
