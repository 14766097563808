import {
  Grid,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Stack,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  FormLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import { decimalAlphaToHex } from "../../../../../lib/color";
import { useColor } from "../../../../../lib/hooks";
import { LayerType } from "../../../../../lib/LayerType";
import BettermapsModal from "../../../BettermapsModal";
import LayerDetails from "../../LayerDetails";
import BettermapsSelect from "../../../BettermapsSelect";
import BettermapsTextField from "../../../BettermapsTextField";
import { useDispatch, useSelector } from "react-redux";
import { modifyOneLayer } from "../../../../../redux/slices/layers";
import { editDataLayer } from "../../../MapBox/MapBoxSource";
import { RandomColorPalette } from "../../../ColorPalette";
import { getThemeProps } from "@mui/system";
import BettermapsColorPicker from "../../../BettermapsColorPicker";

export default function EditGeoJSONModal(props) {
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);
  var temporalLayers = layers;
  const getLineColor = () =>
    props.layer.type.value === LayerType.Line.value
      ? `${props.layer.lineColor}${decimalAlphaToHex(props.layer.opacity)}`
      : props.layer.lineColor;

  const getFillColor = () =>
    props.layer.type.value !== LayerType.Line.value
      ? `${props.layer.fillColor}${decimalAlphaToHex(props.layer.opacity)}`
      : props.layer.fillColor;

  const [lineColor, setLineColor] = useColor(getLineColor());
  const [fillColor, setFillColor] = useColor(getFillColor());
  const [layerName, setLayerName] = useState(props.layer.name);
  const [showOnHover, setShowOnHover] = useState(props.layer.showOnHover);
  const [selectedHoverProperties, setSelectedHoverProperties] = useState(
    props.layer.hoverProperties
  );
  const [availableHoverProperties, setAvailableHoverProperties] = useState([]);
  const [selectColor, setSelectColor] = useState(props.layer.selectColor);

  const handleChange = (event) => {
    setSelectColor(event.target.value);
  };
  useEffect(() => {}, [fillColor]);
  useEffect(() => {
    if (props.layer.source.data.features) {
      setAvailableHoverProperties(
        Object.keys(props.layer.source.data.features[0].properties)
      );
    }
  }, []);

  function editGeoJSONLayer() {
    const objIndex = temporalLayers.findIndex(
      (obj) => obj.id == props.layer.id
    );
    var typeColor = `rgba(${fillColor.rgb.r},${fillColor.rgb.g},${fillColor.rgb.b},${fillColor.rgb.a})`;

    if (props.layer.type.value == LayerType.Line.value) {
      typeColor = `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`;
    }
    var newLayerData = temporalLayers[objIndex].source;
    var newLayerSource = {};
    newLayerSource.id = newLayerData.id;
    newLayerSource.type = newLayerData.type;
    newLayerSource.data = {};
    newLayerSource.data.type = newLayerData.data.type;
    newLayerSource.data.features = [];
    var featureColors = [];
    newLayerData.data.features.map((datalayer) => {
      var newData = {};
      newData.geometry = datalayer.geometry;
      newData.type = datalayer.type;
      newData.properties = { ...datalayer.properties };

      var color = selectColor == "random" ? RandomColorPalette() : typeColor;
      newData.properties.color = color;
      featureColors.push(color);
      newLayerSource.data.features.push(newData);
    });

    dispatch(
      modifyOneLayer(
        objIndex,
        showOnHover,
        selectedHoverProperties,
        selectColor,
        fillColor.rgb,
        lineColor.rgb,
        newLayerSource,
        featureColors
        // opacity
      )
    );
    props.setShow(false);
  }

  return (
    <BettermapsModal
      sx={{ width: "500px" }}
      title="Edit GeoJSON Layer"
      showModal={props.show}
      setShowModal={props.setShow}
      onDone={editGeoJSONLayer}
      doneText="Save"
    >
      {/* <Grid container direction="row"> */}
      {/* <LayerDetails
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          fillColor={fillColor}
          setFillColor={setFillColor}
          lineColor={lineColor}
          setLineColor={setLineColor}
          layerName={layerName}
          setLayerName={setLayerName}
          layerType={props.layer.type}
          selectColor={selectColor}
          setSelectColor={setSelectColor}
        ></LayerDetails> */}
      {/* <Grid
          item
          xl={props.xl || 5}
          lg={props.lg || 5}
          md={props.md || 5}
          sm={props.sm || 5}
          xs={props.xs || 5}
          style={{ padding: "7px 7px 7px 0px" }}
        > */}
      <Stack>
        <BettermapsTextField
          label="Layer name"
          value={layerName}
          onChange={(e) => setLayerName(e.target.value)}
        ></BettermapsTextField>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={selectColor}
              onChange={handleChange}
            >
              <FormControlLabel
                value="unique"
                control={<Radio />}
                label="Unique Color"
              />
              {selectColor == "unique" && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // marginRight: "40px",
                    // marginTop: "10px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <Typography>
                      {props.layer.type.value == LayerType.Line.value
                        ? "Line color"
                        : "Outline color"}
                    </Typography>
                    <Box style={{ marginLeft: "10px" }}>
                      <BettermapsColorPicker
                        color={lineColor}
                        setColor={setLineColor}
                        // disableAlpha={
                        //   props.layer.type.value != LayerType.Line.value
                        // }
                      />
                    </Box>
                  </Box>
                  {props.layer.type.value != LayerType.Line.value && (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: "40px",
                        marginTop: "10px",
                      }}
                    >
                      <Typography>Fill color</Typography>

                      <Box style={{ marginLeft: "10px" }}>
                        <BettermapsColorPicker
                          color={fillColor}
                          setColor={setFillColor}
                          disableAlpha={
                            props.layer.type.value == LayerType.Line.value
                          }
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              <FormControlLabel
                value="random"
                control={<Radio />}
                label="Random Color"
              />
            </RadioGroup>
          </FormControl>
          {/* <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <Typography>
              {props.layerType.value == LayerType.Line.value
                ? "Line color"
                : "Outline color"}
            </Typography>
            <Box style={{ marginLeft: "10px" }}>
              <BettermapsColorPicker
                color={props.lineColor}
                setColor={props.setLineColor}
                disableAlpha={props.layerType.value != LayerType.Line.value}
              />
            </Box>
          </Box>
          {props.layerType.value != LayerType.Line.value && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "40px",
                marginTop: "10px",
              }}
            >
              <Typography>Fill color</Typography>

              <Box style={{ marginLeft: "10px" }}>
                <BettermapsColorPicker
                  color={props.fillColor}
                  setColor={props.setFillColor}
                  disableAlpha={props.layerType.value == LayerType.Line.value}
                />
              </Box>
            </Box>
          )} */}
          {/* <ToggleButtonGroup
            disabled
            style={{
              backgroundColor: theme.palette.secondary.main,
              width: "fit-content",
              marginTop: "10px",
            }}
            value={layerVisibility}
            size="small"
            exclusive
            onChange={(e, a) => setLayerVisibility(a)}
          >
            <ToggleButton value="owned">
              <FavoriteBorderIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="public">
              <LockOpenIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="private">
              <LockOutlinedIcon sx={{ color: "white" }} />
            </ToggleButton>
          </ToggleButtonGroup> */}
        </Box>
      </Stack>
      {/* </Grid> */}

      {availableHoverProperties.length > 0 && (
        <Box display="flex" flexDirection="row" style={{ marginTop: "5px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnHover}
                onChange={() => setShowOnHover(!showOnHover)}
              />
            }
            label="Show on hover"
          />

          <BettermapsSelect
            sx={{ width: 200 }}
            label="Display features"
            multiple
            value={selectedHoverProperties}
            onChange={(e) => setSelectedHoverProperties(e.target.value)}
          >
            {availableHoverProperties.map((property, index) => (
              <MenuItem key={index} value={property}>
                {property}
              </MenuItem>
            ))}
          </BettermapsSelect>
        </Box>
      )}
      {/* </Grid> */}
    </BettermapsModal>
  );
}
