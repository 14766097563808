export const LayerType = {
  Background: {
    value: "background",
    color: "",
  },
  Invert: {
    value: "invert",
    color: "black",
  },
  Fill: {
    value: "fill",
    color: "#F5DBAE",
  },
  Line: {
    value: "line",
    color: "#D8AEF5",
  },
  Circle: {
    value: "circle",
    color: "#C6F5DA",
  },
  Raster: {
    value: "raster",
    color: "",
  },
};
