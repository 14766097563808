// imageName must be found in: REACT_APP_S3_PUBLIC_MINIATURES_PREFIX_URL
// styleName must be found in: REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL

export const mapTemplates = [
    {
      text: "Empty",
      imageName: "empty.png",
      styleName: "empty_style.json",
      source: "Maputnik",
    },
    {
      text: "Basic",
      imageName: "basic.png",
      styleName: "basic.json",
      source: "MapTiler",
    },
    {
      text: "Bright",
      imageName: "bright.png",
      styleName: "bright.json",
      source: "MapTiler",
    },
    {
      text: "Dark Matter",
      imageName: "dark_matter.png",
      styleName: "dark_matter.json",
      source: "MapTiler",
    },
    {
      text: "Positron",
      imageName: "positron.png",
      styleName: "positron.json",
      source: "MapTiler",
    },
    {
      text: "OSM Liberty",
      imageName: "osm_liberty.png",
      styleName: "osm_liberty.json",
      source: "MapTiler",
    },
    {
      text: "Toner",
      imageName: "toner.png",
      styleName: "toner.json",
      source: "MapTiler",
    },
    {
        text: "Terrain",
        imageName: "terrain_klokantech.png",
        styleName: "topo.json",
        source: "MapTiler",
      },
      {
        text: "Game of Thrones",
        imageName: "got.png",
        styleName: "got.json",
        source: "Mapbox",
      },
      {
        text: "Blueprint",
        imageName: "blueprint.png",
        styleName: "blueprint.json",
        source: "Mapbox",
      },
      {
        text: "Pencil",
        imageName: "pencil.png",
        styleName: "pencil.json",
        source: "Mapbox",
      },
      {
        text: "Satellite - Mapbox",
        imageName: "mapbox-satellite.png",
        styleName: "mapbox_satellite.json",
        source: "Mapbox",
      },
      {
        text: "OSM Aerial",
        imageName: "mapbox-satellite.png",
        styleName: "style_aerial.json",
        source: "",
        env:"dev"
      },
            {
        text: "Camouflage",
        imageName: "Camouflage.png",
        styleName: "Camouflage.json",
        source: "",
        env:""
      },      {
        text: "Emerald",
        imageName: "Emerald.png",
        styleName: "Emerald.json",
        source: "",
        env:""
      },      {
        text: "Roy",
        imageName: "Roy.png",
        styleName: "Roy.json",
        source: "",
        env:""
      },      {
        text: "Runner",
        imageName: "Runner.png",
        styleName: "Runner.json",
        source: "",
        env:""
      },      {
        text: "Swiss ski",
        imageName: "Swiss ski.png",
        styleName: "Swiss ski.json",
        source: "",
        env:""
      },      {
        text: "Vintage",
        imageName: "Vintage.png",
        styleName: "Vintage.json",
        source: "",
        env:""
      },
      {
      text: "X-Ray",
      imageName: "X-ray.png",
      styleName: "X-ray.json",
      source: "",
      env:""
    },
  ];