import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import tinycolor from "tinycolor2";

export function usePersistedState(key, defaultState) {
  const getInitialState = () => {
    return window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key)).value
      : defaultState;
  };

  const [value, setValue] = useState(getInitialState());

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify({ value: value }));
  }, [value]);

  return [value, setValue];
}

export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useColor(initialColor) {
  const initColor = (initialColor) => {
    const color = tinycolor(initialColor || theme.palette.primary.main);
    return {
      hsl: color.toHsl(),
      hex: `#${color.toHex()}`,
      rgb: color.toRgb(),
      hsv: color.toHsv(),
      // oldHue: 0,
      // source: data.source,
    };
  };

  const theme = useTheme();
  const [color, setColor] = useState(initColor(initialColor));

  return [color, setColor];
}

export const useMediaQuery = (query) => {
  if (typeof window === "undefined" || typeof window.matchMedia === "undefined")
    return;

  const mediaQuery = window.matchMedia(query);

  const [match, setMatch] = useState(mediaQuery.matches);

  useEffect(() => {
    const handler = () => setMatch(mediaQuery.matches);
    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, []);

  return match;
};
