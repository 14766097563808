import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, Typography } from '@mui/material';
import ActionButton from './ActionButton';

export default function BettermapsModal(props) {
	const userData = useSelector((state) => state.user);

	const handleCloseIcon = () => {
		props.setShowModal(false);
		analytics.identify("close draw your map from X icon", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};
return (
	<Modal
		open={props.showModal}
		onClose={() => props.setShowModal(false)}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description">
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: "80%",
				bgcolor: "lightgray",
				border: "1px solid #000",
				boxShadow: 24,
				p: 4,
				borderRadius: 2,
				...props.sx,
			}}>
			<Box
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}>
				<Typography variant="h6">{props.title}</Typography>
				<ActionButton
					title=""
					onClick={handleCloseIcon}
					icon={<CloseIcon sx={{ color: "primary.main" }} />}
					sx={{ bgcolor: "inherit" }}></ActionButton>
			</Box>
			{props.children}
			{props.onDone && (
				<Box
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "10px",
					}}>
					<Button
						disabled={props.doneDisabled}
						variant="contained"
						onClick={props.onDone}>
						{props.doneText || "Done"}
					</Button>
				</Box>
			)}
		</Box>
	</Modal>
);
}
