import BettermapsModal from "../../BettermapsModal";
import { Templates } from "../../Templates";

export default function TemplatesModal(props) {
  return (
    <BettermapsModal
      title="Background layer selection"
      showModal={props.showTemplatesModal}
      setShowModal={props.setShowTemplatesModal}
      sx={{ width: "1000px" }}
    >
      <Templates
        closeFunction={props.setShowTemplatesModal}
        setMapStyle={props.setMapStyle}
      ></Templates>
    </BettermapsModal>
  );
}
