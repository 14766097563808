import { IconButton, Tooltip, Checkbox, Box } from "@mui/material";

export default function CheckableActionButton(props) {
  return (
    <Tooltip title={props.title} enterDelay={1000}>
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: props.disabled ? "secondary.main" : "primary.main",
          height: 36,
          width: 80,
        }}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          pointerEvents: "fill",
        }}
      >
        <IconButton
          disabled={props.disabled}
          sx={{
            borderRadius: 1,
            maxHeight: 36,
          }}
          onClick={props.onClick}
        >
          {props.icon}
        </IconButton>
        <Checkbox
          sx={{
            color: "white",
            "&.Mui-checked": {
              color: "white",
            },
            "& svg": {
              color: "white",
            },
            maxHeight: 36,
          }}
          disabled={props.disabled}
          checked={props.checked}
          onChange={() => props.onCheckChange()}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
    </Tooltip>
  );
}
