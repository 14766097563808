import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import { getStyleAnalysis } from "../../services/DigitalMapService";
import ActionButton from "../common/ActionButton";
import ExampleStylesModal from "./ExampleStylesModal";
import { useState } from "react";
import { useSnackbar } from "notistack";

const Input = styled("input")({
  display: "none",
});

export default function WebMapStyleAnalyser(props) {
	const [showExampleStylesModal, setShowExampleStylesModal] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const doAnalysis = (file) => {
		props.setStyleAnalysis("");
		getStyleAnalysis("html", true, file)
			.then((d) => {
				props.setStyleAnalysis(d);
				props.setLoading(false);
			})
			.catch((e) => {
				enqueueSnackbar(`Error: ${e.message ? e.message : e}`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				});
			});
	};

	const handleChange = (e) => {
		if (e.target.files[0]) {
			props.setLoading(true);
			doAnalysis(e.target.files[0]);
		}
	};

	// const analyseExampleStyle = async (styleURL) => {
	//   props.setLoading(true);
	//   fetch(styleURL)
	//     .then((r) => r.blob())
	//     .then((b) => doAnalysis(b));

	//   setShowExampleStylesModal(false);
	// };
	// Refactored code
	const analyseExampleStyle = async (styleURL) => {
		try {
			props.setLoading(true);
			const response = await fetch(styleURL);
			const blob = await response.blob();
			await doAnalysis(blob);
		} catch (error) {
			console.log("Error:", error);
		}

		setShowExampleStylesModal(false);
	};

	return (
		<Box direction="row" display="flex">
			<label htmlFor="contained-button-file" style={{ marginRight: "16px" }}>
				<Input
					accept="application/JSON"
					id="contained-button-file"
					multiple
					type="file"
					onInput={handleChange}
				/>
				<ActionButton
					title="Upload Style"
					variant="filled"
					icon={<AddIcon style={{ color: "white" }}></AddIcon>}
					component="span"></ActionButton>
			</label>
			<Button
				variant="contained"
				title={"Examples"}
				onClick={() => setShowExampleStylesModal(true)}
				sx={{
					borderRadius: 1,
					maxWidth: 139,
					minWidth: 139,
					maxHeight: 36,
					mr: 2,
				}}>
				<Typography variant="button">examples</Typography>
			</Button>
			<Button
				variant="contained"
				title={"Export"}
				sx={{
					borderRadius: 1,
					maxWidth: 139,
					minWidth: 139,
					maxHeight: 36,
				}}>
				<Typography variant="button">export</Typography>
			</Button>
			<ExampleStylesModal
				showExampleStylesModal={showExampleStylesModal}
				setShowExampleStylesModal={setShowExampleStylesModal}
				analyseExampleStyle={analyseExampleStyle}></ExampleStylesModal>
		</Box>
	);
}
