import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { v4 as randomID } from "uuid";
import { useColor } from "../../../../lib/hooks";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { LayerType } from "../../../../lib/LayerType";
import { transformCSVFileToGeojson } from "../../../../services/DigitalMapService";
import BettermapsModal from "../../BettermapsModal";
import BettermapsSelect from "../../BettermapsSelect";
import LayerDetails from "../LayerDetails";
import { Analytics, Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { addLayer } from "../../../../redux/slices/layers";
import { getLayerFeatures } from "../../../../services/DatasourcesService";
import MapBoxSource from "../../MapBox/MapBoxSource";
import { useSelector } from "react-redux";
import BettermapsTextField from "../../BettermapsTextField";
import { width } from "@mui/system";

export default function CSSModal(props) {
	const dispatch = useDispatch();
	const [CSVColumns, setCSVColumns] = useState([]);
	const [CSVFileError, setCSVFileError] = useState("");
	const [CSVFileName, setCSVFileName] = useState("");
	const [CSVFile, setCSVFile] = useState();
	const [latitudeColumn, setLatitudeColumn] = useState("");
	const [longitudeColumn, setLongitudeColumn] = useState("");
	const [lineColor, setLineColor] = useColor("#02c100");
	const [fillColor, setFillColor] = useColor("#cd0000");
	const [layerName, setLayerName] = useState("");
	const [layerType, setLayerType] = useState(LayerType.Circle);
	const [zoomOnLoad, setZoomOnLoad] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const FIVE_MEGABYTES = 5 * Math.pow(1024, 2);
	const [selectColor, setSelectColor] = useState("random");
	var boundingBox = [];
	const userData = useSelector((state) => state.user);

	const zoomToBoundingBox = (boundingBox) => {
		props.map.getMap().fitBounds(boundingBox);
	};

	const handleAddCSVLayer = () => {
		setIsLoading(true);
		Auth.currentAuthenticatedUser()
			.then((u) => u.username)
			.then((ownerId) =>
				transformCSVFileToGeojson(CSVFile, ownerId, {
					lat: latitudeColumn,
					long: longitudeColumn,
				})
			)
			.then((d) => {
				setIsLoading(false);
				props.setShowCSVModal(false);
				addFeatures(d.url);
				boundingBox = d.boundingBox;
				if (zoomOnLoad) zoomToBoundingBox(d.boundingBox);
			})
			.catch((e) => {
				enqueueSnackbar(
					`Error when transforming your CSV to Geojson. Please try later...`,
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				setIsLoading(false);
				props.setShowCSVModal(false);
				console.error(e);
			});
	};

	function addFeatures(URL) {
		var typeColor = `rgba(${fillColor.rgb.r},${fillColor.rgb.g},${fillColor.rgb.b},${fillColor.rgb.a})`;
		if (layerType.value == LayerType.Line.value) {
			typeColor = `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`;
		}
		getLayerFeatures(URL).then((d) => {
			MapBoxSource(d, selectColor, typeColor).then((s) => {
				addCSVLayer(s);
			});
		});
	}

	function addCSVLayer(data, URL) {
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();
		const newLayer = {
			id: layerID,
			name: layerName,
			fileType: LayerFileType.GeoJSON,
			type: layerType,
			show: true,
			// position: layers.length + 1,
			fillColor: fillColor.rgb,
			lineColor: lineColor.rgb,
			boundingbox: boundingBox,
			selectColor: selectColor,
			featureColors: data.featureColors,
			urlSource: URL,
			opacity: layerType == LayerType.Line ? lineColor.rgb.a : fillColor.rgb.a,
			source: {
				id: sourceID,
				type: "geojson",
				data: data.data,
			},
			showOnHover: false,
			hoverProperties: [],
		};
		dispatch(addLayer(newLayer));
		// props.setLayers((layers) => {
		//   const lastLayer = layers[0];
		//   if (lastLayer) lastLayer.beforeId = layerID;
		//   return [
		//     ...layers,
		//     {
		//       id: layerID,
		//       name: layerName,
		//       fileType: LayerFileType.GeoJSON,
		//       type: layerType,
		//       show: true,
		//       position: layers.length + 1,
		//       fillColor: fillColor.hex,
		//       lineColor: lineColor.hex,
		//       boundingbox: boundingBox,
		//       opacity:
		//         layerType == LayerType.Line ? lineColor.rgb.a : fillColor.rgb.a,
		//       source: {
		//         id: sourceID,
		//         type: "geojson",
		//         data: geojsonFileURL,
		//       },
		//       showOnHover: false,
		//       hoverProperties: [],
		//     },
		//   ];
		// });
	}

	const handleCSVFileSelected = (e) => {
		setCSVFileError("");
		const selectedCSVFile = e.currentTarget.files[0];
		if (selectedCSVFile.size <= FIVE_MEGABYTES) {
			const fileReader = new FileReader();

			fileReader.onload = (e) => {
				const text = fileReader.result;
				const firstLine = text.split("\n").shift();

				setCSVColumns(firstLine.replace(/\r/g, "").split(","));
				setCSVFileName(selectedCSVFile.name);
				setLayerName(selectedCSVFile.name);
				setCSVFile(selectedCSVFile);
			};

			fileReader.readAsText(selectedCSVFile, "UTF-8");
		} else {
			setCSVFileError("CSV files cannot be greater than 5MB");
		}
	};
	return (
		<BettermapsModal
			sx={{ width: "100%", maxWidth: "700px" }}
			title="Add CSV Layer"
			showModal={props.showCSVModal}
			setShowModal={props.setShowCSVModal}
			onDone={handleAddCSVLayer}
			doneText="Add"
			doneDisabled={
				isLoading || latitudeColumn === "" || longitudeColumn == ""
			}>
			<Grid
				container
				direction="row"
				sx={{ display: "flex", justifyContent: "space-between", mt: "1rem" }}>
				<LayerDetails
					label="CSV Layer"
					xl={7}
					lg={6}
					md={5}
					sm={4}
					xs={2}
					fillColor={fillColor}
					setFillColor={setFillColor}
					lineColor={lineColor}
					setLineColor={setLineColor}
					layerName={layerName}
					setLayerName={setLayerName}
					layerType={layerType}
					selectColor={selectColor}
					setSelectColor={setSelectColor}></LayerDetails>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						display: "flex",
						alignItems: "end",
						width: "30%",
					}}>
					<Button
						variant="contained"
						component="label"
						sx={{
							borderRadius: 1,
							width: "100%",
							height: 40,
							marginTop: "6px",
						}}
						onClick={() =>
							analytics.identify("click on select csv file button", {
								id: userData.ownerId,
								subscription: userData.subscription,
							})
						}>
						Select CSV file...
						<input
							type="file"
							accept=".csv"
							hidden
							onChange={handleCSVFileSelected}
						/>
					</Button>
					<Typography style={{ marginTop: "5px" }} variant="subtitle2">
						{CSVFileName}
					</Typography>
					<Typography
						style={{ marginTop: "5px", color: "red" }}
						variant="subtitle2">
						{CSVFileError}
					</Typography>
				</Box>
			</Grid>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				sx={{ display: "flex", justifyContent: "center" }}>
				<BettermapsSelect
					sx={{ width: 200 }}
					disabled={!CSVFile}
					value={latitudeColumn}
					onChange={(e) => {
						setLatitudeColumn(e.target.value),
							analytics.identify(
								`click on Add CSV Layer Modal - Latitude: ${e.target.value}`,
								{
									id: userData.ownerId,
									subscription: userData.subscription,
								}
							);
					}}
					label="Latitude">
					{CSVColumns.map((column, index) => (
						<MenuItem key={index} value={column}>
							{column}
						</MenuItem>
					))}
				</BettermapsSelect>
				<BettermapsSelect
					sx={{ width: 200, ml: 1 }}
					disabled={!CSVFile}
					value={longitudeColumn}
					onChange={(e) => {
						setLongitudeColumn(e.target.value),
							analytics.identify(
								`click on Add CSV Layer Modal - Longitude: ${e.target.value}`,
								{
									id: userData.ownerId,
									subscription: userData.subscription,
								}
							);
					}}
					label="Longitude">
					{CSVColumns.map((column, index) => (
						<MenuItem key={index} value={column}>
							{column}
						</MenuItem>
					))}
				</BettermapsSelect>
			</Box>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				marginTop="10px">
				<CircularProgress
					style={{ display: isLoading ? "block" : "none" }}
					color="primary"
				/>
			</Box>
			<FormControlLabel
				style={{
					position: "absolute",
					left: "32px",
					bottom: "32px",
				}}
				control={
					<Checkbox
						checked={zoomOnLoad}
						onChange={() => setZoomOnLoad(!zoomOnLoad)}
					/>
				}
				label="Zoom on load"
			/>
		</BettermapsModal>
	);
}
