import { Box, Typography } from "@mui/material";
import SmallScreenImage from "../images/small_screen.svg";

export default function SmallDeviceNotificationPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 50px)",
        justifyContent: "center",
        alignItems: "center",
        p: "10px",
      }}
    >
      <Typography align="center" variant="h4" sx={{ color: "primary.main" }}>
        This device is too small to use Bettermaps
      </Typography>
      <Typography align="center" sx={{ color: "text.secondary" }}>
        Please try on a bigger device such as a tablet or desktop
      </Typography>
      <img
        style={{ width: "60%", marginTop: "50px" }}
        src={SmallScreenImage}
      ></img>
    </Box>
  );
}
