import { createSlice } from "@reduxjs/toolkit";

export const layersSlice = createSlice({
  name: "layers",
  initialState: {
    layers: [],
    backgroundLayer: {
      id: "Background",
      text: "Mapbox Bright",
      name: "Background Layer",
      show: true,
    },
  },
  reducers: {
    editReverseLayer: (state, action) => {
      state.layers[action.payload[0]].name = action.payload[1];
      state.layers[action.payload[0]].fillColor = action.payload[2];
      state.layers[action.payload[0]].lineColor = action.payload[3];
      state.layers[action.payload[0]].opacity = action.payload[4];
    },
    setlayers: (state, action) => {
      state.layers = action.payload;
    },
    resetLayers: (state) => {
      state.layers = [];
    },
    eraseLayer: (state, action) => {
      state.layers = state.layers.filter(({ id }) => id != action.payload);
    },
    addLayer: (state, action) => {
      state.layers.push(action.payload);
    },
    modifyWmsLayer: (state, action) => {
      state.layers[action.payload[0]].name = action.payload[1];
      state.layers[action.payload[0]].opacity = action.payload[2];
    },
    modifyLayer: (state, action) => {
      state.layers[action.payload[0]].showOnHover = action.payload[1];
      state.layers[action.payload[0]].hoverProperties = action.payload[2];
      state.layers[action.payload[0]].selectColor = action.payload[3];
      state.layers[action.payload[0]].fillColor = action.payload[4];
      state.layers[action.payload[0]].lineColor = action.payload[5];
      if (action.payload[6]) {
        state.layers[action.payload[0]].source = action.payload[6];
      }
      state.layers[action.payload[0]].featureColors = action.payload[7];
      state.layers[action.payload[0]].opacity = action.payload[8];
    },
    saveLayer: (state, action) => {
      var newLayers = [];
      state.layers.map((layer) => {
        if (layer.id == action.payload[0]) {
          newLayers.push({ ...layer, dbLayerId: action.payload[1] });
        } else {
          newLayers.push({ ...layer });
        }
      });
      state.layers = newLayers;
    },
    showLayer: (state, action) => {
      state.layers[action.payload].show = !state.layers[action.payload].show;
    },

    addBeforeId: (state, action) => {
      state.layers[action.payload[0]].beforeId = action.payload[1];
    },
    moveLayerUp: (state, action) => {
      var newLayers = [];
      var movingUp = {};
      var movingDown;
      state.layers.map((layer) => {
        var pos = layer.position;
        if (layer.id == action.payload[0]) {
          newLayers.push({ ...layer, position: pos + 1 });
        } else if (layer.id == action.payload[1]) {
          newLayers.push({ ...layer, position: pos - 1 });
        } else {
          newLayers.push({ ...layer });
        }
      });
      newLayers = newLayers.sort((a, b) => a.position - b.position);
      state.layers = newLayers;
    },
    editFeatureColor: (state, action) => {
      state.layers[action.payload[0]].source.data.features[
        action.payload[1]
      ].properties.color = action.payload[2];
      state.layers[action.payload[0]].featureColors[action.payload[1]] =
        action.payload[2];
    },
  },
});

export const {
  setlayers,
  resetLayers,
  eraseLayer,
  addLayer,
  modifyLayer,
  saveLayer,
  showLayer,
  addBeforeId,
  moveLayerUp,
  modifyWmsLayer,
  editReverseLayer,
  editFeatureColor,
} = layersSlice.actions;

export default layersSlice.reducer;

export const getlayers = (layers) => (dispatch) => {
  dispatch(setlayers(layers));
};

export const modifyOneLayer =
  (
    index,
    showOnHover,
    hoverProperties,
    selectColor,
    fillColor,
    lineColor,
    newLayerData,
    color,
    opacity
  ) =>
  (dispatch) => {
    var info = [
      index,
      showOnHover,
      hoverProperties,
      selectColor,
      fillColor,
      lineColor,
      newLayerData,
      color,
      opacity,
    ];
    dispatch(modifyLayer(info));
  };

export const resetLayersForNewMap = () => (dispatch) => {
  dispatch(resetLayers());
};
