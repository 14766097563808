import { RandomColorPalette } from "../../common/ColorPalette";
import { useSelector } from "react-redux";
import { v4 as randomID } from "uuid";

export default async function MapBoxSource(allData, selectColor, fillColor) {
  var returnData = {};
  var data = {};
  var dataType = "";
  var dataFeatures = [];
  var feature = {};
  var featureType = "";
  var featureColor = "";
  var geometryType = "";
  var featureCoordinates = [];
  var allProperties = {};
  var featureColors = [];

  dataType = allData.type;
  allData.features.map((f) => {
    if (f.geometry) {
      featureType = f.type;
      featureColor = selectColor == "random" ? RandomColorPalette() : fillColor;
      geometryType = f.geometry.type;
      featureCoordinates = f.geometry.coordinates;
      allProperties = f.properties;
      allProperties.color = featureColor;
      allProperties.id = `bettermaps-feature-${randomID()}`;

      feature = {
        type: featureType,
        properties: allProperties,
        geometry: {
          type: geometryType,
          coordinates: featureCoordinates,
        },
      };
      featureColors.push(featureColor);
      dataFeatures.push(feature);
      feature = {};
      featureType = "";
      featureColor = "";
      geometryType = "";
      featureCoordinates = [];
    }
  });
  data = {
    type: dataType,
    features: dataFeatures,
  };
  returnData = { data: data, featureColors: featureColors };
  return returnData;
}

export async function CreateDataSourceFromStyles(allData, colors) {
  var newLayers = [];
  var newLayer = {};
  var data = {};
  var dataType = "";
  var dataFeatures = [];
  var feature = {};
  var featureType = "";
  var featureColor = "";
  var geometryType = "";
  var featureCoordinates = [];
  var allProperties = {};
  var featureColors = [];
  var i = 0;

  dataType = allData.type;
  allData.features.map((f) => {
    if (f.geometry) {
      featureType = f.type;

      geometryType = f.geometry.type;
      featureCoordinates = f.geometry.coordinates;
      allProperties = f.properties;
      allProperties.color = colors[i];

      feature = {
        type: featureType,
        properties: allProperties,
        geometry: {
          type: geometryType,
          coordinates: featureCoordinates,
        },
      };
      featureColors.push(featureColor);
      dataFeatures.push(feature);
      feature = {};
      featureType = "";
      featureColor = "";
      geometryType = "";
      featureCoordinates = [];
      i += 1;
    }
  });
  data = {
    type: dataType,
    features: dataFeatures,
  };

  return data;
}

export async function CreateGeojsonFromDraw(layers) {
  var data = {};
  data.type = "FeatureCollection";
  data.features = [];
  var feature = {};
  var featureColors = [];
  var featureColor = "";

  feature.geometry = {};
  feature.geometry.type = "";

  feature.geometry.coordinates = [];

  layers.map((l) => {
    feature.type = "Feature";
    feature.properties = {};
    // featureColor = RandomColorPalette();
    // feature.properties.color = featureColor;
    feature.geometry = {};
    feature.geometry.type = l.type;
    feature.geometry.coordinates = l.coordinates;
    data.features.push(feature);
    feature = {};
    // featureColors.push(featureColor);
  });
  // var returnData = { data: data, featuresColors: featureColors };
  return data;
}
