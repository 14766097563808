import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, MenuItem, Modal, Typography } from "@mui/material";
import { PaperOrientation, PaperSize } from "../../../../lib/PaperSettings";
import BettermapsSelect from "../../BettermapsSelect";
import BettermapsModal from "../../BettermapsModal";

export default function PaperSettingsModal(props) {
  return (
    <BettermapsModal
      sx={{ width: "30%", minWidth: "450px" }}
      title="Paper Settings"
      showModal={props.show}
      setShowModal={props.setShow}
      onDone={() => props.setShow(false)}
      doneText="Close"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <BettermapsSelect
          sx={{ m: 1, minWidth: 120, maxWidth: 130 }}
          label="Paper Size"
          value={props.paperSettings.paperSize}
          onChange={(e) =>
            props.setPaperSettings((f) => {
              return { ...f, paperSize: e.target.value };
            })
          }
        >
          {Object.keys(PaperSize).map((k, i) => (
            <MenuItem key={i} value={PaperSize[k].value}>
              {PaperSize[k].value}
            </MenuItem>
          ))}
        </BettermapsSelect>
        <BettermapsSelect
          sx={{ m: 1, minWidth: 120, maxWidth: 130 }}
          label="Paper Orientation"
          value={props.paperSettings.paperOrientation}
          onChange={(e) =>
            props.setPaperSettings((f) => {
              return { ...f, paperOrientation: e.target.value };
            })
          }
        >
          {Object.keys(PaperOrientation).map((k, i) => (
            <MenuItem key={i} value={PaperOrientation[k]}>
              {k}
            </MenuItem>
          ))}
        </BettermapsSelect>
      </Box>
    </BettermapsModal>
  );
}
