import { Box, CircularProgress } from "@mui/material";
import DOMPurify from "dompurify";

export default function WebMapStyleAnalysisPreview(props) {
  return (
    <Box>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.styleAnalysis),
        }}
      ></div>
      {props.loading && (
        <CircularProgress
          color="primary"
          style={{ position: "absolute", right: "50%", top: "30%" }}
        />
      )}
    </Box>
  );
}
