import { Auth } from "aws-amplify";
import { LocalVariables } from "./LocalStorageVariables";

const cleanLocalStorage = () => {
  Object.keys(LocalVariables).forEach((v) =>
    window.localStorage.removeItem(LocalVariables[v])
  );
};

export default async function logOut() {
  cleanLocalStorage();
  await Auth.signOut();
  window.location.reload();
}
