import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

export default function DataSourceCard(props) {
  const width = props.width || "150px";
  return (
    <Link
      style={{ marginRight: "16px", textDecoration: "none" }}
      to={{ pathname: props.to }}
      target="_blank"
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          bgcolor: "primary.main",
          width: width,
        }}
        elevation={10}
      >
        <CardMedia
          component="img"
          image={props.cardImage}
          alt={props.cardTitle}
          sx={{ width: width, height: width }}
        />
        <Box
          sx={{
            bgcolor: "rgba(255, 255, 255, 0.84)",
            padding: "5px",
            marginBottom: "10px",
          }}
        >
          <Typography variant="body" color="text.primary">
            {props.cardTitle}
          </Typography>
        </Box>
      </Card>
    </Link>
  );
}
