import { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./styles.module.css";

import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import Modal from 'react-modal'
import { Modal, Box, Typography } from "@mui/material";

import Done from "./Done";
import Issue from "./Issue";
import BugReportIcon from "@mui/icons-material/BugReport";

export default function Feedback(props) {
	const [window, setWindow] = useState("menu");
	const userData = useSelector((state) => state.user);

	const handleClickIssue = () => {
		setWindow("issue");
		analytics.identify("click on FEEDBACK - ISSUE", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleClickIdea = () => {
		setWindow("idea");
		analytics.identify("click on FEEDBACK - IDEA", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleClickOther = () => {
		setWindow("other");
		analytics.identify("click on FEEDBACK - OTHER", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};


	return (
		<Modal
			className={styles.mymodal}
			BackdropProps={{
				style: { backgroundColor: "transparent" },
			}}
			open={props.modal}
			onClose={props.toggle}>
			<Box
				className={styles.feedback_main}
				style={{
					position: "absolute",
					bottom: props.bottom + 55,
					right: props.right,
				}}>
				{window === "menu" ? (
					<div className={styles.feedback_wrapper}>
						<Typography
							variant="h6"
							style={{ fontWeight: "bold" }}
							sx={{ color: "primary.main" }}>
							Whats on your mind?
						</Typography>
						<div className={styles.feedback_items}>
							<div onClick={handleClickIssue} className={styles.feedback_item}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<BugReportIcon
										style={{
											width: "50px",
											height: "50px",
											color: props.issueColor || "#ff5252",
										}}
									/>
									Issue
								</div>
							</div>
							<div onClick={handleClickIdea} className={styles.feedback_item}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<EmojiObjectsIcon
										style={{
											width: "50px",
											height: "50px",
											color: props.ideaColor || "#F0BC2E",
										}}
									/>
									Idea
								</div>
							</div>
							<div onClick={handleClickOther} className={styles.feedback_item}>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<MoreHorizIcon
										style={{
											width: "50px",
											height: "50px",
											color: props.otherColor || "#3c3737",
										}}
									/>
									Other
								</div>
							</div>
						</div>
					</div>
				) : window === "idea" ? (
					<Issue
						onChange={props.onChange}
						save={props.save}
						message={props.value}
						placeholder="I would love..."
						type="idea"
						toggle={setWindow}
						title="Share an idea"
						icon={
							<EmojiObjectsIcon
								style={{
									width: "20px",
									height: "20px",
									color: props.ideaColor || "#F0BC2E",
								}}
							/>
						}
					/>
				) : window === "other" ? (
					<Issue
						onChange={props.onChange}
						save={props.save}
						message={props.value}
						placeholder="What do you want us to know?"
						type="anything"
						toggle={setWindow}
						title="Tell us anything!"
						icon={null}
					/>
				) : window === "done" ? (
					<Done toggle={setWindow} />
				) : (
					<Issue
						onChange={props.onChange}
						save={props.save}
						message={props.value}
						placeholder="I noticed that..."
						type="issue"
						toggle={setWindow}
						title="Report an Issue"
						icon={
							<BugReportIcon
								style={{
									width: "20px",
									height: "20px",
									color: props.issueColor || "#ff5252",
								}}
							/>
						}
					/>
				)}
			</Box>
		</Modal>
	);
}
