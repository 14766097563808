/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLayer = /* GraphQL */ `
  mutation CreateLayer(
    $input: CreateLayerInput!
    $condition: ModelLayerConditionInput
  ) {
    createLayer(input: $input, condition: $condition) {
      id
      ownerId
      properties
      createdAt
      updatedAt
    }
  }
`;
export const updateLayer = /* GraphQL */ `
  mutation UpdateLayer(
    $input: UpdateLayerInput!
    $condition: ModelLayerConditionInput
  ) {
    updateLayer(input: $input, condition: $condition) {
      id
      ownerId
      properties
      createdAt
      updatedAt
    }
  }
`;
export const deleteLayer = /* GraphQL */ `
  mutation DeleteLayer(
    $input: DeleteLayerInput!
    $condition: ModelLayerConditionInput
  ) {
    deleteLayer(input: $input, condition: $condition) {
      id
      ownerId
      properties
      createdAt
      updatedAt
    }
  }
`;
export const createStyle = /* GraphQL */ `
  mutation CreateStyle(
    $input: CreateStyleInput!
    $condition: ModelStyleConditionInput
  ) {
    createStyle(input: $input, condition: $condition) {
      baseStyleURL
      description
      id
      layers
      name
      ownerId
      s3URL
      styleId
      createdAt
      updatedAt
    }
  }
`;
export const updateStyle = /* GraphQL */ `
  mutation UpdateStyle(
    $input: UpdateStyleInput!
    $condition: ModelStyleConditionInput
  ) {
    updateStyle(input: $input, condition: $condition) {
      baseStyleURL
      description
      id
      layers
      name
      ownerId
      s3URL
      styleId
      createdAt
      updatedAt
    }
  }
`;
export const deleteStyle = /* GraphQL */ `
  mutation DeleteStyle(
    $input: DeleteStyleInput!
    $condition: ModelStyleConditionInput
  ) {
    deleteStyle(input: $input, condition: $condition) {
      baseStyleURL
      description
      id
      layers
      name
      ownerId
      s3URL
      styleId
      createdAt
      updatedAt
    }
  }
`;
