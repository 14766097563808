import { Checkbox, FormControlLabel, MenuItem, Box, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as randomID } from "uuid";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { LayerType } from "../../../../lib/LayerType";
import { getWMSs } from "../../../../services/DatasourcesService";
import BettermapsModal from "../../BettermapsModal";
import BettermapsSelect from "../../BettermapsSelect";
import { AlphaPicker } from "react-color";
import { useColor } from "../../../../lib/hooks";
import WMSCapabilities from "wms-capabilities";
import BettermapsTextField from "../../BettermapsTextField";
import { useDispatch, useSelector } from "react-redux";
import { addLayer } from "../../../../redux/slices/layers";

export default function WMSModal(props) {
	const dispatch = useDispatch();
	const [selectedWMS, setSelectedWMS] = useState({ bbox: [], url: "" });
	const [WMSs, setWMSs] = useState([
		{ name_: "No WMSs Found", access_url: "" },
	]);
	const [WMSLayers, setWMSLayers] = useState([]);
	const [selectedWMSLayers, setSelectedWMSLayers] = useState([]);
	const [layerName, setLayerName] = useState("");
	const [color, setColor] = useColor();
	const [zoomOnLoad, setZoomOnLoad] = useState(true);
	const [showHistorical, setShowHistorical] = useState(false);
	const userData = useSelector((state) => state.user);

	useEffect(() => {
		getWMSs(showHistorical).then((d) => setWMSs(d));
	}, []);

	useEffect(() => {
		getWMSs(showHistorical).then((d) => setWMSs(d));
	}, [showHistorical]);

	function addWMSLayer() {
		props.setShowWMSModal(false);
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();
		const newLayer = {
			id: layerID,
			name: layerName,
			fileType: LayerFileType.WMS,
			type: LayerType.Raster,
			show: true,
			// position: layers.length + 1,
			opacity: color.rgb.a,
			boundingbox: selectedWMS.boundingBox,
			source: {
				id: sourceID,
				type: "raster",
				tiles: [buildTilesURL()],
				tileSize: 256,
			},
		};
		dispatch(addLayer(newLayer));
		// props.setLayers((layers) => {
		//   const lastLayer = layers[0];
		//   if (lastLayer) lastLayer.beforeId = layerID;
		//   return [
		//     ...layers,
		//     {
		//       id: layerID,
		//       name: layerName,
		//       fileType: LayerFileType.WMS,
		//       type: LayerType.Raster,
		//       show: true,
		//       position: layers.length + 1,
		//       opacity: color.rgb.a,
		//       boundingbox: selectedWMS.boundingBox,
		//       source: {
		//         id: sourceID,
		//         type: "raster",
		//         tiles: [buildTilesURL()],
		//         tileSize: 256,
		//       },
		//     },
		//   ];
		// });

		if (zoomOnLoad) zoomToBoundingBox();
	}

	const buildTilesURL = () => {
		const layers = selectedWMSLayers.join(",");
		const tilesURL = `${selectedWMS.url}?bbox={bbox-epsg-3857}&format=image/png&service=WMS&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&version=1.3.0&layers=${layers}&crs=EPSG:3857`;
		return tilesURL;
	};

	const zoomToBoundingBox = () => {
		props.map.getMap().fitBounds(selectedWMS.boundingBox);
	};

	// const handleWMSChange = (wmsURL) => {
	// 	const getCapabilitiesURL = `${wmsURL}?request=GetCapabilities&service=WMS`;

	// 	fetch(getCapabilitiesURL)
	// 		.then((response) => response.text())
	// 		.then((text) => new WMSCapabilities(text).toJSON())
	// 		.then((json) => {
	// 			setWMSLayers(getLayersOnWMS(json));
	// 			const selectedWMS = getSelectedWMS(wmsURL, json);
	// 			setSelectedWMS(selectedWMS);
	// 			setLayerName(selectedWMS.name);
	// 		});
	// };

	// Refactored Code
	const handleWMSChange = async (wmsURL) => {
		const getCapabilitiesURL = `${wmsURL}?request=GetCapabilities&service=WMS`;

		try {
			const response = await fetch(getCapabilitiesURL);
			const text = await response.text();
			const json = new WMSCapabilities(text).toJSON();

			setWMSLayers(getLayersOnWMS(json));
			const selectedWMS = getSelectedWMS(wmsURL, json);
			setSelectedWMS(selectedWMS);
			setLayerName(selectedWMS.name);
		} catch (error) {
			console.log("Error:", error);
		}
	};
	const getLayersOnWMS = (getCapabilitiesJSON) => {
		const containsValidFormat =
			getCapabilitiesJSON.Capability.Request.GetMap.Format.some((f) =>
				["image/png", "image/jpeg"].includes(f)
			);

		if (containsValidFormat) {
			return getCapabilitiesJSON.Capability.Layer.Layer.filter(
				(l) =>
					l.CRS && l.CRS.includes("EPSG:3857") && l.CRS.includes("EPSG:4326")
			).map((l) => ({
				boundingBox: l.BoundingBox.find((bb) => bb.crs === "EPSG:4326").extent,
				title: l.Title,
				name: l.Name,
			}));
		}
		return [];
	};

	const getSelectedWMS = (url, getCapabilitiesJSON) => {
		const boundingBox = getCapabilitiesJSON.Capability.Layer.BoundingBox
			? getCapabilitiesJSON.Capability.Layer.BoundingBox.find(
					(bb) => bb.crs === "EPSG:4326"
			  ).extent.reverse()
			: [180, 90, -180, -90];

		const name = getCapabilitiesJSON.Service.Title;

		return {
			url,
			boundingBox,
			name,
		};
	};

	return (
		<BettermapsModal
			sx={{ width: "30%" }}
			title="Add WMS layer"
			showModal={props.showWMSModal}
			setShowModal={props.setShowWMSModal}
			onDone={addWMSLayer}
			doneText="Add"
			doneDisabled={selectedWMSLayers.length === 0}>
			<BettermapsTextField
				label="Layer name"
				value={layerName}
				onChange={(e) => {
					setLayerName(e.target.value),
						analytics.identify(`Layer Name input value: ${e.target.value}`, {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
				}}
				sx={{
					mt: "10px",
					mb: "10px",
					maxWidth: "100%",
				}}></BettermapsTextField>
			<BettermapsSelect
				sx={{ minWidth: 120, mb: "10px" }}
				label="WMS"
				value={selectedWMS.url ?? "WMS"}
				onChange={(e) => {
					handleWMSChange(e.target.value),
						analytics.identify(`WMS value: ${e.target.value}`, {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
				}}>
				{WMSs.map((l, i) => (
					<MenuItem
						key={i}
						value={l.access_url.substring(0, l.access_url.indexOf("?"))}>
						{`${l.name_} | ${l.location_}`}
					</MenuItem>
				))}
			</BettermapsSelect>
			<BettermapsSelect
				sx={{ minWidth: 120, mb: "10px" }}
				multiple={true}
				label="WMS Layers"
				value={selectedWMSLayers}
				onChange={(e) => {
					setSelectedWMSLayers(
						e.target.value === "string"
							? e.target.value.split(",")
							: e.target.value
					);
					analytics.identify(`WMS Layers value: ${e.target.value}`, {
						id: userData.ownerId,
						subscription: userData.subscription,
					});
				}}
				renderValue={(selected) => (
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							gap: 0.5,
							maxHeight: "120px",
							overflow: "auto",
						}}>
						{selected.map((value) => (
							<Chip key={value} label={value} />
						))}
					</Box>
				)}>
				{WMSLayers.length >= 1 ? (
					WMSLayers.map((l, i) => (
						<MenuItem key={i} value={l.name}>
							{l.title}
						</MenuItem>
					))
				) : (
					<MenuItem>Select WMS before</MenuItem>
				)}
			</BettermapsSelect>
			<FormControlLabel
				style={{ marginLeft: "-10px" }}
				control={
					<Checkbox
						checked={showHistorical}
						onChange={() => {
							setShowHistorical(!showHistorical),
								analytics.identify(
									"Add WMS Layer - click show historical layers",
									{
										id: userData.ownerId,
										subscription: userData.subscription,
									}
								);
						}}
					/>
				}
				label="Show historical layers"
			/>
			<Box
				style={{
					position: "absolute",
					bottom: "45px",
					left: "184px",
				}}>
				<AlphaPicker color={color.rgb} onChange={setColor} width={150} />
			</Box>
			<FormControlLabel
				style={{
					position: "absolute",
					left: "32px",
					bottom: "32px",
				}}
				control={
					<Checkbox
						checked={zoomOnLoad}
						onChange={() => {
							setZoomOnLoad(!zoomOnLoad),
								analytics.identify("Zoom WMS Layer", {
									id: userData.ownerId,
									subscription: userData.subscription,
								});
						}}
					/>
				}
				label="Zoom on load"
			/>
		</BettermapsModal>
	);
}
