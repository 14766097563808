import { Box, Grid, Typography, Container } from "@mui/material";
import StripePricePage from "../components/Stripe/StripePricePage";
import { useTheme } from "@mui/material/styles";

export default function StripePage() {
  const theme = useTheme();

  return (
    <Container sx={{ paddingTop: "50px", width: "90%", height: "90%" }}>
      <Box
        sx={{
          border: "2px solid #000",
          borderColor: theme.palette.primary.main,
          p: 4,
          borderRadius: 2,
        }}
      >
        <StripePricePage />
      </Box>
    </Container>
  );
}
