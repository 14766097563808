import * as React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { v4 as randomID } from "uuid";
import { LayerType } from "../../../../lib/LayerType";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { useState } from "react";
import { useColor } from "../../../../lib/hooks";
import BettermapsTextField from "../../BettermapsTextField";
import { UploadJSONToS3 } from "../../../../services/DigitalMapService";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import BettermapsModal from "../../BettermapsModal";
import BettermapsColorPicker from "../../BettermapsColorPicker";
import { getLayerFeatures } from "../../../../services/DatasourcesService";
import { useDispatch } from "react-redux";
import { addLayer } from "../../../../redux/slices/layers";
import MapBoxSource from "../../MapBox/MapBoxSource";

export default function UploadJSONModal(props) {
  props = props.props;
  const dispatch = useDispatch();
  const [showUploadJSONModal, setShowUploadJSONModal] = React.useState(false);
  const OpenUploadJSONModal = () => {
    setShowUploadJSONModal(true);
  };
  const [selectColor, setSelectColor] = useState("random");
  const [fillColor, setFillColor] = useColor("#cd0000");
  const [lineColor, setLineColor] = useColor("#02c100");
  const [layerName, setLayerName] = useState("GEOJSON NAME");
  const [layerType, setLayerType] = useState(LayerType.Circle);
  const [zoomOnLoad, setZoomOnLoad] = useState(true);
  const [JSONFileName, setJSONFileName] = useState("");
  const [JSONFileError, setJSONFileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [JSONFile, setJSONFile] = useState();
  const [boundingBox, setBoundingBox] = useState("");

  const FIVE_MEGABYTES = 5 * Math.pow(1024, 2);

  const zoomToBoundingBox = (boundingBox) => {
    props.map.getMap().fitBounds(boundingBox);
  };

  const handleChange = (event) => {
    setSelectColor(event.target.value);
  };
  const theme = useTheme();
  const [layerVisibility, setLayerVisibility] = useState("public");

  const handleAddJSONLayer = () => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((u) => u.username)
      .then((ownerId) => {
        UploadJSONToS3(JSONFile, ownerId).then((d) => {
          if (d.data.body === "Geojson format not valid.") {
            enqueueSnackbar(`Geojson format not valid`, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "error",
            });
            setIsLoading(false);
            setShowUploadJSONModal(false);
            props.setShowGeoJSONModal(false);
          } else if (d.data.body.url && d.data.body.boundingBox) {
            setIsLoading(false);
            setShowUploadJSONModal(false);
            props.setShowGeoJSONModal(false);
            setBoundingBox(d.data.body.boundingBox);
            addFeatures(d.data.body.url);
            if (zoomOnLoad) zoomToBoundingBox(d.data.body.boundingBox);
          } else {
            enqueueSnackbar(
              `Error Uploading your Geojson, please verify the file`,
              {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "error",
              }
            );
            setIsLoading(false);
            setShowUploadJSONModal(false);
            props.setShowGeoJSONModal(false);
          }
        });
      });
  };

  function addFeatures(URL) {
    var geometryType;
    getLayerFeatures(URL).then((d) => {
      if (
        d.features[0].geometry.type.toLowerCase() == "polygon" ||
        d.features[0].geometry.type.toLowerCase() == "multipolygon"
      ) {
        geometryType = LayerType.Fill;
        addMoreFeatures(URL, geometryType);
      } else if (d.features[0].geometry.type.toLowerCase() == "point") {
        geometryType = LayerType.Circle;
        addMoreFeatures(URL, geometryType);
      } else if (d.features[0].geometry.type.toLowerCase() == "line") {
        geometryType = LayerType.Line;
        addMoreFeatures(URL, geometryType);
      }
    });
  }

  function addMoreFeatures(URL, geometryType) {
    var url = URL;
    getLayerFeatures(URL).then((d) => {
      MapBoxSource(d, selectColor, fillColor.hex).then((s) => {
        addJSONLayer(s, url, geometryType);
      });
    });
  }

  function addJSONLayer(s, url, geometryType) {
    const layerID = `bettermaps-layer-${randomID()}`;
    const sourceID = randomID();
    const newLayer = {
      id: layerID,
      name: layerName,
      fileType: LayerFileType.GeoJSON,
      type: geometryType,
      show: true,
      // position: layers.length + 1,
      fillColor: fillColor.hex,
      lineColor: lineColor.hex,
      selectColor: selectColor,
      featureColors: s.featureColors,
      urlSource: url,
      boundingbox: boundingBox,
      opacity: geometryType.value == "line" ? lineColor.rgb.a : fillColor.rgb.a,
      source: {
        id: sourceID,
        type: "geojson",
        data: s.data,
      },
      showOnHover: false,
      hoverProperties: [],
    };
    dispatch(addLayer(newLayer));
    // props.setLayers((layers) => {
    //   const lastLayer = layers[0];
    //   if (lastLayer) lastLayer.beforeId = layerID;
    //   return [
    //     ...layers,
    //     {
    //       id: layerID,
    //       name: layerName,
    //       fileType: LayerFileType.GeoJSON,
    //       type: GeometryType,
    //       show: true,
    //       position: layers.length + 1,
    //       fillColor: fillColor.hex,
    //       lineColor: lineColor.hex,
    //       boundingbox: boundingBox,
    //       opacity:
    //         GeometryType.value == "line" ? lineColor.rgb.a : fillColor.rgb.a,
    //       source: {
    //         id: sourceID,
    //         type: "geojson",
    //         data: geojsonFileURL,
    //       },
    //       showOnHover: false,
    //       hoverProperties: [],
    //     },
    //   ];
    // });
  }

  const handleJSONFileSelected = (e) => {
    setJSONFileError("");
    const selectedJSONFile = e.currentTarget.files[0];

    if (selectedJSONFile.size <= FIVE_MEGABYTES) {
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        setJSONFileName(selectedJSONFile.name);
        setLayerName(selectedJSONFile.name);
        setJSONFile(selectedJSONFile);
      };

      fileReader.readAsText(selectedJSONFile, "UTF-8");
    } else {
      setJSONFileError("Geojson files cannot be greater than 5MB");
    }
  };

  return (
    <div>
      {/* <Button
        onClick={OpenUploadJSONModal}
        variant="contained"
        sx={{ mt: "5px" }}
      >
        Upload Geojson
      </Button> */}
      <BettermapsModal
        sx={{ width: "70%", maxWidth: "500px" }}
        title="Add Geojson Layer"
        showModal={showUploadJSONModal}
        setShowModal={setShowUploadJSONModal}
        onDone={handleAddJSONLayer}
        doneText="Add"
        doneDisabled={isLoading || JSONFile == undefined}
      >
        <Grid container direction="column">
          <Box
            flex="1"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <BettermapsTextField
              label="Layer name"
              value={layerName}
              onChange={(e) => setLayerName(e.target.value)}
            ></BettermapsTextField>

            <Button
              variant="contained"
              component="label"
              sx={{ borderRadius: 1, width: 50, height: 50 }}
            >
              File
              <input
                type="file"
                accept=".geojson"
                hidden
                onChange={handleJSONFileSelected}
              />
            </Button>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectColor}
                onChange={handleChange}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <FormControlLabel
                      value="unique"
                      control={<Radio />}
                      label="Unique Color"
                    />
                  </Box>

                  <Box>
                    <FormControlLabel
                      value="random"
                      control={<Radio />}
                      label="Random Color"
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          {selectColor == "unique" && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                // marginRight: "40px",
                // marginTop: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: "20px",
                  marginTop: "10px",
                }}
              >
                <Typography>
                  {layerType.value == LayerType.Line.value
                    ? "Line color"
                    : "Outline color"}
                </Typography>
                <Box style={{ marginLeft: "10px" }}>
                  <BettermapsColorPicker
                    color={lineColor}
                    setColor={setLineColor}
                    disableAlpha={layerType.value != LayerType.Line.value}
                  />
                </Box>
              </Box>
              {layerType.value != LayerType.Line.value && (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "40px",
                    marginTop: "10px",
                  }}
                >
                  <Typography>Fill color</Typography>

                  <Box style={{ marginLeft: "10px" }}>
                    <BettermapsColorPicker
                      color={fillColor}
                      setColor={setFillColor}
                      disableAlpha={layerType.value == LayerType.Line.value}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {/* <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "20px",
                marginTop: "10px",
              }}
            >
              <Typography>
                {layerType.value == LayerType.Line.value
                  ? "Line color"
                  : "Outline color"}
              </Typography>
              <Box style={{ marginLeft: "10px" }}>
                <BettermapsColorPicker
                  color={lineColor}
                  setColor={setLineColor}
                  disableAlpha={layerType.value != LayerType.Line.value}
                />
              </Box>
            </Box>
            {layerType.value != LayerType.Line.value && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: "40px",
                  marginTop: "10px",
                }}
              >
                <Typography>Fill color</Typography>

                <Box style={{ marginLeft: "10px" }}>
                  <BettermapsColorPicker
                    color={fillColor}
                    setColor={setFillColor}
                    disableAlpha={layerType.value == LayerType.Line.value}
                  />
                </Box>
              </Box>
            )} */}
          {/* <ToggleButtonGroup
              disabled
              style={{
                backgroundColor: theme.palette.secondary.main,
                width: "fit-content",
                marginTop: "10px",
              }}
              value={layerVisibility}
              size="small"
              exclusive
              onChange={(e, a) => setLayerVisibility(a)}
            >
              <ToggleButton value="owned">
                <FavoriteBorderIcon sx={{ color: "white" }} />
              </ToggleButton>
              <ToggleButton value="public">
                <LockOpenIcon sx={{ color: "white" }} />
              </ToggleButton>
              <ToggleButton value="private">
                <LockOutlinedIcon sx={{ color: "white" }} />
              </ToggleButton>
            </ToggleButtonGroup> */}
          {/* </Box> */}
        </Grid>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          marginTop="10px"
        >
          <CircularProgress
            style={{ display: isLoading ? "block" : "none" }}
            color="primary"
          />
        </Box>
        <FormControlLabel
          style={{
            position: "absolute",
            left: "32px",
            bottom: "32px",
          }}
          control={
            <Checkbox
              checked={zoomOnLoad}
              onChange={() => setZoomOnLoad(!zoomOnLoad)}
            />
          }
          label="Zoom on load"
        />
      </BettermapsModal>
    </div>
  );
}
