export const PaperSize = {
  A2: { value: "A2", dimensions: [594, 420] },
  A3: { value: "A3", dimensions: [420, 297] },
  A4: { value: "A4", dimensions: [297, 210] },
  A5: { value: "A5", dimensions: [210, 148] },
  A6: { value: "A6", dimensions: [148, 105] },
  B2: { value: "B2", dimensions: [707, 500] },
  B3: { value: "B3", dimensions: [500, 353] },
  B4: { value: "B4", dimensions: [353, 250] },
  B5: { value: "B5", dimensions: [250, 176] },
  B6: { value: "B6", dimensions: [176, 125] },
};

export const PaperOrientation = {
  Landscape: "landscape",
  Portrait: "portrait",
};
