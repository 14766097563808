const ColorPalette = [
  { r: 244, g: 67, b: 54 },
  { r: 0, g: 150, b: 136 },
  { r: 205, g: 220, b: 57 },
  { r: 233, g: 30, b: 99 },
  { r: 156, g: 39, b: 176 },
  { r: 255, g: 138, b: 128 },
  { r: 33, g: 150, b: 243 },
  { r: 0, g: 188, b: 212 },
  { r: 76, g: 175, b: 80 },
  { r: 255, g: 152, b: 0 },
  { r: 255, g: 235, b: 59 },
  { r: 121, g: 85, b: 72 },
  { r: 158, g: 158, b: 158 },
  { r: 207, g: 216, b: 220 },
];

export function RandomColorPalette() {
  let RandomColor =
    ColorPalette[Math.floor(Math.random() * ColorPalette.length)];
  RandomColor = `rgba(${RandomColor.r},${RandomColor.g},${RandomColor.b},1)`;
  return RandomColor;
}

// "#cddc39",
//   "#607d8b",
//   "#e91e63",
//   "#9c27b0",
//   "#ff8a80",
//   "#2196f3",
//   "#00bcd4",
//   "#4caf50",
//   "#ff9800",
//   "#ffeb3b",
//   "#795548",
//   "#9e9e9e",
//   "#cfd8dc",
