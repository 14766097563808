import { useSelector } from 'react-redux';

import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import MapboxMap from '../common/MapboxMap';

const thumbnails = [
	{
		latitude: 51.5152,
		longitude: -0.1419,
		zoom: 15,
	},
	{
		latitude: 40.4152,
		longitude: -3.703,
		zoom: 11,
	},
	{
		latitude: 52.5132,
		longitude: 13.3846,
		zoom: 7,
	},
	{
		latitude: 46.8643,
		longitude: 2.5741,
		zoom: 3,
	},
	{
		latitude: 33.7243,
		longitude: 6.0897,
		zoom: 1,
	},
];

export default function ZoomViews(props) {
	const theme = useTheme();
	const [showThumbnails, setShowThumbnails] = useState(true);
	const userData = useSelector((state) => state.user);

	const handleClickZoom = () => {
		setShowThumbnails(!showThumbnails),
			analytics.identify('click on Zoom Views', {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
	};

	return (
		<Stack
			alignItems="center"
			spacing={1}
			style={{
				position: 'fixed',
				top: props.top || 65,
				right: props.right || 15,
				zIndex: 1,
				display:
					props.onEditMode || props.onEditMode == null ? 'block' : 'none',
			}}>
			<Button
				variant="contained"
				title={'Zoom views'}
				onClick={handleClickZoom}
				sx={{ borderRadius: 1, maxWidth: '166', width: '15vh' }}>
				<Typography variant="button">zoom views</Typography>
			</Button>
			{thumbnails
				.sort((a, b) => (a.zoom > b.zoom ? -1 : 1))
				.map((t, index) => (
					<Box
						key={index}
						hidden={showThumbnails}
						sx={{
							borderColor: theme.palette.primary.main,
							borderWidth: '2px',
							borderStyle: 'solid',
						}}>
						<MapboxMap
							id={`zoom-view-${index}`}
							static
							layers={props.layers}
							latitude={t.latitude}
							longitude={t.longitude}
							zoom={t.zoom}
							height="15vh"
							width="15vh"
							maxHeight="16vh"
							maxWidth="15vh"
							mapStyle={props.mapStyle}
						/>
					</Box>
				))}
		</Stack>
	);
}
