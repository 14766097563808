export const LocalVariables = {
  BettermapsVersion: "bettermapsVersion",
  Layers: "layers",
  Style: "style",
  ShowLayerTiles: "showLayerTiles",
  StyleName: "styleName",
  StyleId: "styleId",
  Scale: "scale",
  Navigation: "navigation",
  Legend: "legend",
  Attribution: "attribution",
  PaperSettings: "paperSettings",
  ExportSettings: "exportSettings",
  MapBoundingBox: "mapBoundingBox",
};
