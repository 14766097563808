import { Grid, Box } from "@mui/material";
import { styled } from "@mui/material";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  borderStyle: process.env.REACT_APP_ENV == "dev" ? "dashed" : "",
  borderWidth: "0.5px",
  alignItems: "center",
  height: "calc(100vh - 64px)",
  justifyContent: "center",
}));

export default function HalfPage(props) {
  return (
    <div>
      <Box sx={{ flexGrow: 1 }} align="center" direction="column">
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            <Item sx={{ padding: "0px" }}>{props.leftContent}</Item>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Item sx={{ padding: "0px" }}>{props.rightContent}</Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
