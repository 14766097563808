import { MenuItem } from "@mui/material";
import { useState } from "react";
import BettermapsModal from "../common/BettermapsModal";
import BettermapsSelect from "../common/BettermapsSelect";

export default function ExampleStylesModal(props) {
  const [exampleStyleFile, setExampleStyleFile] = useState(
    "dark-matter-gl-style-master/style.json"
  );

  const exampleStyles = [
    {
      value: "dark-matter-gl-style-master/style.json",
      name: "dark-matter-gl-style-master",
    },
    {
      value: "maptiler-terrain-gl-style-master/style.json",
      name: "maptiler-terrain-gl-style-master",
    },
    {
      value: "maptiler-toner-gl-style-master/style.json",
      name: "maptiler-toner-gl-style-master",
    },
    {
      value: "osm-bright-gl-style-master/style.json",
      name: "osm-bright-gl-style-master",
    },
    {
      value: "osm-liberty-gh-pages/style.json",
      name: "osm-liberty-gh-pages",
    },
    {
      value: "osm-liberty-topo-gh-pages/style.json",
      name: "osm-liberty-topo-gh-pages",
    },
  ];

  return (
    <BettermapsModal
      sx={{ width: "60%" }}
      title="Examples Styles Selection"
      showModal={props.showExampleStylesModal}
      setShowModal={props.setShowExampleStylesModal}
      onDone={() =>
        props.analyseExampleStyle(
          process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL +
            `examples/${exampleStyleFile}`
        )
      }
      doneText="Select"
    >
      <BettermapsSelect
        label="Example Style"
        value={exampleStyleFile}
        onChange={(e) => setExampleStyleFile(e.target.value)}
      >
        {exampleStyles.map((exampleStyle, index) => (
          <MenuItem key={index} value={exampleStyle.value}>
            {exampleStyle.name}
          </MenuItem>
        ))}
      </BettermapsSelect>
    </BettermapsModal>
  );
}
