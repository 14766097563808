import DeleteIcon from "@mui/icons-material/Delete";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Box,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DeleteStyleModal from "../components/common/CreateMap/modals/DeleteStyleModal";
import TablePaginationActions from "../components/DataLayerPage/TablePaginationActions";
import DataSourceCard from "../components/DataSources/DataSourceCard";
import { listUserStyles, getUserStyle } from "../services/DatasourcesService";
import { useSelector, useDispatch } from "react-redux";
import { setStyleId, setStyleName, setMapStyle } from "../redux/slices/style";
import { resetLayers, setlayers } from "../redux/slices/layers";
import { resetBoundingBox } from "../redux/slices/boundingBox";

export default function DataSourcesPage() {
  const dispatch = useDispatch();
  const { ownerId } = useSelector((state) => state.user);
  const [styles, setStyles] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [nextToken, setNextToken] = useState();
  const [showDeleteStyleModal, setShowDeleteStyleModal] = useState(false);
  const [styleToDeleteId, setStyleToDeleteId] = useState();

  const columns = [
    { id: "name", value: "Name" },
    { id: "descripcion", value: "Description" },
    { id: "actions", value: "Actions" },
  ];

  useEffect(() => {
    if (!ownerId) {
      Auth.currentAuthenticatedUser()
        .then((u) => u.username)
        .then((ownerId) => {
          listUserStyles(rowsPerPage, ownerId).then((d) => {
            setStyles(d.styles);
            setNextToken(d.nextToken);
          });
        });
    } else {
      listUserStyles(rowsPerPage, ownerId).then((d) => {
        setStyles(d.styles);
        setNextToken(d.nextToken);
      });
    }
  }, []);

  const handleOnOpenMap = (id) => {
    getUserStyle(id).then((d) => {
      dispatch(setStyleId(d.id));
      dispatch(setlayers(d.layers));
      dispatch(setStyleName(d.name));
      dispatch(setMapStyle(d.baseStyleURL));
      dispatch(resetBoundingBox());
    });
    history.push("/create-map");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    listUserStyles(event.target.value).then((d) => {
      setStyles(d.styles);
      setNextToken(d.nextToken);
    });
    setPage(0);
  };

  const handleDeleteStyle = (styleId) => {
    setStyleToDeleteId(styleId);
    setShowDeleteStyleModal(true);
  };

  const removeStyleFromTable = () => {
    listUserStyles(styles.length, ownerId).then((d) => {
      setStyles(d.styles);
      setNextToken(d.nextToken);
    });
  };

  const getFollowingEntries = () => {
    listUserStyles(rowsPerPage, ownerId, nextToken).then((d) => {
      setStyles([...styles, ...d.styles]);
      setNextToken(d.nextToken);
    });
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.secondary.main,
    },
  }));

  const cards = [
    [
      "HERE",
      "",
      "/images/sources/HERE.png",
      "https://developer.here.com/products/platform/data",
    ],
    [
      "Mapbox",
      "",
      "/images/sources/Mapbox.png",
      "https://www.mapbox.com/data-products",
    ],
    [
      "OSM",
      "",
      "/images/sources/OSM.png",
      "https://www.openstreetmap.org/export#map=5/51.500/-0.100",
    ],
    [
      "Maptiler",
      "",
      "/images/sources/Maptiler.svg",
      "https://data.maptiler.com/downloads/planet/",
    ],
  ];

  return (
    <Container sx={{ paddingTop: "32px", width: "90%" }}>
      <Typography variant="h4" sx={{ mt: 1, mb: 5 }}>
        External Data sources
      </Typography>
      <Box style={{ display: "flex" }}>
        {cards.map((card, index) => (
          <DataSourceCard
            key={index}
            cardTitle={card[0]}
            cardImage={card[2]}
            onClick={card[1]}
            to={card[3]}
          />
        ))}
      </Box>
      <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
        Open data sources
      </Typography>
      <TableContainer
        sx={{
          maxHeight: 440,
          marginTop: 1,
          borderStyle: "solid",
          borderCollapse: "primary.main",
          borderRadius: "6px",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "primary.main",
                    color: "white",
                    pt: "8px",
                    pb: "8px",
                  }}
                  key={column.id}
                  align="left"
                  style={{
                    width: column.id == "actions" ? "140px" : "500px",
                  }}
                >
                  {column.value}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {styles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((style) => {
                return (
                  <StyledTableRow role="checkbox" tabIndex={-1} key={style.id}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        style={{
                          width: column.id == "actions" ? "140px" : "500px",
                        }}
                      >
                        {column.id == "actions" ? (
                          <Box sx={{ display: "flex" }}>
                            <Tooltip title="Open as map">
                              <IconButton
                                color="primary"
                                sx={{ pt: 0, pb: 0, pl: 0 }}
                                onClick={() => handleOnOpenMap(style.id)}
                                // component={Link}
                                // to={`create-map?styleId=${style.id}`}
                              >
                                <EditLocationIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download Style">
                              <span>
                                <IconButton
                                  color="primary"
                                  sx={{ pt: 0, pb: 0 }}
                                  disabled
                                >
                                  <FileDownloadIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                            <Tooltip title="Delete style">
                              <IconButton
                                color="primary"
                                sx={{ pt: 0, pb: 0 }}
                                onClick={() => handleDeleteStyle(style.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          style[column.id]
                        )}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => (
          <TablePaginationActions
            entries={styles}
            nextToken={nextToken}
            getFollowingEntries={getFollowingEntries}
            {...props}
          />
        )}
        showFirstButton={false}
        showLastButton={false}
      />

      <DeleteStyleModal
        show={showDeleteStyleModal}
        setShow={setShowDeleteStyleModal}
        styleToDeleteId={styleToDeleteId}
        removeStyleFromTable={removeStyleFromTable}
      ></DeleteStyleModal>
    </Container>
  );
}
