// import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, TextField } from "@mui/material";
import React from "react";
import MapboxMap from "../components/common/MapboxMap";

export default function ExploreMapPage() {
	const [answer, setAnswer] = React.useState("");
	const [request, setRequest] = React.useState("");

	const handleChange = (event) => {
		setRequest(event.target.value);
	};

	function ControlPanel() {
		return (
			<div className="control-panel">
				<h3>Cass is a friendly AI Map creator</h3>
				<p>Ask him something, so he can help you to create a map.</p>
			</div>
		);
	}

	// const sendRequest = (event) => {
	//   fetch('https://okowggj7mb.execute-api.eu-central-1.amazonaws.com/'+ process.env.REACT_APP_ENV + '/', {
	//     method: "POST",
	//     headers: {
	//       "Content-Type": "application/json",
	//     },
	//     body: JSON.stringify({
	//       text: request,
	//     }),
	//   })
	//     .then((response) => response.json())
	//     .then((data) => setAnswer(data.body));
	// };

	// Refactored Code

	const sendRequest = async (event) => {
		try {
			const response = await fetch(
				"https://okowggj7mb.execute-api.eu-central-1.amazonaws.com/" +
					process.env.REACT_APP_ENV +
					"/",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						text: request,
					}),
				}
			);
			const data = await response.json();
			setAnswer(data.body);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<React.Fragment>
			<MapboxMap
				// ref={mapRef}
				// id={mapId}
				latitude="40.0"
				longitude="-3"
				zoom="5"
				height="calc(100vh - 50px)"
				// mapStyle={mapstyle}
				content={
					<React.Fragment>
						{/* <CurrentZoomLevel mapId={mapId} right={60} top={20} /> */}
						{/* <NavigationControl style={navControlStyle} /> */}
						{/* <AttributionControl compact={true} style={attributionStyle} /> */}
						{/* <ScaleControl
              maxWidth={100}
              unit="metric"
              style={scaleControlStyle}
            /> */}
					</React.Fragment>
				}
			/>
			<ControlPanel />

			{/* <ControlPanel /> */}
			<Box
				sx={{
					width: "60%",
					maxWidth: "80%",
					position: "relative",
					zIndex: "99",
					bottom: "160px",
					left: "200px",
					// right: '200px',
				}}>
				<TextField
					fullWidth
					label="Talk to Cass to create a map"
					id="fullWidth"
					value={request}
					onChange={handleChange}
					InputProps={{
						endAdornment: (
							<Button
								variant="contained"
								endIcon={<SendIcon />}
								onClick={sendRequest}>
								Send
							</Button>
						),
					}}
					sx={{
						backgroundColor: "white",
						bottom: "10px",
					}}></TextField>
				<TextField
					fullWidth
					id="outlined-multiline-flexible"
					//   label="Cass understood:"
					multiline
					maxRows={4}
					value={answer}
					sx={{
						backgroundColor: "white",
						top: "10px",
					}}
				/>
			</Box>
		</React.Fragment>
	);
}
