import { useState } from "react";
import MapImageAnalyser from "../components/AnalyseMapImage/MapImageAnalyser";
import MapImageAnalysisPreview from "../components/AnalyseMapImage/MapImageAnalysisPreview";
import HalfPage from "../components/common/Grid/HalfPage";
import { usePersistedState } from "../lib/hooks";

export default function AnalyseMapImagePage(props) {
  const [geoJson, setGeoJson] = usePersistedState("imageAnalysisGeoJSON", {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPoint",
          coordinates: [
            [4.446657, 51.02457],
            [4.479846, 50.944796],
          ],
        },
        properties: {
          prop0: "value0",
        },
      },
    ],
  });
  const [loading, setLoading] = useState(false);

  return (
    <HalfPage
      leftContent={
        <MapImageAnalyser setGeoJson={setGeoJson} setLoading={setLoading} />
      }
      rightContent={
        <MapImageAnalysisPreview geoJson={geoJson} loading={loading} />
      }
    ></HalfPage>
  );
}
