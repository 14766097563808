import post from "../clients/HttpClient";
import { Auth } from "aws-amplify";

export async function sendFeedback(feedback) {
  const currentUser = await Auth.currentAuthenticatedUser();
  return post(
    `${process.env.REACT_APP_USER_FEEDBACK_LAMBDA}/${process.env.REACT_APP_ENV}`,
    JSON.stringify({ ...feedback, userEmail: currentUser.attributes.email })
  );
}
