import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import BettermapsModal from "../../../BettermapsModal";
import BettermapsColorPicker from "../../../BettermapsColorPicker";
import React, { useEffect, useState } from "react";
import { editFeatureColor } from "../../../../../redux/slices/layers";
import { useDispatch, useSelector } from "react-redux";

export default function EditFeatureColorModal(props) {
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);
  const [lineColor, setLineColor] = useState(
    props.show.feature.properties.color
  );
  const [layerIndex, setLayerIndex] = useState();
  const [featureIndex, setFeatureIndex] = useState();
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    var firstIndex = layers.findIndex((obj) => obj.id == props.show.layer.id);
    var secondIndex = layers[firstIndex].source.data.features.findIndex(
      (obj) => obj.properties.id == props.show.feature.properties.id
    );

    setLayerIndex(firstIndex);
    setFeatureIndex(secondIndex);
  }, []);

  const editFeature = () => {
    var newColor = `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`;

    var result = [layerIndex, featureIndex, newColor];
    dispatch(editFeatureColor(result));

    props.setShow(false);
  };

  useEffect(() => {
    if (lineColor != props.show.feature.properties.color) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  }, [lineColor]);

  return (
    <BettermapsModal
      sx={{ width: "50%", maxWidth: "300px" }}
      title="Edit Feature"
      showModal={props.show.show}
      setShowModal={props.setShow}
      onDone={editFeature}
      doneText="Edit"
      doneDisabled={isDone === false}
    >
      <Box sx={{ display: "flex", mt: "10px" }}>
        <Typography sx={{ mr: "5px" }}>Color:</Typography>
        <BettermapsColorPicker
          color={lineColor}
          setColor={setLineColor}
          // disableAlpha={
          //   props.layerType.value != LayerType.Line.value
          // }
        />
      </Box>
    </BettermapsModal>
  );
}
