import { useSelector } from 'react-redux';

import { Typography, Card, CardMedia, CardContent, Grid } from '@mui/material';
import { Link as Route } from 'react-router-dom';
export default function DashBoardCard(props) {
	const userData = useSelector((state) => state.user);

	return props.cardText == 'Documentation' ? (
		<Card
			sx={{
				height: '100%',
				width: '140px',
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '10px',
				bgcolor: 'primary.main',
			}}
			style={{ ...props.style }}
			component="a"
			href={props.to}
			target="_blank"
			onClick={() =>
				analytics.identify(`click on dashboard ${props.cardText}`, {
					id: userData.ownerId,
					subscription: userData.subscription,
				})
			}>
			<CardMedia
				children={props.cardIcon}
				sx={{ padding: '10% 10% 0 10%' }}
			/>
			<CardContent style={{ padding: '10px' }}>
				<Typography
					gutterBottom
					component="h2"
					color="white">
					{props.cardText}
				</Typography>
			</CardContent>
		</Card>
	) : (
		<Card
			sx={{
				height: '100%',
				width: '140px',
				display: 'flex',
				flexDirection: 'column',
				borderRadius: '10px',
				bgcolor: 'primary.main',
			}}
			style={{ ...props.style }}
			component={Route}
			to={props.to}
			onClick={() =>
				analytics.identify(`click on dashboard ${props.cardText}`, {
					id: userData.ownerId,
					subscription: userData.subscription,
				})
			}>
			<CardMedia
				children={props.cardIcon}
				sx={{ padding: '10% 10% 0 10%' }}
			/>
			<CardContent style={{ padding: '10px' }}>
				<Typography
					gutterBottom
					component="h2"
					color="white">
					{props.cardText}
				</Typography>
			</CardContent>
		</Card>
	);
}
