import DragHandleIcon from "@mui/icons-material/DragHandle";
import AttributionIcon from "@mui/icons-material/FormatColorText";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import MapIcon from "@mui/icons-material/Map";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import NearMeIcon from "@mui/icons-material/NearMe";
import RoundedCornerOutlinedIcon from "@mui/icons-material/RoundedCornerOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { LayerType } from "../../../lib/LayerType";
import { analyseStyle } from "../../../services/StyleAnalysisService";
import Icon from "../Icon";
import ActionsMenu from "./ActionsMenu";
import AddedLayersDisplay from "./AddedLayersDisplay";
import ControlModal from "./modals/ControlModal";
import ExportModal from "./modals/ExportModal";
import GeoJSONModal from "./modals/GeoJSONModal";
import KMLModal from "./modals/KMLModal";
import ExcelModal from "./modals/ExcelModal";
import MapExtendModal from "./modals/MapExtendModal";
import NewMapModal from "./modals/NewMapModal";
import PaperExportModal from "./modals/PaperExportModal";
import PaperSettingsModal from "./modals/PaperSettingsModal";
import RemoveLayerModal from "./modals/RemoveLayerModal";
import SaveStyleModal from "./modals/SaveStyleModal";
import TemplatesModal from "./modals/TemplatesModal";
import CSVModal from "./modals/CSVModal";
import EditGeoJSONModal from "./modals/editLayer/EditGeoJSONModal";
import WMSModal from "./modals/WMSModal";
// import UploadJSONModal from "./modals/UploadJSONModal";
import { useQueryParams } from "../../../lib/hooks";
import {
  createUserLayer,
  createUserStyle,
  updateUserLayer,
  updateUserStyle,
} from "../../../services/DatasourcesService";
import { LayerFileType } from "../../../lib/LayerFileType";
import EditKMLModal from "./modals/editLayer/EditKMLModal";
import EditWMSModal from "./modals/editLayer/EditWMSModal";
import EditReverseLayerModal from "./modals/editLayer/EditReverseLayerModal";
import DrawLayerModal from "./modals/DrawLayerModal";
import { useDispatch, useSelector } from "react-redux";
import { saveLayer } from "../../../redux/slices/layers";
import { getlayers } from "../../../redux/slices/layers";
import { setStyleId } from "../../../redux/slices/style";
import { useHistory } from "react-router-dom";
import { getMapData } from "../../../redux/slices/map";
import { canPrintPaperMap } from "../../../services/StripeService";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

export default function MapCreator(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { layers } = useSelector((state) => state.layers);
  const { ownerId } = useSelector((state) => state.user);
  const { styleId } = useSelector((state) => state.style);
  const { styleName } = useSelector((state) => state.style);
  const { mapStyle } = useSelector((state) => state.style);
  const { mapId } = useSelector((state) => state.maps);
  const { boundingBox } = useSelector((state) => state.boundingBox);
  const { email } = useSelector((state) => state.user);
  const myMap = useMap()[props.mapId];
  const myDrawMap = useMap()["Draw-map"];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const queryParams = useQueryParams();
  const [inExport, setInExport] = useState(false);
  const [styleSaved, setStyleSaved] = useState(false);
  const [styleAnalysed, setStyleAnalysed] = useState(false);
  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  // const [showUploadJSONModal, setShowUploadJSONModal] = useState(false);
  const [showWMSModal, setShowWMSModal] = useState(false);

  const [showGeoJSONModal, setShowGeoJSONModal] = useState(false);
  const [showKMLModal, setShowKMLModal] = useState(false);
  const [showExcelModal, setShowExcelModal] = useState(false);
  const [removeLayerModal, setRemoveLayerModal] = useState({
    show: false,
    layer: null,
  });
  const [editGeoJSONLayerModal, setEditGeoJSONLayerModal] = useState({
    show: false,
    layer: null,
  });
  const [editKMLLayerModal, setEditKMLLayerModal] = useState({
    show: false,
    layer: null,
  });
  const [editReverseLayerModal, setEditReverseLayerModal] = useState({
    show: false,
    layer: null,
  });
  const [editWMSLayerModal, setEditWMSLayerModal] = useState({
    show: false,
    layer: null,
  });
  const [showNewMapModal, setShowNewMapModal] = useState(false);
  const [showMapExtendModal, setShowMapExtendModal] = useState(false);
  const [showDrawLayerModal, setShowDrawLayerModal] = useState(false);
  const [showPaperSettingsModal, setShowPaperSettingsModal] = useState(false);
  const [showPaperExportModal, setShowPaperExportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showSaveStyleModal, setShowSaveStyleModal] = useState(false);
  const [showCSVModal, setShowCSVModal] = useState(false);

  const [showScaleControlModal, setShowScaleControlModal] = useState(false);
  const [showLegendControlModal, setShowLegendControlModal] = useState(false);
  const [showNavigationControlModal, setShowNavigationControlModal] =
    useState(false);

  const openTemplatesModal = () => setShowTemplatesModal(true);
  const openWMSModal = () => setShowWMSModal(true);
  // const openUploadJSONModal = () => setShowUploadJSONModal(true);
  const openGeoJSONModal = () => setShowGeoJSONModal(true);
  const openKMLModal = () => setShowKMLModal(true);
  const openExcelModal = () => setShowExcelModal(true);
  const openNewMapModal = () => setShowNewMapModal(true);
  const openMapExtendModal = () => setShowMapExtendModal(true);
  const openDrawLayerModal = () => setShowDrawLayerModal(true);
  const openPaperSettingsModal = () => setShowPaperSettingsModal(true);
  const openPaperExportModal = () => setShowPaperExportModal(true);
  const openExportModal = () => setShowExportModal(true);
  const openSaveStyleModal = () => setShowSaveStyleModal(true);
  const openCSVModal = () => setShowCSVModal(true);

  useEffect(() => {
    if (history.location.pathname != "/export-paper-map") {
      if (props.layers.length > 0 && myMap != undefined) {
        if (props.layers[props.layers.length - 1].boundingbox) {
          myMap
            .getMap()
            .fitBounds(props.layers[props.layers.length - 1].boundingbox);
        }
      } else if (boundingBox && boundingBox.length > 0 && myMap != undefined) {
        myMap.getMap().fitBounds(boundingBox);
      }
    }
  }, [myMap]);

  useEffect(() => {
    if (history.location.pathname == "/export-paper-map") {
      setInExport(true);
    }
  }, []);

  useEffect(() => {
    setStyleAnalysed(true);
    setStyleSaved(true);
  }, []);

  useEffect(() => {
    setStyleAnalysed(false);
    setStyleSaved(false);
  }, [props.layers, props.mapStyle]);

  useEffect(() => {
    setStyleSaved(false);
  }, [props.styleName]);

  const actions = [
    {
      title: "Create new map",
      type: "NON-CHECKABLE",
      onClick: openNewMapModal,
      icon: <MapIcon sx={{ color: "white" }} />,
      variant: "filled",
      hidden: inExport !== true ? "" : "true",
    },
    {
      title: "Add GeoJSON",
      type: "NON-CHECKABLE",
      onClick: openGeoJSONModal,
      icon: <Icon icon="geojson" sx={{ color: "primary.main" }} />,
      variant: "contained",
      hidden: inExport !== true ? "" : "true",
    },
    {
      title: "Add KML",
      type: "NON-CHECKABLE",
      onClick: openKMLModal,
      icon: <Icon icon="kml" sx={{ color: "primary.main" }} />,
      variant: "contained",
      hidden: inExport !== true ? "" : "true",
    },
    {
      title: "Add Excel",
      type: "NON-CHECKABLE",
      onClick: openExcelModal,
      icon: <UploadFileIcon sx={{ color: "primary.main" }} />,
      variant: "contained",
      hidden:
        process.env.REACT_APP_ENV !== "prod" &&
        process.env.REACT_APP_ENV !== "staging"
          ? inExport !== true
            ? ""
            : "true"
          : "true",
    },
    {
      title: "Add CSV",
      type: "NON-CHECKABLE",
      onClick: openCSVModal,
      icon: <Icon icon="csv" sx={{ color: "primary.main" }} />,
      variant: "contained",
      hidden: inExport !== true ? "" : "true",
    },
    {
      title: "Add WMS",
      type: "NON-CHECKABLE",
      onClick: openWMSModal,
      icon: <Icon icon="wms" sx={{ color: "primary.main" }} />,
      variant: "contained",
      hidden: inExport !== true ? "" : "true",
    },

    {
      title: inExport !== true ? "Export" : "Print to paper",
      type: "NON-CHECKABLE",
      onClick: props.showPaperMapActions
        ? beforeOpenPaperExportModal
        : beforeOpenExportModal,
      variant: "contained",
    },
    // {
    //   title: "Import JSON",
    //   type: "NON-CHECKABLE",
    //   onClick: openUploadJSONModal,
    //   icon: <Icon icon="geojson" sx={{ color: "primary.main" }} />,
    //   variant: "contained",
    // },
    {
      title: "Analyse Style",
      type: "NON-CHECKABLE",
      onClick: () => {
        analyseStyle(myMap)
          .then((results) => {
            setStyleAnalysed(true);
            results.forEach((e, i) => {
              setTimeout(
                () =>
                  enqueueSnackbar(e.description, {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    variant: e.type,
                  }),
                i * 1000
              );
            });
          })
          .catch((e) =>
            enqueueSnackbar(e.message, {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "error",
            })
          );
        window.analytics.track("analyseStyle.click");  
      },
      disabled: styleAnalysed,
      hidden: inExport !== true ? "" : "true",
    },
    {
      type: "CHECKABLE",
      title: "Set scale",
      onClick: () => setShowScaleControlModal(!showScaleControlModal),
      onCheckChange: () =>
        props.setScale((s) => {
          return { ...s, show: !s.show };
        }),
      icon: <LinearScaleIcon sx={{ color: "white" }} />,
      checked: props.scale.show,
    },
    {
      type: "CHECKABLE",
      title: "Set north",
      // onClick: () => setShowNavigationControlModal(!showNavigationControlModal),
      onCheckChange: () =>
        props.setNavigation((n) => {
          return { ...n, show: !n.show };
        }),
      icon: <NearMeIcon sx={{ color: "white" }} />,
      checked: props.navigation.show,
    },
    {
      type: "CHECKABLE",
      title: "Legend Settings",
      onClick: () => setShowLegendControlModal(!showLegendControlModal),
      onCheckChange: () => {}, // TODO: Implement when the legen control is implemented
      icon: <DragHandleIcon sx={{ color: "white" }} />,
      checked: props.legend.show,
      disabled: true,
      hidden: true,
    },
    {
      type: "CHECKABLE",
      title: "Show attribution",
      onClick: () => {},
      onCheckChange: () =>
        props.setAttribution((a) => {
          return { ...a, show: !a.show };
        }),
      icon: <AttributionIcon sx={{ color: "white" }} />,
      checked: props.attribution.show,
    },
    {
      type: "NON-CHECKABLE",
      title: "Map Extend Settings",
      onClick: openMapExtendModal,
      icon: <ZoomOutMapOutlinedIcon sx={{ color: "white" }} />,
      variant: "filled",
    },
    {
      type: "NON-CHECKABLE",
      title: "Margin Settings",
      onClick: () => {},
      icon: <RoundedCornerOutlinedIcon sx={{ color: "white" }} />,
      hidden: true,
      variant: "filled",
    },
    {
      type: "NON-CHECKABLE",
      title: "Draw Layer",
      onClick: openDrawLayerModal,
      icon: <ModeEditOutlineIcon sx={{ color: "white" }} />,
      variant: "filled",
    },
  ];

  const paperMapActions = props.showPaperMapActions && [
    {
      type: "NON-CHECKABLE",
      title: "Paper Settings",
      onClick: openPaperSettingsModal,
      icon: <InsertDriveFileOutlinedIcon sx={{ color: "white" }} />,
      variant: "filled",
    },
  ];

  const backgroundLayer = {
    id: "Background",
    text: "Mapbox Bright",
    name: "Background Layer",
    type: LayerType.Background,
    show: true,
  };

  function deleteLayer(layer) {
    let newLayers = [];
    let previousLayer;
    props.layers
      .sort((a, b) => a.position - b.position)
      .every((l) => {
        if (l.id == layer.id) {
          if (previousLayer) previousLayer.beforeId = layer.beforeId;
          props.layers
            .slice(l.position)
            .forEach((e) => newLayers.push({ ...e, position: e.position - 1 }));
          return false;
        }
        newLayers.push(l);
        previousLayer = l;
        return true;
      });
    props.setLayers(newLayers);
  }

  function editBackground() {
    openTemplatesModal();
  }
  function editReverseLayer(layer) {
    setEditReverseLayerModal({ show: true, layer: layer });
  }

  function beforeOpenExportModal() {
    dispatch(
      getMapData(
        myMap.getZoom(),
        myMap.getCenter(),
        myMap.getPitch(),
        myMap.getBearing()
      )
    );
    openExportModal();
  }

  function beforeOpenPaperExportModal() {
    canPrintPaperMap(email).then((u) => {
      if (u == 200) {
        openPaperExportModal();
      } else if (u == 403) {
        enqueueSnackbar(
          "You have run out of prints. Change the subscription to get more prints today.",
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          }
        );
      } else if (u == 500) {
        enqueueSnackbar(
          "You have no subscription plan, please choose one of the subscriptions plans",
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          }
        );
        history.push("/subscriptions");
      } else {
        enqueueSnackbar(
          "There was an error loading the print modal. Please, try later.",
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          }
        );
      }
    });
  }

  const reopenDrawModal = () => {
    setShowDrawLayerModal(false);
    setTimeout(() => {
      setShowDrawLayerModal(true);
    }, 10);
  };

  function editLayer(layer) {
    if (layer.type.value === LayerType.Invert.value) {
      editReverseLayer(layer);
    } else {
      if (layer.fileType === LayerFileType.GeoJSON)
        setEditGeoJSONLayerModal({ show: true, layer: layer });
      if (layer.fileType === LayerFileType.KML)
        setEditKMLLayerModal({ show: true, layer: layer });
      if (layer.fileType === LayerFileType.WMS)
        setEditWMSLayerModal({ show: true, layer: layer });
    }

    // if (layer.type.value === LayerType.Background.value) openTemplatesModal();
  }

  const handleCreateStyle = (camera, l, modifyWMSLayers) => {
    createUserStyle(
      myMap.getStyle(),
      styleName,
      l,
      mapStyle,
      ownerId,
      camera
      // modifyWMSLayers
    )
      .then((id) => {
        setStyleSaved(true);
        dispatch(setStyleId(id));
        enqueueSnackbar("Style saved", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(
          "There was an error when saving your style. Please, try later.",
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          }
        );
      });
  };

  const handleUpdateStyle = (camera, l, modifyWMSLayers) => {
    updateUserStyle(
      styleId,
      myMap.getStyle(),
      styleName,
      l,
      mapStyle,
      ownerId,
      camera
      // modifyWMSLayers
    ).then((id) => {
      if (id) {
        setStyleSaved(true);
        dispatch(setStyleId(id));
        enqueueSnackbar("Style updated", {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        });
      }
    });
    // .catch((e) => {
    //   console.error(e);
    //   enqueueSnackbar(
    //     "There was an error when updating your style. Please, try later.",
    //     {
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "center",
    //       },
    //       variant: "error",
    //     }
    //   );
    // });
  };

  const handleStyleSave = () => {
    layers.forEach((l) => {
      l.dbLayerId
        ? updateUserLayer(l, ownerId)
        : createUserLayer(l, ownerId).then((id) => {
            dispatch(saveLayer([l.id, id]));
          });
    });

    modiftyLayersToSave();
  };

  const modiftyLayersToSave = () => {
    var modifyLayers = [];
    var modifyWMSLayers = [];
    var cameraSettings = {};
    cameraSettings.long = myMap.getCenter().lng;
    cameraSettings.lat = myMap.getCenter().lat;
    cameraSettings.zoom = myMap.getZoom();
    cameraSettings.pitch = myMap.getPitch();
    cameraSettings.bearing = myMap.getBearing();

    // modifyLayers.push(cameraSettings)

    layers.forEach((l) => {
      if (l.type.value == "raster") {
        // var modifyWMSLayer = {};
        // modifyWMSLayer.id = l.id;
        // modifyWMSLayer.name = l.name;
        // modifyWMSLayer.fileType = l.fileType;
        // modifyWMSLayer.type = l.type;
        // modifyWMSLayer.show = l.show;
        // modifyWMSLayer.opacity = l.opacity;
        // modifyWMSLayer.boundingbox = l.boundingbox;
        // modifyWMSLayer.source = {};
        // modifyWMSLayer.source.id = l.source.id;
        // modifyWMSLayer.source.type = l.source.type;
        // modifyWMSLayer.source.tiles = l.source.tiles;
        // modifyWMSLayer.source.tileSize = l.source.tileSize;
        // modifyWMSLayer.dbLayerId = l.dbLayerId;
        // modifyWMSLayers.push(modifyWMSLayer);
        var modifyLayer = {};
        modifyLayer.id = l.id;
        modifyLayer.name = l.name;
        modifyLayer.fileType = l.fileType;
        modifyLayer.type = l.type;
        modifyLayer.show = l.show;
        modifyLayer.opacity = l.opacity;
        modifyLayer.boundingbox = l.boundingbox;
        modifyLayer.source = {};
        modifyLayer.source.id = l.source.id;
        modifyLayer.source.type = l.source.type;
        modifyLayer.source.tiles = l.source.tiles;
        modifyLayer.source.tileSize = l.source.tileSize;
        modifyLayer.dbLayerId = l.dbLayerId;
        modifyLayers.push(modifyLayer);
      } else {
        var modifyLayer = {};
        modifyLayer.id = l.id;
        modifyLayer.name = l.name;
        modifyLayer.fileType = l.fileType;
        modifyLayer.type = l.type;
        modifyLayer.show = l.show;
        modifyLayer.fillColor = l.fillColor;
        modifyLayer.lineColor = l.lineColor;
        if (l.type != "invert") {
          modifyLayer.selectColor = l.selectColor;
          modifyLayer.featureColors = l.featureColors;
          modifyLayer.showOnHover = l.showOnHover;
          modifyLayer.hoverProperties = l.hoverProperties;
        }
        modifyLayer.boundingbox = l.boundingbox;
        modifyLayer.urlSource = l.urlSource;
        modifyLayer.opacity = l.opacity;
        modifyLayer.source = {};
        modifyLayer.source.id = l.source.id;
        modifyLayer.source.type = l.source.type;
        modifyLayer.source.data = [];
        modifyLayer.dbLayerId = l.dbLayerId;
        modifyLayers.push(modifyLayer);
      }
    });

    styleId
      ? handleUpdateStyle(cameraSettings, modifyLayers, modifyWMSLayers)
      : handleCreateStyle(cameraSettings, modifyLayers, modifyWMSLayers);
  };

  return (
    <React.Fragment>
      <Grid
        container
        style={{
          position: "absolute",
          top: 15,
          left: 15,
          display: "flex",
          flexDirection: "column",
          width: "70%",
          pointerEvents: "none",
        }}
      >
        <ActionsMenu
          actions={actions}
          paperMapActions={paperMapActions}
          showPaperMapActions={props.showPaperMapActions}
          showLayerTiles={props.showLayerTiles}
          setShowLayerTiles={props.setShowLayerTiles}
          mapId={props.mapId}
          styleSaved={styleSaved}
          setStyleSaved={setStyleSaved}
          styleName={props.styleName}
          setStyleName={props.setStyleName}
          mapStyle={props.mapStyle}
          layers={props.layers}
          setMapStyleId={props.setMapStyleId}
          openSaveStyleModal={openSaveStyleModal}
        />
        <AddedLayersDisplay
          editBackground={editBackground}
          layers={[backgroundLayer, ...props.layers]}
          setLayers={props.setLayers}
          deleteLayer={deleteLayer}
          // setLayerVisibility={setLayerVisibility}
          setRemoveLayerModal={setRemoveLayerModal}
          showLayerTiles={props.showLayerTiles}
          editLayer={editLayer}
          // handleMoveUpLayer={handleMoveUpLayer}
          // handleMoveDownLayer={handleMoveDownLayer}
          mapId={props.mapId}
        ></AddedLayersDisplay>
      </Grid>
      <TemplatesModal
        showTemplatesModal={showTemplatesModal}
        setShowTemplatesModal={setShowTemplatesModal}
        setMapStyle={props.setMapStyle}
      />
      {showGeoJSONModal && (
        <GeoJSONModal
          showGeoJSONModal={showGeoJSONModal}
          setShowGeoJSONModal={setShowGeoJSONModal}
          setLayers={props.setLayers}
          map={myMap}
        />
      )}
      {showKMLModal && (
        <KMLModal
          showKMLModal={showKMLModal}
          setShowKMLModal={setShowKMLModal}
          openKML={openKMLModal}
          setLayers={props.setLayers}
          map={myMap}
        />
      )}
      {showExcelModal && (
        <ExcelModal
          showExcelModal={showExcelModal}
          setShowExcelModal={setShowExcelModal}
          openExcel={openExcelModal}
          setLayers={props.setLayers}
          map={myMap}
        />
      )}
      {showWMSModal && (
        <WMSModal
          showWMSModal={showWMSModal}
          setShowWMSModal={setShowWMSModal}
          setLayers={props.setLayers}
          map={myMap}
        />
      )}
      {/* {showUploadJSONModal && <BasicModal />} */}
      {showCSVModal && (
        <CSVModal
          showCSVModal={showCSVModal}
          setShowCSVModal={setShowCSVModal}
          setLayers={props.setLayers}
          map={myMap}
        />
      )}
      <ControlModal
        title={"Scale Settings"}
        show={showScaleControlModal}
        setShow={setShowScaleControlModal}
        position={props.scale.position}
        setPosition={(position) =>
          props.setScale((s) => {
            return { ...s, position };
          })
        }
        unit={props.scale.unit}
        setUnit={(unit) =>
          props.setScale((s) => {
            return { ...s, unit };
          })
        }
      ></ControlModal>
      <ControlModal
        title={"Navigation Settings"}
        show={showNavigationControlModal}
        setShow={setShowNavigationControlModal}
        position={props.navigation.position}
        setPosition={(position) =>
          props.setScale((s) => {
            return { ...s, position };
          })
        }
      ></ControlModal>
      <ControlModal
        title={"Legend Settings"}
        show={showLegendControlModal}
        setShow={setShowLegendControlModal}
        position={props.legend.position}
        setPosition={(position) =>
          props.setLegend((s) => {
            return { ...s, position };
          })
        }
      ></ControlModal>

      {removeLayerModal && (
        <RemoveLayerModal
          removeLayerModal={removeLayerModal}
          setRemoveLayerModal={setRemoveLayerModal}
          deleteLayer={deleteLayer}
        ></RemoveLayerModal>
      )}
      {showNewMapModal && (
        <NewMapModal
          show={showNewMapModal}
          setShow={setShowNewMapModal}
          setMapStyle={props.setMapStyle}
          setLayers={props.setLayers}
          setShowLayerTiles={props.setShowLayerTiles}
          setStyleName={props.setStyleName}
          setMapStyleId={props.setMapStyleId}
          setMapBoundingBox={props.setMapBoundingBox}
          setShowTemplatesModal={setShowTemplatesModal}
        ></NewMapModal>
      )}
      <MapExtendModal
        show={showMapExtendModal}
        setShow={setShowMapExtendModal}
        myMap={myMap}
        scaleUnit={props.scale.unit}
      ></MapExtendModal>
      <DrawLayerModal
        show={showDrawLayerModal}
        setShow={setShowDrawLayerModal}
        myMap={myMap}
        reopenDrawModal={reopenDrawModal}
      ></DrawLayerModal>

      {props.showPaperMapActions && (
        <PaperSettingsModal
          show={showPaperSettingsModal}
          setShow={setShowPaperSettingsModal}
          paperSettings={props.paperSettings}
          setPaperSettings={props.setPaperSettings}
        ></PaperSettingsModal>
      )}
      {props.showPaperMapActions && (
        <PaperExportModal
          show={showPaperExportModal}
          setShow={setShowPaperExportModal}
          exportSettings={props.exportSettings}
          setExportSettings={props.setExportSettings}
          paperSettings={props.paperSettings}
          map={myMap}
        ></PaperExportModal>
      )}
      <ExportModal
        show={showExportModal}
        setShow={setShowExportModal}
        map={myMap}
        styleName={props.styleName}
        layers={props.layers}
        mapStyle={props.mapStyle}
        setStyleSaved={setStyleSaved}
        setMapStyleId={props.setMapStyleId}
      ></ExportModal>
      <SaveStyleModal
        show={showSaveStyleModal}
        setShow={setShowSaveStyleModal}
        onSave={handleStyleSave}
      ></SaveStyleModal>
      {editGeoJSONLayerModal.show && (
        <EditGeoJSONModal
          show={editGeoJSONLayerModal.show}
          setShow={(show) => setEditGeoJSONLayerModal({ show: show })}
          layer={editGeoJSONLayerModal.layer}
          setLayers={props.setLayers}
        ></EditGeoJSONModal>
      )}
      {editReverseLayerModal.show && (
        <EditReverseLayerModal
          show={editReverseLayerModal.show}
          setShow={(show) => setEditReverseLayerModal({ show: show })}
          layer={editReverseLayerModal.layer}
          map={myMap}
        />
      )}
      {editKMLLayerModal.show && (
        <EditKMLModal
          show={editKMLLayerModal.show}
          setShow={(show) => setEditKMLLayerModal({ show: show })}
          layer={editKMLLayerModal.layer}
          setLayers={props.setLayers}
        ></EditKMLModal>
      )}
      {editWMSLayerModal.show && (
        <EditWMSModal
          show={editWMSLayerModal.show}
          setShow={(show) => setEditWMSLayerModal({ show: show })}
          layer={editWMSLayerModal.layer}
          setLayers={props.setLayers}
        ></EditWMSModal>
      )}
    </React.Fragment>
  );
}
