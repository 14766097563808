import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useSelector } from "react-redux";

import { LayerType } from "../../../lib/LayerType";
import BettermapsColorPicker from "../BettermapsColorPicker";
import BettermapsTextField from "../BettermapsTextField";

export default function LayerDetails(props) {
	const theme = useTheme();
	const [layerVisibility, setLayerVisibility] = useState("public");
	const userData = useSelector((state) => state.user);

	const handleChange = (e) => {
		props.setSelectColor(e.target.value);
		analytics.identify(`Color Selector: ${e.target.value}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	return (
		<Grid
			item
			xl={props.xl || 5}
			lg={props.lg || 5}
			md={props.md || 5}
			sm={props.sm || 5}
			xs={props.xs || 5}
			style={{ padding: "7px 7px 7px 0px" }}>
			<Stack spacing={1}>
				<BettermapsTextField
					label={props.label}
					value={props.layerName}
					onChange={(e) => {
						props.setLayerName(e.target.value),
							analytics.identify(
								`click input ${props.label ?? "GeoJSON layer"} : ${
									props.layerName
								}`,
								{
									id: userData.ownerId,
									subscription: userData.subscription,
								}
							);
					}}></BettermapsTextField>
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						flexWrap: "wrap",
					}}>
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={props.selectColor}
							onChange={handleChange}>
							<FormControlLabel
								value="unique"
								control={<Radio />}
								label="Unique Color"
							/>
							{props.selectColor == "unique" && (
								<Box
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										// marginRight: "40px",
										// marginTop: "10px",
									}}>
									<Box
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											marginRight: "20px",
											marginTop: "10px",
										}}>
										<Typography>
											{props.layerType.value == LayerType.Line.value
												? "Line color"
												: "Outline color"}
										</Typography>
										<Box style={{ marginLeft: "10px" }}>
											<BettermapsColorPicker
												color={props.lineColor}
												setColor={props.setLineColor}
												// disableAlpha={
												//   props.layerType.value != LayerType.Line.value
												// }
											/>
										</Box>
									</Box>
									{props.layerType.value != LayerType.Line.value && (
										<Box
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												marginRight: "40px",
												marginTop: "10px",
											}}>
											<Typography>Fill color</Typography>

											<Box style={{ marginLeft: "10px" }}>
												<BettermapsColorPicker
													color={props.fillColor}
													setColor={props.setFillColor}
													disableAlpha={
														props.layerType.value == LayerType.Line.value
													}
												/>
											</Box>
										</Box>
									)}
								</Box>
							)}
							<FormControlLabel
								value="random"
								control={<Radio />}
								label="Random Color"
								onClick={() =>
									analytics.identify("ColorSelector: random", {
										id: userData.ownerId,
										subscription: userData.subscription,
									})
								}
							/>
						</RadioGroup>
					</FormControl>
					{/* <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: "20px",
              marginTop: "10px",
            }}
          >
            <Typography>
              {props.layerType.value == LayerType.Line.value
                ? "Line color"
                : "Outline color"}
            </Typography>
            <Box style={{ marginLeft: "10px" }}>
              <BettermapsColorPicker
                color={props.lineColor}
                setColor={props.setLineColor}
                disableAlpha={props.layerType.value != LayerType.Line.value}
              />
            </Box>
          </Box>
          {props.layerType.value != LayerType.Line.value && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "40px",
                marginTop: "10px",
              }}
            >
              <Typography>Fill color</Typography>

              <Box style={{ marginLeft: "10px" }}>
                <BettermapsColorPicker
                  color={props.fillColor}
                  setColor={props.setFillColor}
                  disableAlpha={props.layerType.value == LayerType.Line.value}
                />
              </Box>
            </Box>
          )} */}
					{/* <ToggleButtonGroup
            disabled
            style={{
              backgroundColor: theme.palette.secondary.main,
              width: "fit-content",
              marginTop: "10px",
            }}
            value={layerVisibility}
            size="small"
            exclusive
            onChange={(e, a) => setLayerVisibility(a)}
          >
            <ToggleButton value="owned">
              <FavoriteBorderIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="public">
              <LockOpenIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="private">
              <LockOutlinedIcon sx={{ color: "white" }} />
            </ToggleButton>
          </ToggleButtonGroup> */}
				</Box>
			</Stack>
		</Grid>
	);
}
