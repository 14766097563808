import { useSelector } from "react-redux";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";

export default function SaveStyleModal(props) {
	const userData = useSelector((state) => state.user);

	return (
		<Dialog
			open={props.show}
			onClose={() => props.setShow(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{"Information"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Upon saving your style, layers will also be saved.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setShow(false),
							analytics.identify("click on Save Style: Cancel", {
								id: userData.ownerId,
								subscription: userData.subscription,
							});
					}}
					autoFocus>
					Cancel
				</Button>
				<Button
					color="info"
					onClick={() => {
						props.onSave();
						props.setShow(false);
						analytics.identify("click on Save Style: Confirm", {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
					}}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
